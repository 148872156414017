import { createSelector } from 'reselect';
import { AppStateInterface } from '../reducers/interfaces';

export const getFormulations = (state: AppStateInterface) => {
  return state.formulations.filter((x) =>
    state.selectedUser?.formulations?.includes(x.key),
  );
};

export const getFormulationData = (id?: string) =>
  createSelector(getFormulations, (formulation) =>
    formulation.find((x) => x.key === id),
  );
