import bcrypt from 'bcryptjs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import {
  authenticateUser,
  isUserAuthenticated,
} from '../../../redux/actions/app';
import LeftArrow from '../../../resources/arrow-left.svg';
import { AccountLoginProps } from '../LoginPage.interface';
import {
  Container,
  UserHeading,
  ArrowIcon,
  SecurityQuestion,
  Buttons,
  ForgotPassword,
  PasswordReset,
} from './KeywordEntry.styles';
import { ResetPassword } from './ResetPassword';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { loginUser, sendResetLink } from '../../../api/api';
import { EmailSent, ErrorMessage } from './AccountLogin.styles';
import { LoadingIndicator } from '../../../components/LoadingIndicator/LoadingIndicator';
import { updateFormulationsInDB } from '../../../redux/actions/formulation';
import { modifyDBFormulations } from '../../../helpers/modifyDbFormulations';

export const KeywordEntry = ({
  selectAccount,
  user,
}: AccountLoginProps): React.ReactElement => {
  const [isForgottenPassword, setIsForgottenPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetLinkSent, setIsResetLinkSent] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage || isResetLinkSent) setIsSubmitting(false);
    if (isResetLinkSent) setErrorMessage(undefined);
  }, [errorMessage, isResetLinkSent]);

  const selectItem = () => {
    let item = {
      id: 'password',
      label: 'Password',
      type: 'password',
      required: true,
    };
    if (isForgottenPassword) {
      item = {
        id: isAccountOnline(user) ? 'passwordResetEmail' : 'securityAnswer',
        label: isAccountOnline(user) ? 'Email' : 'Security Answer',
        type: isAccountOnline(user) ? 'text' : 'password',
        required: true,
      };
    }
    return item;
  };

  const isOnlineForgottenPassword =
    isAccountOnline(user) && isForgottenPassword;

  const handleSubmitPassword = async (values: {
    password: string;
    securityAnswer: string;
  }) => {
    setErrorMessage(undefined);
    setIsSubmitting(true);

    if (!isAccountOnline(user)) {
      const matchesUserPassword = bcrypt.compareSync(
        isForgottenPassword ? values.securityAnswer : values.password,
        isForgottenPassword ? user.securityAnswer : (user.password as string),
      );

      if (matchesUserPassword) {
        if (isForgottenPassword) {
          setResetPassword(true);
        } else {
          ReactGA.set({ userId: user.id });
          navigate('/main');
          dispatch(isUserAuthenticated(true));
        }
      }
    } else {
      const {
        user: fetchedUser,
        token,
        status,
        error,
      } = await loginUser(user.email, values.password);

      if (status === 'error' || error) {
        return setErrorMessage(
          status === 'error'
            ? 'No database connection! Try again later.'
            : 'No internet connection! Try again later.',
        );
      }
      if (!fetchedUser) {
        return setErrorMessage('Invalid password!');
      }

      dispatch(authenticateUser(fetchedUser, token));
      const newFormulations = modifyDBFormulations(
        fetchedUser.formulations,
        fetchedUser.email,
      );
      dispatch(updateFormulationsInDB(newFormulations));
      ReactGA.set({ userId: fetchedUser.id });
      navigate('/main');
      dispatch(isUserAuthenticated(true));
    }
  };

  const handleSendResetLink = async (values: {
    passwordResetEmail: string;
  }) => {
    setIsSubmitting(true);
    setErrorMessage(undefined);
    setIsResetLinkSent(false);

    const { status, message, error } = await sendResetLink(
      values.passwordResetEmail,
    );

    if (status === 'fail' || status === 'error' || error) {
      return setErrorMessage(
        error && status === undefined
          ? 'No internet connection! Try again later.'
          : message,
      );
    }

    setIsResetLinkSent(true);

    setTimeout(() => {
      setIsResetLinkSent(false);
    }, 5000);
  };

  if (resetPassword) return <ResetPassword />;

  return (
    <Container>
      <UserHeading
        data-cy='selected-user'
        onClick={() => selectAccount(user.id as string)}
      >
        <ArrowIcon src={LeftArrow} alt='Go back' />
        <SectionHeading label={user?.fullName} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {isResetLinkSent && (
          <EmailSent>Your reset link is waiting in your mailbox.</EmailSent>
        )}
      </UserHeading>
      <Formik
        initialValues={{
          password: '',
          securityAnswer: '',
          passwordResetEmail: '',
        }}
        validate={(values) => {
          if (isAccountOnline(user)) {
            return {};
          }
          const matchesUserPassword = bcrypt.compareSync(
            isForgottenPassword ? values.securityAnswer : values.password,
            isForgottenPassword
              ? user.securityAnswer
              : (user.password as string),
          );

          if (!matchesUserPassword) {
            return isForgottenPassword
              ? { securityAnswer: '* Invalid security answer.' }
              : { password: '* Invalid password.' };
          }
          return {};
        }}
        onSubmit={(values) =>
          isOnlineForgottenPassword
            ? handleSendResetLink(values)
            : handleSubmitPassword(values)
        }
      >
        {({
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          values,
        }) => (
          <Form>
            {!isForgottenPassword ? (
              <FormInputFields
                item={selectItem()}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                placeholder={false}
                values={values}
                autoFocus
              />
            ) : !isAccountOnline(user) ? (
              <>
                <SecurityQuestion>{user.securityQuestion}</SecurityQuestion>
                <FormInputFields
                  item={selectItem()}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  placeholder={false}
                  values={values}
                  autoFocus
                />
              </>
            ) : (
              <>
                <PasswordReset>
                  Type in your e-mail and we will send you a link to change your
                  password.
                </PasswordReset>
                <FormInputFields
                  item={selectItem()}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  placeholder={false}
                  values={values}
                  autoFocus
                />
              </>
            )}
            <Buttons
              content={isForgottenPassword ? 'flex-end' : 'space-between'}
            >
              {!isForgottenPassword && (
                <ForgotPassword
                  data-cy='forget-password-action'
                  onClick={() => {
                    if (errorMessage) setErrorMessage(undefined);
                    setIsForgottenPassword(true);
                  }}
                >
                  Forgot your password?
                </ForgotPassword>
              )}
              <ButtonStyled
                data-cy={
                  isOnlineForgottenPassword
                    ? 'send-reset-link-button'
                    : 'login-button'
                }
                type='primary'
                onClick={() => !isSubmitting && handleSubmit()}
              >
                {isSubmitting ? (
                  <LoadingIndicator container />
                ) : isOnlineForgottenPassword ? (
                  'Send'
                ) : (
                  'Log in'
                )}
              </ButtonStyled>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
