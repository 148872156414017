import { FilterOption } from './TableFilters.interface';

export const generateFilterOptions = <T>(
  columnName: keyof T,
  data: T[],
): FilterOption[] => {
  const entryList = data.map((entry) => String(entry[columnName]));
  const options = [...Array.from(new Set(entryList))].map((entry) => ({
    value: entry,
    label: entry,
  }));

  return options.length > 0 ? [{ value: '', label: 'All' }, ...options] : [];
};
