import { SelectValue } from 'antd/lib/select';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import {
  TextFieldInputStyled,
  SelectInputStyled,
  NumberFieldInputStyled,
} from '../../../components/Input/Input.styles';
import {
  ResinsTable,
  CalculatorResinsProps,
} from './CalculatorResins.interface';
import { getResins } from '../../../redux/selectors/materials';
import {
  Container,
  Title,
  CalculatorResinTable,
  BoldText,
  BatchContainer,
  RemoveMaterial,
} from './CalculatorResins.styles';
import { Button } from '../../../components/Button/Button';

const newData = {
  key: '0',
  materialKey: '',
  title: '',
  ohNumber: 0,
  functionality: 0,
  eqWeight: 0,
  parts: 0,
  ohContribution: 0,
  batchWeights: 0,
  calcCost: 0,
  matCost: 0,
  materialType: '',
  owner: '',
};

export const CalculatorResins = ({
  showExtraColumns,
  handleResinsBatchWeight,
  handleResins,
  enabled,
  loadedFormulation,
  isFirstLoad,
}: CalculatorResinsProps) => {
  const [materials, setMaterials] = useState<ResinsTable[]>([newData]);
  const [totalParts, setTotalParts] = useState(0);
  const [totalBatWeight, setTotalBatWeight] = useState(0);
  const [ohContribution, setOHContribution] = useState(0);
  const [batchWeight, setBatchWeight] = useState(1500);
  const [totalMatCost, setTotalMatCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [checkCost, setCheckCost] = useState(false);
  const materialsList = useSelector(getResins);
  const materialOptions = useMemo(
    () =>
      materialsList.map((x) => ({
        label: x.title,
        value: x.key,
      })),
    [materialsList],
  );

  const updateOHCon = () => {
    const costUpdated = [...materials];
    let total = 0;
    costUpdated.forEach((x) => {
      if (x.ohNumber && x.parts)
        x.ohContribution = (x.ohNumber * Number(x.parts)) / totalParts;
      total += (x.ohNumber * Number(x.parts)) / totalParts;
    });
    setOHContribution(total);
    setMaterials(costUpdated);
  };

  const handleCost = (record: { key: string }, e: any) => {
    const data = [...materials];
    const recordIndex = data.findIndex((x) => record.key === x.key);
    data.splice(recordIndex, 1, {
      ...data[recordIndex],
      matCost: e,
    });

    setCheckCost(true);
    setMaterials(data);
  };

  const updateBatchWeight = () => {
    const updatedData = [...materials];
    let total = 0;
    updatedData.forEach((x) => {
      x.batchWeights = x.parts
        ? (Number(x.parts) * batchWeight) / totalParts
        : 0;
      total += x.batchWeights;
    });
    setMaterials(updatedData);
    setTotalBatWeight(total);
  };

  const updateTotal = () => {
    let total = 0;
    materials.forEach((x) => {
      if (x.parts) total += Number(x.parts);
    });
    setTotalParts(total);
  };

  const calculateBatch = (e: { target: { value: string } }) => {
    const updatedData = [...materials];
    let total = 0;
    updatedData.forEach((x) => {
      x.batchWeights = x.parts
        ? (Number(x.parts) * Number(e.target.value)) / totalParts
        : 0;
      total += x.batchWeights;
    });
    handleResinsBatchWeight(Number(e.target.value));
    setBatchWeight(Number(e.target.value));
    setTotalBatWeight(total);
    setCheckCost(true);
    setMaterials(updatedData);
  };

  const updateCost = () => {
    const costUpdated = [...materials];
    let totalMat = 0;
    let total = 0;
    costUpdated.forEach((x) => {
      if (x.matCost && x.parts) {
        const costAmount = (x.matCost * Number(x.parts)) / totalParts;
        x.calcCost = costAmount;
        total += costAmount;
        totalMat += x.matCost;
      }
    });
    setTotalMatCost(totalMat);
    setTotalCost(total);
    setMaterials(costUpdated);
    setCheckCost(false);
  };

  useEffect(() => {
    if (materials) {
      updateOHCon();
      updateCost();
      updateBatchWeight();
    }
  }, [totalParts]);

  useEffect(() => {
    if (materials) {
      updateTotal();
      if (checkCost) {
        updateCost();
      }
      handleResins(materials);
    }
  }, [materials]);

  useEffect(() => {
    if (loadedFormulation && isFirstLoad) {
      setMaterials(loadedFormulation.materials.resins);
      setBatchWeight(loadedFormulation.resBatchWeight);
    }
  }, [loadedFormulation]);

  const handleMaterialSelection = (record: { key: string }, e: SelectValue) => {
    const data = [...materials];
    const recordIndex = data.findIndex((x) => record.key === x.key);
    const mIndex = materialsList.findIndex((x) => x.key === e);
    data.splice(recordIndex, 1, {
      ...materialsList[mIndex],
      materialKey:
        (materialsList[mIndex].id as string) || materialsList[mIndex].key,
      title: materialsList[mIndex].title,
      ohNumber: Number(materialsList[mIndex].ohNumber || 0),
      functionality: Number(materialsList[mIndex].noFunctionality || 0),
      eqWeight: Number(materialsList[mIndex].eqWeight || 0),
      parts: 0,
      ohContribution: 0,
      batchWeights: 0,
      calcCost: 0,
      matCost: 0,
      materialType: materialsList[mIndex].materialType,
      owner: materialsList[mIndex].owner,
    });

    setMaterials(data);
  };

  const handleParts = (record: { key: string }, e: any): any => {
    const data = [...materials];
    const partsTotal = e;
    const recordIndex = data.findIndex((x) => record.key === x.key);
    data.splice(recordIndex, 1, {
      ...data[recordIndex],
      parts: partsTotal,
    });
    setCheckCost(true);
    setMaterials(data);
  };

  const removeMaterial = (key: string) => {
    const filteredData = materials.filter((x) => x.key !== key);
    setCheckCost(true);
    setMaterials(filteredData);
  };

  const handleAdd = () => {
    const total = materials.length;
    setCheckCost(true);
    setMaterials([...materials, { ...newData, key: String(total + 1) }]);
  };

  const calculatorResinsColumns: ColumnsType<any> = [
    {
      title: 'Material',
      dataIndex: 'title',
      render: (title: string, record: ResinsTable, index: number) => {
        return enabled ? (
          <SelectInputStyled
            showSearch
            data-cy='resins-material-selection'
            placeholder='Choose material'
            onChange={(e) => handleMaterialSelection(record, e as any)}
            options={materialOptions}
            defaultValue={title}
            optionFilterProp='label'
            filterOption={(input, option) => {
              if (option) {
                if (option.label) {
                  return (
                    (option.label as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  );
                }
              }
              return true;
            }}
          />
        ) : (
          <div data-cy='resins-view-material'>{title}</div>
        );
      },
    },
    {
      title: 'Parts',
      dataIndex: 'parts',
      render: (parts: number, record: ResinsTable) => {
        const index = materials.findIndex((x) => record.key === x.key);

        return enabled ? (
          <NumberFieldInputStyled
            min='0'
            data-cy='resins-parts-input'
            type='number'
            value={record.parts}
            disabled={!materials[index].title || !enabled}
            defaultValue={parts}
            onChange={(e) => handleParts(record, e)}
          />
        ) : (
          <div data-cy='resins-view-parts'>{parts}</div>
        );
      },
    },
    {
      title: 'OH #',
      dataIndex: 'ohNumber',
    },
    {
      title: 'Eq. Wt.',
      dataIndex: 'eqWeight',
      render: (text: string, record: ResinsTable) =>
        record.eqWeight && Number(record.eqWeight).toFixed(2),
    },
    {
      title: 'Functionality',
      dataIndex: 'functionality',
      render: (text: string, record: ResinsTable) =>
        Number(record.functionality).toFixed(2),
    },
    {
      title: 'OH Contribution',
      dataIndex: 'ohContribution',
      render: (text: string, record: ResinsTable) => {
        if (record.ohNumber && record.parts)
          return ((record.ohNumber * record.parts) / totalParts).toFixed(2);
        return 0;
      },
    },
    {
      title: 'Batch Weights',
      dataIndex: 'batchWeights',
      render: (text: string, record: ResinsTable) =>
        record.batchWeights && Number(record.batchWeights).toFixed(2),
    },
    {
      title: 'Raw Mat. Cost $/Lb',
      dataIndex: 'matCost',
      className: showExtraColumns ? 'visible' : 'hidden',
      render: (matCost: number, record: ResinsTable) => {
        const index = materials.findIndex((x) => record.key === x.key);
        return enabled ? (
          <NumberFieldInputStyled
            data-cy='resins-material-cost-input'
            type='number'
            disabled={!materials[index].materialKey}
            defaultValue={matCost}
            onChange={(e) => handleCost(record, e)}
            prefix='$'
          />
        ) : (
          <div data-cy='resins-view-material-cost'>{matCost}</div>
        );
      },
    },
    {
      title: 'Blend Cost $/Lb',
      dataIndex: 'calcCost',
      className: showExtraColumns ? 'visible' : 'hidden',
      render: (text: string, record: ResinsTable) =>
        `$ ${record.calcCost.toFixed(2)}`,
    },
    {
      title: '',
      dataIndex: 'key',
      render: (key: string) => {
        return (
          enabled && (
            <div key={key}>
              <RemoveMaterial
                data-cy='resins-remove-material'
                title='Remove'
                onClick={() => removeMaterial(key)}
              />
            </div>
          )
        );
      },
    },
  ];

  return (
    <Container>
      <Title>Resin Blend</Title>
      <CalculatorResinTable
        data-cy='selected-resins-table'
        dataSource={materials}
        columns={calculatorResinsColumns}
        pagination={false}
        summary={() => {
          return (
            <>
              <CalculatorResinTable.Summary.Row>
                <CalculatorResinTable.Summary.Cell index={1}>
                  {enabled && (
                    <Button
                      type='text'
                      data-cy='resins-add-new-material'
                      onClick={() => handleAdd()}
                    >
                      + Add Material
                    </Button>
                  )}
                </CalculatorResinTable.Summary.Cell>
                <CalculatorResinTable.Summary.Cell index={2}>
                  {totalParts}
                </CalculatorResinTable.Summary.Cell>
                <CalculatorResinTable.Summary.Cell index={3} />
                <CalculatorResinTable.Summary.Cell index={4} />
                <CalculatorResinTable.Summary.Cell index={5} />
                <CalculatorResinTable.Summary.Cell index={6}>
                  {ohContribution ? ohContribution.toFixed(2) : 0.0}
                </CalculatorResinTable.Summary.Cell>
                <CalculatorResinTable.Summary.Cell index={7}>
                  {totalBatWeight.toFixed(2)}
                </CalculatorResinTable.Summary.Cell>
                <CalculatorResinTable.Summary.Cell
                  index={8}
                  className={showExtraColumns ? 'visible' : 'hidden'}
                >
                  $ {totalMatCost.toFixed(2)}
                </CalculatorResinTable.Summary.Cell>
                <CalculatorResinTable.Summary.Cell
                  index={9}
                  className={showExtraColumns ? 'visible' : 'hidden'}
                >
                  $ {totalCost.toFixed(2)}
                </CalculatorResinTable.Summary.Cell>
                <CalculatorResinTable.Summary.Cell index={5} />
              </CalculatorResinTable.Summary.Row>
            </>
          );
        }}
      />

      <BatchContainer>
        <BoldText>Resin Batch Weight</BoldText>
        {enabled ? (
          <TextFieldInputStyled
            data-cy='resins-batch-weight-input'
            defaultValue={
              loadedFormulation ? loadedFormulation.resBatchWeight : batchWeight
            }
            min={0}
            type='number'
            onChange={calculateBatch}
          />
        ) : (
          <span data-cy='resins-view-batch-weight'>{batchWeight || 1500}</span>
        )}
      </BatchContainer>
    </Container>
  );
};
