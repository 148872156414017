import { gql } from "@apollo/client";

export const UPDATE_MATERIAL = gql`
  mutation updateMaterial($data: MaterialUpdateInput!, $where: MaterialWhereUniqueInput!) {
    updateMaterial(data: $data, where: $where) {
      title
      ohNumber
      eqWeight
      noFunctionality
      nco
      comments
      materialType
      type {
        value
      }
    }
  }
`;