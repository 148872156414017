import styled from "styled-components";

export const ErrorComponent = styled.p`
  margin: 1rem auto 0;
  color: ${(props) => props.theme.texts.colors.semantic.error[60]};
  `;

export const SuccessComponent = styled.p`
  margin: 1rem auto 0;
  color: ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
`;