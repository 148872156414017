export const recentFormulationsColumns = [
  { title: 'Title', dataIndex: 'name' },
  { title: 'Owner', dataIndex: 'owner' },
  {
    title: 'Hydroxyl No.',
    dataIndex: 'hydroxilNo',
    render: (hydroxilNo: number) =>
      Number(hydroxilNo.toFixed(2)).toLocaleString('en-US'),
  },
  {
    title: 'Ratio',
    dataIndex: 'ratio',
    render: (ratio: number) => Number(ratio.toFixed(2)).toLocaleString('en-US'),
  },
  {
    title: 'NCO %',
    dataIndex: 'nco',
    render: (nco: number) => Number(nco.toFixed(2)).toLocaleString('en-US'),
  },
  { title: 'Index', dataIndex: 'index' },
  {
    title: 'Last Edit',
    dataIndex: 'lastEdit',
    render: (lastEdit: number) => {
      const date = new Date(lastEdit);

      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();

      const formattedMonth = String(month).padStart(2, '0');
      const formattedDay = String(day).padStart(2, '0');
      const formattedYear = String(year);

      return `${formattedMonth}/${formattedDay}/${formattedYear}`;
    },
  },
];
