import React, { ContextType, useCallback, useContext, useEffect } from 'react';
import type { Blocker, History as HistType, Transition } from 'history';
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';

export const downloadFile = (href: string, name: string) => {
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', href);
  downloadAnchorNode.setAttribute('download', name);
  downloadAnchorNode.setAttribute('_target', 'blank');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadJSON = <T>(object: T, name: string) => {
  const data = encodeURIComponent(JSON.stringify(object));
  const dataStr = `data:text/json;charset=utf-8,${data}`;

  return downloadFile(dataStr, `${name}.json`);
};

const readFile = <T>(
  file: any,
  handleDataImport: React.Dispatch<React.SetStateAction<T>>,
) => {
  const fileReader: FileReader = new FileReader();

  fileReader.onload = () => {
    const { result } = fileReader;
    handleDataImport(JSON.parse(result as string));
  };

  fileReader.readAsText(file);
};

export const uploadFile = <T>(
  handleDataImport: React.Dispatch<React.SetStateAction<T>>,
) => {
  const uploadInputNode = document.createElement('input');
  uploadInputNode.setAttribute('type', 'file');
  uploadInputNode.setAttribute('upload', 'name');
  uploadInputNode.setAttribute('accept', 'application/json');
  uploadInputNode.setAttribute('data-cy', 'file-upload-input');
  document.body.appendChild(uploadInputNode);

  uploadInputNode.onchange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const { files } = target;
    const file = files && files[0];
    if (file?.type === 'application/json') {
      readFile(file, handleDataImport);
    }
  };

  uploadInputNode.click();
  uploadInputNode.remove();
};

interface NavigatorProp extends BaseNavigator {
  block: HistType['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: NavigatorProp;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(
    NavigationContext,
  ) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(
  message:
    | string
    | ((
        location: Transition['location'],
        action: Transition['action'],
      ) => string),
  when = true,
) {
  const blocker = useCallback(
    (tx: Transition) => {
      let response;
      if (typeof message === 'function') {
        response = message(tx.location, tx.action);
        if (typeof response === 'string') {
          response = window.confirm(response);
        }
      } else {
        response = window.confirm(message);
      }
      if (response) {
        tx.retry();
      }
    },
    [message],
  );
  return useBlocker(blocker, when);
}
