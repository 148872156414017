import React from 'react';
import styled from 'styled-components';
import AccountImage from '../../containers/LoginPage/resources/accountSetup.jpeg';
import BASFlogo from '../../resources/BASF-logo-full.svg';

const Image = styled.div`
  @media (min-width: 767px) {
    position: fixed;
    height: 100vh;
    width: 40%;
  }
  position: relative;
  background-image: url(${AccountImage});
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 40vh;
  width: 100%;
`;
const Logo = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  width: 30%;
  background-color: ${(props) =>
    props.theme.texts.colors.brand.primaryGreen[10]};
  padding: 1rem;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    min-width: 40%;
    img {
      height: 2rem;
    }
  }
`;

const AppName = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  background-color: ${(props) =>
    props.theme.texts.colors.brand.primaryGreen[10]};
  padding: 1rem;
  h1 {
    margin: 0 0 0 15px;
    color: white;
    font-weight: bold;
  }
`;

export const SetupImage = (): React.ReactElement => {
  return (
    <>
      <Image />
      <Logo>
        <img src={BASFlogo} alt='logo' />
      </Logo>
      <AppName>
        <h1>OttoKalc™</h1>
      </AppName>
    </>
  );
};
