import { gql } from '@apollo/client';

export const UPDATE_FORMULATION = gql`
  mutation UpdateFormulation(
    $data: FormulationUpdateInput!
    $where: FormulationWhereUniqueInput!
  ) {
    updateFormulation(data: $data, where: $where) {
      id
    }
  }
`;
