import moment from 'moment';
import { createSelector } from 'reselect';
import { AppStateInterface } from '../reducers/interfaces';
import { getFormulations } from './formulations';
import { getMaterials } from './materials';

export const getSelectedUser = (state: AppStateInterface) => state.selectedUser;

export const getHomeInformation = createSelector(
  [getMaterials, getFormulations, getSelectedUser],
  (materials, formulations, selectedUser) => {
    const totalResins = selectedUser?.materials.filter(
      (x) => x.materialType === 'Resins',
    ).length;
    const totalIsocyanates = selectedUser?.materials.filter(
      (x) => x.materialType === 'Isocyanates',
    ).length;
    const totalFormulations = selectedUser?.formulations.length;

    return {
      customResins: totalResins,
      customIsocyanates: totalIsocyanates,
      customFormulations: totalFormulations,
    };
  },
);

export const getRecentFormulations = createSelector(
  [getFormulations],
  (formulations) => {
    const ts = Math.round(new Date().getTime() / 1000);
    const yesterday = ts - 24 * 3600;

    return formulations.filter((x) => {
      let formulationDate = x.lastEdit;
      if (typeof formulationDate === 'string')
        formulationDate = moment(formulationDate).unix();

      return formulationDate > yesterday && x.owner === 'Personal';
    });
  },
);

export const getIsAuthenticated = (state: AppStateInterface) =>
  state.isAuthenticated;

export const getToken = (state: AppStateInterface) => state.userToken;
