import styled from 'styled-components';
import {
  CalendarOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';

export const Container = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin: 24px 0;
  .ant-space {
    flex-wrap: wrap;
    .ant-space-item {
      margin-bottom: 24px;
    }
    @media (min-width: 990px) {
      .ant-space-item {
        margin-bottom: 0;
      }
    }
  }
`;

export const SearchIcon = styled(SearchOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.texts.colors.brand.neutral[60]};
`;

export const CalendarIcon = styled(CalendarOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.texts.colors.brand.neutral[60]};
`;

export const CloseCircleIcon = styled(CloseCircleOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme.texts.colors.brand.neutral[60]};
`;

export const SpaceStretch = styled(Space)`
  align-items: stretch;
`;
