import { ReactNode } from 'react';

export enum FilterType {
  SEARCH = 'search',
  SELECT = 'select',
  DATE = 'date',
}

export type Comparator<T = any> = (value1: T, value2: string) => boolean;

export interface Comparators extends Partial<Record<FilterType, Comparator>> {
  default: Comparator;
}

export interface FilterOption {
  value: string;
  label: ReactNode;
}

export interface FilterField<T> {
  field: keyof T;
  comparator?: Comparator;
}

export interface Filter<T> {
  key: string;
  columns: FilterField<T>[];
  type: FilterType;
  placeholder: string;
  options?: FilterOption[];
}

export type FiltersMap = Record<string, string | undefined>;

export interface FiltersProps<T> {
  applyFiltersToTable: (filter: FiltersMap, filtersList: Filter<T>[]) => void;
  filtersList: Filter<T>[];
  filters?: FiltersMap;
  searchWithFilter: (
    e: React.ChangeEvent<HTMLInputElement>,
    filter: Filter<T>,
  ) => void;
  changeFilters: (
    value: string | number | undefined,
    filterName: string,
  ) => void;
  clearFilters: () => void;
}
