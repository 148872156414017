import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import {
  AppStateInterface,
  IsocyanateInterface,
  ResinInterface,
} from '../reducers/interfaces';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getMaterials = (
  state: AppStateInterface,
): (ResinInterface | IsocyanateInterface)[] => {
  return state.materials && state.selectedUser
    ? [...state.materials, ...state.selectedUser!.materials]
    : [];
};

export const getResins = createDeepEqualSelector([getMaterials], (materials) =>
  materials.filter(
    (material): material is ResinInterface =>
      material.materialType === 'Resins',
  ),
);

export const getIsocyanates = createDeepEqualSelector(
  [getMaterials],
  (materials) =>
    materials.filter(
      (material): material is IsocyanateInterface =>
        material.materialType === 'Isocyanates',
    ),
);
