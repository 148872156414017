import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    textMain: '#fff',
    textSecondary: '#65AC1E',
    buttonMain: '#65AC1E',
    buttonSecondary: '#EAFFD6',
  },
  texts: {
    bold: 500,
    colors: {
      brand: {
        primaryGreen: {
          100: '#366B01',
          80: '#4A8C08',
          60: '#65AC1E',
          40: '#84D136',
          20: '#EAFFD6',
          10: '#4FA830',
        },
        secondaryBlue: {
          100: '#00366D',
          80: '#004A96',
          60: '#0669CF',
          40: '#65ADF7',
          20: '#C4E1FF',
        },
        neutral: {
          100: '#121A2C',
          80: '#454D5F',
          60: '#6C7689',
          40: '#A6AEBF',
          20: '#F1F4FB',
          10: '#F7F8FB',
        },
      },
      semantic: {
        success: {
          100: '#0A5C30',
          80: '#10934D',
          60: '#16C568',
          40: '#93EBBC',
          20: '#D2F9E4',
        },
        error: {
          100: '#6E0C0C',
          80: '#A51212',
          60: '#E61A1A',
          40: '#F28C8C',
          20: '#FAD1D1',
        },
        warning: {
          100: '#7A4800',
          80: '#B36800',
          60: '#FFBF00',
          40: '#FFDF80',
          20: '#FFF2CC',
        },
        info: {
          100: '#0F5A6B',
          80: '#1787A1',
          60: '#18B8DC',
          40: '#90DEEF',
          20: '#D2F2F9',
        },
      },
    },
    heroTitle: {
      size: '40px',
    },
    layoutTitle: {
      size: '32px',
    },
    subtitle: {
      size: '24px',
    },
    largeText: {
      size: '20px',
    },
    text: {
      size: '16px',
    },
    smallText: {
      size: '14px',
    },
    labelTextMedium: {
      size: '12px',
    },
    labelText: {
      size: '10px',
    },
  },
  nav: {
    height: '64px',
  },
  footer: {
    height: '64px',
  },
};
