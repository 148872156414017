// @ts-nocheck
import { useMutation } from '@apollo/client';
import { Checkbox, List } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { IsocyanatesTable } from '../../../containers/CalculatorsPage/components/CalculatorIsocyanates.interface';
import { ResinsTable } from '../../../containers/CalculatorsPage/components/CalculatorResins.interface';
import {
  CREATE_FORMULATION_FOR_USER,
  CREATE_MATERIAL,
} from '../../../graphql/mutations';
import createFormulationPayload from '../../../helpers/createFormulationPayload';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { sendNotification } from '../../../helpers/sendNotification';
import { addCustomMaterial } from '../../../redux/actions/app';
import { updateSelectedUser } from '../../../redux/actions/user';
import { getMaterials } from '../../../redux/selectors/materials';
import { getSelectedUser } from '../../../redux/selectors/user';
import { Button } from '../../Button/Button';
import { ImportModalProps } from './ImportModal.interface';
import { ModalWithList } from './ImportModal.style';

export function ImportModal<
  T extends {
    key: string;
    title?: string;
    name?: string;
    materials?: { resins: ResinsTable[]; isocyanates: IsocyanatesTable[] };
  },
>({
  data = [],
  handleImport,
  validationSchema,
  ...modalProps
}: ImportModalProps<T>) {
  const [addCustomMaterialToDB] = useMutation(CREATE_MATERIAL);
  const dispatch = useDispatch();
  const basfMaterials = useSelector(getMaterials).filter(
    (x) => x.owner === 'BASF',
  );
  const selectedUser = useSelector(getSelectedUser);
  const isOnlineAccount = isAccountOnline(selectedUser);

  const [selectedImports, setSelectedImports] = useState<T[]>([]);
  const [duplicated, setDuplicated] = useState<T[]>([]);
  const [imported, setImported] = useState<T[]>([]);
  const [rejected, setRejected] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isDbError, setIsDbError] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [addFormulationToDB] = useMutation(CREATE_FORMULATION_FOR_USER);

  const addMaterialToDb = async (item: any) => {
    const dbMaterialsData = {
      data: {
        id: uuid(),
        key: uuid(),
        title: item.title,
        ohNumber: String(item.ohNumber),
        nco: item.nco ? String(item.nco) : '0',
        noFunctionality: String(item.noFunctionality) || '0',
        materialType: item.materialType,
        eqWeight: String(item.eqWeight),
        comments: item.comments,
        owner: {
          connect: {
            id: selectedUser?.id,
          },
        },
        type: {
          create: {
            value: item.type,
            author: {
              connect: {
                id: selectedUser?.id,
              },
            },
          },
        },
      },
    };

    return await addCustomMaterialToDB({ variables: dbMaterialsData });
  };

  const testValidationResults = async (
    validationCheck: Promise<
      PromiseSettledResult<
        | {
            item: T;
            success: boolean;
            errors: never[];
          }
        | {
            item: T;
            success: boolean;
            errors: any;
          }
      >[]
    >,
  ) => {
    validationCheck.then(async (results) => {
      const toBeImported: T[] = [];
      const toBeDuplicated: T[] = [];
      const toBeRejected: T[] = [];
      let nonExistentIso: any[] = [];
      let nonExistentRes: any[] = [];

      for (let rI = 0; rI < results.length; rI++) {
        if (results[rI].status === 'fulfilled') {
          const { success, errors, item } = results[rI].value;
          if (success) {
            if (item.materials) {
              if (item.materials.resins) {
                item.materials.resins.map((x: any) => {
                  const userMaterialIndex = selectedUser?.materials.findIndex(
                    (j) => x.title === j.title,
                  );

                  if (userMaterialIndex !== -1) {
                    x.key = selectedUser?.materials[userMaterialIndex].key;
                    x.materialKey =
                      selectedUser?.materials[userMaterialIndex].id;
                  }

                  const index = basfMaterials.findIndex(
                    (j) => j.title === x.title,
                  );

                  if (index !== -1) {
                    x.key = basfMaterials[index].key;
                    x.materialKey = basfMaterials[index].id;
                  }

                  if (!x.type) {
                    x.type = 'Resin';
                  }

                  return x;
                });

                nonExistentRes = item.materials.resins.filter((x: any) => {
                  return (
                    !selectedUser?.materials.some((j) => j.title === x.title) &&
                    x.owner !== 'BASF' &&
                    x.owner !== 'basf@email.com'
                  );
                });
              }

              if (item.materials.isocyanates) {
                item.materials.isocyanates.map((x: any) => {
                  const userMaterialIndex = selectedUser?.materials.findIndex(
                    (j) => x.title === j.title,
                  );
                  const index = basfMaterials.findIndex(
                    (j) => j.title === x.title,
                  );

                  if (userMaterialIndex !== -1) {
                    x.key = selectedUser?.materials[userMaterialIndex].key;
                    x.materialKey =
                      selectedUser?.materials[userMaterialIndex].id;
                  }
                  if (index !== -1) {
                    x.key = basfMaterials[index].key;
                    x.materialKey = basfMaterials[index].id;
                  }
                  if (!x.type) {
                    x.type = 'Isocyanate';
                  }
                  return x;
                });

                nonExistentIso = item.materials.isocyanates.filter((x: any) => {
                  return (
                    !selectedUser?.materials.some((j) => j.title === x.title) &&
                    x.owner !== 'BASF' &&
                    x.owner !== 'basf@email.com'
                  );
                });
              }

              if (isOnlineAccount) {
                if (nonExistentIso.length) {
                  for (let nI = 0; nI < nonExistentIso.length; nI++) {
                    await addMaterialToDb(nonExistentIso[nI])
                      .then((sx: any) => {
                        const index = item.materials?.isocyanates.findIndex(
                          (s: any) => s.title === nonExistentIso[nI].title,
                        );

                        if (index !== -1 && item.materials) {
                          item.materials.isocyanates[index].key =
                            sx.data.createMaterial.key;

                          item.materials.isocyanates[index].materialKey =
                            sx.data.createMaterial.id;

                          nonExistentIso[nI].key = sx.data.createMaterial.key;
                          nonExistentIso[nI].id = sx.data.createMaterial.id;
                          nonExistentIso[nI].materialKey =
                            sx.data.createMaterial.id;

                          dispatch(addCustomMaterial(nonExistentIso[nI]));
                          dispatch(updateSelectedUser());
                        }
                      })
                      .catch(() => setIsDbError(true));
                  }
                }

                if (nonExistentRes.length) {
                  for (let nRs = 0; nRs < nonExistentRes.length; nRs++) {
                    await addMaterialToDb(nonExistentRes[nRs])
                      .then((sx: any) => {
                        const index = item.materials?.resins.findIndex(
                          (s: any) => s.title === nonExistentRes[nRs].title,
                        );

                        if (index !== -1 && item.materials) {
                          item.materials.resins[index].key =
                            sx.data.createMaterial.key;
                          item.materials.resins[index].materialKey =
                            sx.data.createMaterial.id;

                          nonExistentRes[nRs].key = sx.data.createMaterial.key;
                          nonExistentRes[nRs].id = sx.data.createMaterial.id;
                          nonExistentRes[nRs].materialKey =
                            sx.data.createMaterial.id;

                          dispatch(addCustomMaterial(nonExistentRes[nRs]));
                          dispatch(updateSelectedUser());
                        }
                      })
                      .catch(() => setIsDbError(true));
                  }
                }
              }
            }

            if (isOnlineAccount) {
              setIsLoading(true);
              if (!item.materials) {
                await addMaterialToDb(item)
                  .then((sx) => {
                    const updatedItem = {
                      ...item,
                      id: sx.data.createMaterial.id,
                      key: sx.data.createMaterial.id,
                    };
                    toBeImported.push(updatedItem);
                    dispatch(handleImport(updatedItem));
                  })
                  .catch(() => {
                    setIsDbError(true);
                  });
              } else {
                const newId = uuid();
                item.key = newId;
                item.id = newId;
                const payload = createFormulationPayload(item);
                await addFormulationToDB({ variables: payload })
                  .then(() => {
                    toBeImported.push(item);
                    dispatch(handleImport(item));
                  })
                  .catch((e) => {
                    setIsDbError(true);
                  });
              }
            } else {
              toBeImported.push(item);
              dispatch(handleImport(item));
            }
            dispatch(updateSelectedUser());
          } else if (!success && errors.includes('duplicated_name')) {
            toBeDuplicated.push(item);
          } else if (
            !success &&
            errors.length === 1 &&
            errors.includes('duplicated_key')
          ) {
            const key = uuid();
            const id = uuid();
            const duplicatedKeyItem = { ...item, key, id };
            if (isOnlineAccount) {
              setIsLoading(true);
              if (!duplicatedKeyItem.materials) {
                await addMaterialToDb(duplicatedKeyItem)
                  .then(() => {
                    toBeImported.push(duplicatedKeyItem);
                    dispatch(handleImport(duplicatedKeyItem));
                  })
                  .catch(() => {
                    setIsDbError(true);
                  });
              } else {
                const payload = createFormulationPayload(duplicatedKeyItem);
                await addFormulationToDB({ variables: payload })
                  .then(() => {
                    toBeImported.push(item);
                    dispatch(handleImport(item));
                  })
                  .catch(() => {
                    setIsDbError(true);
                  });
              }
            } else {
              toBeImported.push(duplicatedKeyItem);
              dispatch(handleImport(duplicatedKeyItem));
            }
          } else if (!success && errors.includes('reject')) {
            toBeRejected.push(item);
          }
        } else {
          const text = `Oooops! ${results[rI].reason}`;
          sendNotification('error', { text });
        }
      }
      setDuplicated(toBeDuplicated);
      setImported(toBeImported);
      setRejected(toBeRejected);
    });
  };

  const handleDataImport = useCallback(
    (e) => {
      setIsDuplicated(false);
      setIsImporting(true);
      if (!selectedImports?.length || !validationSchema) return;
      const validationCheck = Promise.allSettled(
        selectedImports.map((item: T) =>
          validationSchema
            .validate(item, { abortEarly: false })
            .then(() => {
              return { item, success: true, errors: [] };
            })
            .catch((error: any) => {
              return { item, success: false, errors: error?.errors };
            }),
        ),
      );

      testValidationResults(validationCheck);
      modalProps.onOk?.(e);
    },
    [selectedImports],
  );

  useEffect(() => {
    if (rejected.length !== 0) {
      const text = 'Following imports failed due to invalid data format';
      const names = rejected
        .map((item) => item?.name || item?.title)
        .join(', ');

      sendNotification('error', { text, names });
    }
    if (duplicated.length !== 0) {
      setIsDuplicated(true);

      const text =
        'Following elements already exist in your library and were skipped';
      const names = duplicated
        .map((item) => item?.name || item?.title)
        .join(', ');

      sendNotification('warn', { text, names });
      setDuplicated([]);
    }
    if (imported.length !== 0) {
      const timeout = isDuplicated ? 2500 : 0;
      const text = `${
        isDuplicated ? 'A part of the data' : 'The data'
      } was successfully imported`;

      setTimeout(() => {
        sendNotification('success', { text });
      }, timeout);

      if (isOnlineAccount) setIsLoading(false);
      setImported([]);
    }
    if (isDbError) {
      const text = "Oooops! Can't update database, try again later.";

      sendNotification('error', { text });
      setIsDbError(false);
      setIsLoading(false);
    }
    setIsImporting(false);
    setRejected([]);
  }, [
    imported.length,
    duplicated.length,
    rejected.length,
    isDbError,
    isDuplicated,
  ]);

  const handleSelectAll = () => {
    if (selectedImports && data && selectedImports?.length < data?.length) {
      setSelectedImports(data);
    } else {
      setSelectedImports([]);
    }
  };

  const handleSelectOption = (selectedItem: T) => {
    const updatedImports = [...selectedImports];
    const checkedId =
      selectedImports &&
      selectedImports?.findIndex((item) => item?.key === selectedItem?.key);
    if (checkedId >= 0) {
      updatedImports?.splice(checkedId, 1);
    } else {
      updatedImports.push(selectedItem);
    }
    setSelectedImports(updatedImports);
  };

  const isSelected = (key: string | undefined) => {
    if (!key) return false;
    const selectedId = selectedImports.findIndex((item) => item?.key === key);
    return selectedId >= 0;
  };

  useEffect(() => {
    setSelectedImports(data);
  }, [data?.length]);

  return (
    <ModalWithList
      {...modalProps}
      centered
      visible={Boolean(data.length) || isLoading || isImporting}
      title='Import'
      footer={[
        <Button
          key='import-modal-cancel-action'
          dataCy='import-modal-cancel-action'
          type='ghost'
          onClick={modalProps.onCancel}
          size='large'
        >
          Cancel
        </Button>,
        <Button
          loading={isLoading || isImporting ? true : false}
          key='import-modal-import-action'
          dataCy='import-modal-import-action'
          type='primary'
          size='large'
          onClick={handleDataImport}
        >
          {isLoading ? 'Updating Database' : 'Import'}
        </Button>,
      ]}
    >
      {!isLoading && !isImporting && (
        <List
          itemLayout='horizontal'
          dataSource={data}
          size='large'
          header={
            <Checkbox
              onChange={handleSelectAll}
              checked={selectedImports?.length === data?.length}
              id='all'
            >
              Name
            </Checkbox>
          }
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                description={
                  <Checkbox
                    key={item?.key}
                    onChange={() => handleSelectOption(item as T)}
                    checked={isSelected(item?.key)}
                  >
                    {item?.title || item?.name}
                  </Checkbox>
                }
              />
            </List.Item>
          )}
        />
      )}
    </ModalWithList>
  );
}
