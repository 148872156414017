import { gql } from '@apollo/client';

export const CREATE_FORMULATION_FOR_USER = gql`
  mutation Mutation($data: FormulationCreateWithoutUserInput!) {
    createFormulationForUser(data: $data) {
      name
      id
      hydroxilNo
      index
      isoBatchWeight
      resBatchWeight
      nco
      ratio
      materialsInFormulation {
        part
        batchWeight
        calcCost
        ncoContribution
        ohContribution
        material {
          title
          key
          ohNumber
          eqWeight
          nco
          noFunctionality
          cost
          type {
            value
          }
          owner {
            email
          }
        }
      }
    }
  }
`;
