import localforage from 'localforage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { CalculatorsPage } from '../../containers/CalculatorsPage/CalculatorsPage';
import { FormulationPage } from '../../containers/FormulationPage/FormulationPage';
import { HomePage } from '../../containers/HomePage/HomePage';
import { LoginPage } from '../../containers/LoginPage/LoginPage';
import { MaterialCreate } from '../../containers/MaterialCreate/MaterialCreate';
import { MaterialsPage } from '../../containers/MaterialsPage/MaterialsPage';
import { NotFoundPage } from '../../containers/NotFoundPage/NotFoundPage';
import { UserProfilePage } from '../../containers/UserProfilePage/UserProfilePage';
import { isDataFetching } from '../../redux/actions/app';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { Faq } from '../../containers/Faq/Faq';
import { CookiePage } from '../../containers/CookiePage/CookiePage';

export const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const userAuthenticated = useSelector(
    (state: { isAuthenticated: boolean }) => state.isAuthenticated,
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | unknown>(
    true,
  );
  const isFetching = useSelector(
    (state: { isFetching: boolean }) => state.isFetching,
  );

  useEffect(() => {
    const isAuthenticatedValue = async () => {
      const value = await localforage.getItem('reduxPersist:isAuthenticated');
      if (JSON.parse(value as string) === false || !JSON.parse(value as string))
        setIsAuthenticated(false);
      dispatch(isDataFetching());
    };
    isAuthenticatedValue();
  }, [isAuthenticated, userAuthenticated]);

  const checkAuthority = (component: JSX.Element) => {
    const url = window.location.href;
    const resetPassword = url.includes('newPassword');

    if (isFetching && isAuthenticated) return <LoadingIndicator />;
    if (url.includes('cookies')) return <CookiePage />;
    if (!isAuthenticated && !userAuthenticated)
      return <LoginPage resetPassword={resetPassword} />;

    dispatch(isDataFetching());
    return component;
  };

  return (
    <Routes>
      <Route element={checkAuthority(<LoginPage />)} path='/' />
      <Route element={checkAuthority(<CookiePage />)} path='/cookies' />
      <Route
        element={checkAuthority(<LoginPage resetPassword />)}
        path='/newPassword/:token'
      />
      <Route element={checkAuthority(<UserProfilePage />)} path='/me' />
      <Route element={checkAuthority(<HomePage />)} path='/main' />
      <Route element={checkAuthority(<HomePage tour />)} path='/main/tour' />
      <Route
        element={checkAuthority(<HomePage dashboard />)}
        path='/main/dashboard'
      />
      <Route
        element={checkAuthority(<FormulationPage />)}
        path='/formulation'
      />
      <Route
        element={checkAuthority(<CalculatorsPage />)}
        path='/formulation/calculator'
      />
      <Route
        element={checkAuthority(<CalculatorsPage saved />)}
        path='/formulation/calculator/:name'
      />
      <Route element={checkAuthority(<MaterialsPage />)} path='/materials' />
      <Route
        element={checkAuthority(<MaterialCreate />)}
        path='/materials/new/:connection'
      />
      <Route
        element={checkAuthority(<MaterialCreate />)}
        path='/materials/new/'
      />
      <Route
        element={checkAuthority(<MaterialCreate />)}
        path='/materials/update/'
      />
      <Route element={checkAuthority(<Faq />)} path='/faq' />
      <Route element={<NotFoundPage />} path='*' />
    </Routes>
  );
};
