import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWithList = styled(Modal)`
  .ant-modal-header {
    border-bottom: 1px solid #ffffff;
    .ant-modal-title {
      font-size: ${({ theme }) => theme.texts.largeText.size};
    }
  }
  .ant-modal-body {
    padding: 0;
    overflow-y: scroll;
    max-height: 360px;
    .ant-list,
    .ant-list-split {
      .ant-list-header {
        position: sticky;
        top: 0;
        padding: 16px 24px;
        background: #ffffff;
        z-index: 100;

        .ant-checkbox + span {
          font-weight: ${({ theme }) => theme.texts.bold};
        }
      }
      .ant-checkbox + span {
        padding-left: 24px;
      }
    }
  }
  .ant-modal-footer {
    padding: 24px;
    border-top: 1px solid #ffffff;
  }
`;
