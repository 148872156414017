export const securityQuestions = [
  { value: 'What is your favorite book?' },
  { value: 'What is the name of the road you grew up on?' },
  { value: 'What is your mother’s maiden name?' },
  { value: 'What was the name of your first/current/favorite pet?' },
  { value: 'What was the first company that you worked for?' },
  { value: 'Where did you meet your spouse?' },
  { value: 'Where did you go to high school/college?' },
  { value: 'What is your favorite food?' },
  { value: 'What city were you born in?' },
  { value: 'Where is your favorite place to vacation?' },
];
