import { Space } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  padding: 50px 50px;

  .ant-row.formulationButtonRow {
    justify-content: center;
    button {
      margin-top: 10px;
      &::first-child {
        margin-top: 0px;
      }
    }
    @media (min-width: 640px) {
      justify-content: space-between;
    }
  }
`;

export const SpaceButtons = styled(Space)`
  display: flex;
  flex-direction: column;
  .ant-space-item {
    width: 100%;
    button {
      width: 100%;
    }
  }
  @media (min-width: 640px) {
    display: inline-flex;
    flex-direction: row;
  }
`;
