import { generateFilterOptions } from '../../../../components/TableFilters/TableFilters.helpers';
import { ResinInterface } from '../../../../redux/reducers/interfaces';
import {
  Filter,
  FilterType,
} from '../../../../components/TableFilters/TableFilters.interface';

export const materialsFilters = (
  resin: ResinInterface[],
): Filter<ResinInterface>[] => [
  {
    key: 'title_comments',
    columns: [{ field: 'title' }, { field: 'comments' }],
    type: FilterType.SEARCH,
    placeholder: 'Search by name and comments',
  },
  {
    key: 'owner',
    columns: [{ field: 'owner' }],
    type: FilterType.SELECT,
    options: generateFilterOptions('owner', resin),
    placeholder: 'Owner',
  },
  {
    key: 'type',
    columns: [{ field: 'type' }],
    type: FilterType.SELECT,
    options: generateFilterOptions('type', resin),
    placeholder: 'Type',
  },
];
