import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Footer, Navigation } from '../components';
import { getIsAuthenticated } from '../redux/selectors/user';
import { RouterLayoutProps } from './RouterLayout.interface';
import { Utag, useUtag } from '../providers/UTagProvider/UTagProvider';

const Container = styled.div`
  padding-top: ${({ theme }) => theme.nav.height};
  background: ${({ theme }) => theme.texts.colors.brand.neutral[20]};
  display: flex;
  flex-direction: column;
  max-height: 100%;
  main {
    flex: 1 1 100%;
    min-height: ${({ theme }) =>
      `calc(100vh - ${theme.nav.height} - ${theme.footer.height})`};
  }
`;

export const RouterLayout: FC<RouterLayoutProps> = (props) => {
  const { children } = props;
  const utag: Utag = useUtag();
  const location = useLocation();

  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (location.pathname) {
      utag.view({
        page_name: location.pathname,
      });
    }
  }, [location]);

  return (
    <Container>
      {location.pathname !== '/' && isAuthenticated && <Navigation />}
      {children}
      {location.pathname !== '/' &&
        isAuthenticated &&
        location.pathname !== '/me' && <Footer />}
    </Container>
  );
};
