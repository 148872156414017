import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { MaterialDetailsProps } from './MaterialDetails.interface';
import { LabeledText } from '../../../../components/LabeledText/LabeledText';
import { getFormulations } from '../../../../redux/selectors/formulations';

export const MaterialDetails = ({
  material,
  handleVisibility,
  visibility,
}: MaterialDetailsProps) => {
  const [arr, setArr] = useState<{ id: string; name: string }[]>([]);
  const formulations = useSelector(getFormulations);

  useEffect(() => {
    if (material) {
      const list: { id: string; name: string }[] = [];
      if (material.materialType === 'Resins') {
        formulations.map((x) =>
          x.materials.resins.map(
            (j) =>
              j.key === material.key && list.push({ id: x.key, name: x.name }),
          ),
        );
      } else {
        formulations.map((x) =>
          x.materials.isocyanates.map(
            (j) =>
              j.key === material.key && list.push({ id: x.key, name: x.name }),
          ),
        );
      }
      setArr(list);
    }
  }, [material, formulations, visibility]);

  return (
    <Modal
      visible={visibility}
      onOk={handleVisibility}
      onCancel={handleVisibility}
      title={`Details of ${material?.title}`}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <LabeledText
            data-cy='calculator-output-resin-hydroxyl-no'
            label='Type'
            text={material?.type}
          />
        </Col>
        <Col span={12}>
          <LabeledText
            data-cy='calculator-output-resin-hydroxyl-no'
            label='Owner'
            text={material?.owner}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {Boolean(material?.comments) && (
            <LabeledText
              data-cy='calculator-output-resin-hydroxyl-no'
              label='Comments'
              text={material?.comments}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {Boolean(arr.length) && (
            <LabeledText
              data-cy='calculator-output-resin-hydroxyl-no'
              label='Used In'
              text={arr.map((x, i) => [
                i > 0 && ', ',
                <Link to={`/formulation/calculator/${x.id}`}>{x.name}</Link>,
              ])}
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};
