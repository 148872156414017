import React from 'react';
import { Link } from 'react-router-dom';
import notFoundIcon from '../../resources/404.svg';
import {
  Content,
  NotFoundIcon,
  Center,
  Title,
  Subtitle,
} from './NotFoundPage.styles';
import { Button } from '../../components';

export const NotFoundPage = () => {
  return (
    <Content>
      <Center>
        <NotFoundIcon src={notFoundIcon} />
        <Title level={4}>
          Oops, the page you were looking for doesn’t exist
        </Title>
        <Subtitle>
          You may have mistyped the address or the page may have moved.
        </Subtitle>
        <br />
        <Link to='/main' type='primary'>
          <Button>Go to Dashboard</Button>
        </Link>
      </Center>
    </Content>
  );
};
