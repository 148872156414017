import styled from 'styled-components';
import { Input, Checkbox, Select, DatePicker, InputNumber } from 'antd';

export const TextFieldInputStyled = styled<typeof Input>(Input)`
  padding: 15px 10px;
  font-weight: 500;
  width: 100%;
  border: 1px solid white;
  min-width: 100%;
  @media (min-width: 640px) {
    min-width: 300px;
  }
  border: 0;
  &.ant-input,
  &.ant-input-affix-wrapper {
    background-color: #f1f4fb;
    border: 1px solid white;
  }
  &.ant-input-affix-wrapper .ant-input {
    background-color: #f1f4fb;
    padding-left: 0.75rem;
  }
  &.ant-input-affix-wrapper .ant-input:focus {
    background-color: #fff;
  }
  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused,
  &.ant-input:focus {
    border: 1px solid #65ac1e;
    background-color: #fff;
    box-shadow: none;
  }
`;

export const NumberFieldInputStyled = styled(InputNumber)`
  padding: 10px 10px;
  font-weight: 500;
  width: 100%;
  border: 1px solid white;
  min-width: 100%;
  @media (min-width: 640px) {
    min-width: 200px;
  }
  border: 0;
  &.ant-input-number,
  &.ant-input-number-wrap {
    background-color: #f1f4fb;
    border: 1px solid white;
  }
  &.ant-input-number-wrap .ant-input-number-input {
    background-color: #f1f4fb;
    padding-left: 0.75rem;
  }
  &.ant-input-number-wrap .ant-input-number-input:focus {
    background-color: #fff;
  }
  &.ant-input-number-wrap:focus,
  &.ant-input-number-focused,
  &.ant-input-number-input:focus {
    border: 1px solid #65ac1e;
    background-color: #fff;
    box-shadow: none;
  }
`;

export const PasswordInputStyled = styled(Input.Password)`
  background-color: #f1f4fb;
  padding: 15px 10px;
  width: 100%;
  font-weight: 500;
  border: 1px solid white;
  input {
    background-color: #f1f4fb;
  }
  input:focus {
    background-color: #fff;
  }
  &.ant-input-password:focus-within {
    border: 1px solid #65ac1e;
    background-color: #fff;
    box-shadow: none;
  }
`;

export const TextareaInputStyled = styled(Input.TextArea)`
  padding: 15px 10px;
  font-weight: 500;
  width: 100%;
  border: 1px solid white;
  &.ant-input {
    background-color: #f1f4fb;
  }
  &.ant-input:focus {
    border: 1px solid #65ac1e;
    background-color: #fff;
    box-shadow: none;
  }
`;

export const CheckboxInputStyled = styled(Checkbox)`
  width: 100%;
  .ant-checkbox-inner {
    background-color: #f1f4fb;
    border: 1px solid #a6aebf;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 2px solid #65ac1e;
    border-top: 0;
    border-left: 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #eaffd6;
    border: 1px solid #84d136;
  }
`;

export const SelectInputStyled = styled(Select)`
  width: 100%;
  min-width: 200px;
  border: 1px solid white;
  &.ant-select {
    background-color: #f1f4fb;
  }
  &.ant-select:focus-within {
    border: 1px solid #65ac1e;
    background-color: #fff;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    min-height: 3.2rem;
    display: flex;
    align-items: center;
  }
  &.ant-select-single:not(.ant-select-open) .ant-select-selector {
    background-color: #f1f4fb;
  }
  .ant-select-selector input {
    min-height: 100%;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  min-width: 200px;

  &.ant-picker {
    height: 100%;
    background-color: #f1f4fb;
    border: none;
    box-shadow: none;
    border-radius: 0;

    &:focus-within {
      border: 1px solid #65ac1e;
      background-color: #fff;
    }
  }
`;
