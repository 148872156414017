import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import { Typography } from 'antd';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { duplicateFormulation } from '../../../redux/actions/formulation';
import { DuplicateFormulationModalProps } from './DuplicateFormulationModal.interface';
import { TextFieldInputStyled } from '../../Input/Input.styles';
import { Button } from '../../Button/Button';

export const DuplicateFormulationModal = ({
  formulation,
  formulations = [],
  ...modalProps
}: DuplicateFormulationModalProps) => {
  const dispatch = useDispatch();

  const [name, setName] = useState<string>();

  const nameIsUnique: boolean = !formulations.find(
    (formulationFind) => formulationFind.name === name,
  );
  const valid: boolean = Boolean(name?.length) && nameIsUnique;

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setName(e.target.value);

  const handleFormulationDuplicate = useCallback(
    (e) => {
      if (!valid || !name?.length) return;
      if (formulation) {
        dispatch(
          duplicateFormulation({
            ...formulation,
            name,
          }),
        );
      }
      modalProps.onOk?.(e);
      setName(undefined);
    },
    [formulation, name],
  );

  useEffect(() => {
    setName(formulation?.name);
  }, [formulation]);

  return (
    <Modal
      {...modalProps}
      centered
      visible={Boolean(formulation)}
      title='Duplicate'
      footer={[
        <Button type='ghost' onClick={modalProps.onCancel}>
          Cancel
        </Button>,
        <Button
          type='primary'
          disabled={!valid}
          onClick={handleFormulationDuplicate}
        >
          Duplicate
        </Button>,
      ]}
    >
      <Typography.Title level={4}>Rename duplicate</Typography.Title>
      <Form>
        <FormItem
          hasFeedback={!nameIsUnique}
          validateStatus='error'
          help={nameIsUnique ? '' : 'Name must be unique'}
        >
          <TextFieldInputStyled
            autoFocus
            value={name}
            onChange={handleNameChange}
            onPressEnter={handleFormulationDuplicate}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};
