import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import { TableProps } from 'antd/lib/table';
import { GenericTable } from '../../../../components/GenericTable/GenericTable';
import { TableFilters } from '../../../../components/TableFilters/TableFilters';
import { materialsFilters } from './IsocyanatesContent.constants';
import { useDataSource } from '../../../../components/TableFilters/TableFilters.hooks';
import { getIsocyanates } from '../../../../redux/selectors/materials';
import {
  IsocyanateInterface,
  MaterialInterface,
} from '../../../../redux/reducers/interfaces';
import {
  DeleteOutlinedIcon,
  EditOutlinedIcon,
} from '../../../FormulationPage/FormulationPage.styles';
import { removeMaterial } from '../../../../redux/actions/app';
import { MaterialDetails } from '../MaterialDetails/MaterialDetails';
import { isAccountOnline } from '../../../../helpers/nameInitials';
import { getSelectedUser } from '../../../../redux/selectors/user';
import { useNavigate } from 'react-router-dom';
import { MaterialContentInterface } from '../../MaterialContent.interface';
import {
  Filter,
  FiltersMap,
} from '../../../../components/TableFilters/TableFilters.interface';
import { isEmpty } from 'lodash';

export const IsocyanatesContent = (
  tableProps: TableProps<IsocyanateInterface> & MaterialContentInterface,
) => {
  const [filters, setFilters] = useState<FiltersMap>({});
  const [visible, setVisible] = useState(false);
  const [material, setMaterial] = useState<MaterialInterface>();
  const materials = useSelector(getIsocyanates);
  const user = useSelector(getSelectedUser);
  const { dataSource, applyFiltersToTable, updateDataSource } =
    useDataSource(materials);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOnlineAccount = isAccountOnline(user);

  const handleFiltersChange = (
    value: string | number | undefined,
    filterName: string,
  ) => {
    let stringValue: string | undefined;

    if (typeof value === 'number') {
      stringValue = String(value);
    } else {
      stringValue = value;
    }

    setFilters((currentFilters) => ({
      ...currentFilters,
      [filterName]: stringValue,
    }));
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // @ts-ignore
    filter: Filter<T>,
  ) => {
    const { value } = e.target;
    handleFiltersChange(value, filter.key);
  };

  const handleFiltersClear = () => {
    setFilters({});
  };

  const handleIsoRemove = (key: string) => {
    dispatch(
      removeMaterial(
        key,
        'Isocyanate',
        user!,
        isOnlineAccount,
        tableProps.deleteMaterial,
        tableProps.setIsLoading,
      ),
    );
  };

  const openMaterialDetails = (resin: IsocyanateInterface) => {
    setMaterial(resin);
    setVisible(!visible);
  };

  const handleModal = () => {
    setVisible(!visible);
  };

  const isocyanatesMaterialColumns: any[] = [
    {
      title: 'Name',
      dataIndex: 'title',
      sorter: (a: any, b: any) => {
        return a.title.localeCompare(b.title);
      },
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
    },
    {
      title: 'NCO %',
      dataIndex: 'nco',
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
      render: (key: string, record: IsocyanateInterface) => {
        return record.nco ? Number(record.nco).toFixed(2) : 0;
      },
    },
    {
      title: 'Eq. Wt.',
      dataIndex: 'eqWeight',
      render: (eqWt: number, record: IsocyanateInterface) =>
        record.owner === 'BASF'
          ? Number(Math.round(eqWt))
          : Number(eqWt).toFixed(2),
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
    },
    {
      title: 'Functionality',
      dataIndex: 'noFunctionality',
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
      render: (key: string, record: IsocyanateInterface) => {
        return record.noFunctionality
          ? Number(record.noFunctionality).toFixed(2)
          : 0;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      onCell: (record: IsocyanateInterface) => {
        return {
          onClick: () => openMaterialDetails(record),
        };
      },
    },
    {
      title: '',
      dataIndex: 'key',
      render: (key: string, record: IsocyanateInterface) => {
        return record.owner !== 'BASF' ? (
          <div key={key}>
            <EditOutlinedIcon
              title='Edit'
              onClick={() =>
                navigate('/materials/update', { state: { key: key } })
              }
            />
            <DeleteOutlinedIcon
              title='Remove'
              onClick={() => handleIsoRemove(key)}
            />
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  useEffect(() => {
    if (materials.length) {
      updateDataSource(materials);
      if (!isEmpty(filters)) {
        applyFiltersToTable(filters, materialsFilters(materials));
      }
    }
  }, [user, materials]);

  return (
    <>
      <TableFilters
        filters={filters}
        changeFilters={handleFiltersChange}
        searchWithFilter={handleSearchChange}
        clearFilters={handleFiltersClear}
        filtersList={materialsFilters(materials)}
        applyFiltersToTable={applyFiltersToTable}
      />
      <Card>
        <MaterialDetails
          handleVisibility={handleModal}
          visibility={visible}
          material={material}
        />
        <GenericTable
          data-cy='isocyanates-materials-table'
          rowClassName='row-click'
          dataSource={dataSource}
          columns={isocyanatesMaterialColumns}
          {...tableProps}
        />
      </Card>
    </>
  );
};
