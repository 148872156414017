import { Tooltip, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import TooltipIcon from '../../resources/help-circle.svg';
import { ButtonStyled } from '../Button/Button.styles';
import {
  NumberFieldInputStyled,
  PasswordInputStyled,
  SelectInputStyled,
  TextFieldInputStyled,
} from '../Input/Input.styles';
import { FormInputFieldsProps } from './FormInputFields.interface';

const RequiredField = styled.div`
  color: ${(props) => props.theme.texts.colors.semantic.error[60]};
`;

const OptionalTag = styled.h5`
  opacity: 0.7;
  margin-left: 0.5rem;
`;

const FieldLabel = styled.label`
  display: flex;
  align-items: center;
  .tooltip {
    margin-left: 0.2rem;
    margin-bottom: 0.2rem;
  }
`;

const LengthCount = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddNewSelectOption = styled.div`
  display: flex;
  padding: 1rem;
`;

export const FormInputFields = ({
  item,
  selectOptions,
  handleChange,
  setFieldValue = () => {},
  errors,
  touched,
  values,
  placeholder = false,
  tooltip = false,
  text = '',
  maxLength,
  addSelectOption,
  setCustomOption = () => {},
  customOption,
  autoFocus,
}: FormInputFieldsProps): React.ReactElement => {
  const { Title } = Typography;

  return (
    <>
      <FieldLabel data-cy='input-label' htmlFor={item.id}>
        <Title level={5}>{item.label}</Title>
        {tooltip && (
          <Tooltip title={text}>
            <img className='tooltip' src={TooltipIcon} alt='tooltip' />
          </Tooltip>
        )}
        {!item.required && <OptionalTag>(optional)</OptionalTag>}
      </FieldLabel>
      {item.type === 'text' && (
        <>
          <TextFieldInputStyled
            value={(values as any)[item.id]}
            data-cy={`${item.id}-textfield`}
            id={item.id}
            onChange={handleChange}
            autoFocus={autoFocus}
          />
        </>
      )}
      {item.type === 'number' && (
        <NumberFieldInputStyled
          value={(values as any)[item.id]}
          data-cy={`${item.id}-textfield`}
          onChange={(value) => setFieldValue(item.id, value)}
          id={item.id}
          step='0.01'
          autoFocus={autoFocus}
          type='number'
        />
      )}
      {item.type === 'textarea' && (
        <>
          <TextFieldInputStyled
            value={(values as any)[item.id]}
            data-cy={`${item.id}-textfield`}
            id={item.id}
            onChange={handleChange}
            maxLength={maxLength}
            autoFocus={autoFocus}
          />
          <LengthCount>
            {(values as any)[item.id].length} / {maxLength}
          </LengthCount>
        </>
      )}
      {item.type === 'password' && (
        <PasswordInputStyled
          data-cy={item.id}
          id={item.id}
          onChange={handleChange}
          value={(values as any)[item?.id]}
          placeholder={
            placeholder
              ? '6+ characters with numbers and One Uppercase at least'
              : ''
          }
          autoFocus={autoFocus}
        />
      )}
      {item.type === 'select' && (
        <SelectInputStyled
          virtual={false}
          id={item.id}
          value={(values as any)[item?.id]}
          data-cy={`${item.id}-select`}
          showSearch
          showArrow
          onChange={(e) => setFieldValue(item.id, e)}
          options={selectOptions}
          autoFocus={autoFocus}
        />
      )}
      {item.type === 'editable-select' && (
        <SelectInputStyled
          virtual={false}
          id={item.id}
          value={(values as any)[item?.id]}
          data-cy={`${item.id}-select`}
          showSearch
          showArrow
          onChange={(e) => setFieldValue(item.id, e)}
          options={selectOptions}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <AddNewSelectOption>
                <TextFieldInputStyled
                  value={customOption}
                  data-cy='option-add'
                  id='custom-options'
                  onChange={(e) => setCustomOption(e.target.value)}
                />
                <ButtonStyled
                  onClick={addSelectOption}
                  type='text'
                  size='large'
                >
                  Add
                </ButtonStyled>
              </AddNewSelectOption>
            </div>
          )}
          autoFocus={autoFocus}
        />
      )}
      {(touched as any)[item.id] && (errors as any)[item.id] && (
        <RequiredField data-cy={`${item.id}-error`}>
          {(errors as any)[item.id]}
        </RequiredField>
      )}
    </>
  );
};

FormInputFields.defaultProps = {
  selectOptions: [],
  setFieldValue: () => {},
  maxLength: 100,
  addSelectOption: () => {},
  setCustomOption: () => {},
};
