import { Col, Row } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import { resinCreateFields } from '../../../constants/materialCreateFormFields';
import {
  addCustomMaterial,
  addMaterialType,
  getMaterialTypes,
  updateCustomMaterial,
} from '../../../redux/actions/app';
import {
  IsocyanateInterface,
  ResinInterface,
} from '../../../redux/reducers/interfaces';
import { getMaterials } from '../../../redux/selectors/materials';
import { getSelectedUser } from '../../../redux/selectors/user';
import { ResinValuesProps } from '../MaterialCreate.interface';
import { EquivalentWeightField } from './EquivalentWeightField';
import { ResinCreateFormProps } from './ResinCreateForm.interface';
import { useMutation } from '@apollo/client';
import { CREATE_MATERIAL, UPDATE_MATERIAL } from '../../../graphql/mutations';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { LoadingIndicator } from '../../../components';
import { QueryResult } from '../../../components/QueryResult/QueryResult';
import { InternetConnectionStatus } from '../MaterialCreate.styles';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const Formfields = styled.div`
  margin-bottom: 1rem;
`;

export const ResinCreateForm = (
  props: ResinCreateFormProps,
): React.ReactElement => {
  const [addCustomMaterialToDB, { loading, error }] =
    useMutation(CREATE_MATERIAL);
  const [
    updateCustomMaterialDB,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_MATERIAL);
  const { onSubmit, onUpdate } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isQueryError, setIsQueryError] = useState(false);
  const [customOption, setCustomOption] = useState('');
  const [isInternetConnection, setIsInternetConnection] = useState(true);
  const [isUpdateState, setIsUpdateState] = useState(false);
  const [updateMaterial, setUpdateMaterial] = useState<ResinInterface>();

  const dispatch = useDispatch();
  const selectOptions = useSelector(
    (state: { userMaterialTypes: [] }) => state.userMaterialTypes,
  );
  const selectedUser = useSelector(getSelectedUser);
  const isOnlineAccount = isAccountOnline(selectedUser);
  const materials = useSelector(getMaterials);
  const { connection } = useParams();

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (loading || updateLoading) {
      setIsQueryError(false);
      setIsLoading(true);
    }

    if (error || updateError) {
      setIsLoading(false);
      setIsQueryError(true);
    }
  }, [loading, updateLoading, error, updateError]);

  useEffect(() => {
    if ((state as { key: string })?.key) {
      setIsUpdateState(true);
    }

    if (connection === 'noInternetConnection') {
      setIsInternetConnection(false);
    }
    dispatch(getMaterialTypes());
  }, []);

  useEffect(() => {
    if (props.updateMaterial) {
      setUpdateMaterial(props.updateMaterial);
    }
  });

  const options = useMemo(() => {
    const userTypesOptions = [
      { value: 'Polyol' },
      { value: 'Isocyanate' },
      { value: 'Resin' },
      { value: 'Surfactant' },
      { value: 'Plasticizer' },
      { value: 'Pigment' },
      { value: 'Dye' },
    ];
    selectOptions.forEach((item: { value: string }) => {
      userTypesOptions.push({ value: item.value });
    });
    return userTypesOptions;
  }, [selectOptions]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .trim()
          .required('* Required')
          .notOneOf(
            materials.map((material) =>
              !isUpdateState ? material.title : null,
            ),
            'Must be unique',
          ),
        ohNumber: Yup.number().nullable().required('* Required'),
        noFunctionality: Yup.number().nullable().required('* Required'),
        type: Yup.string().trim().required('* Required'),
      }),
    [materials],
  );

  const handleCreateMaterial = async (values: ResinValuesProps) => {
    const id = uuid();

    const material: ResinInterface = {
      id: id,
      key: uuid(),
      title: values.title,
      owner: 'Personal',
      ohNumber: String(values.ohNumber),
      eqWeight: values.ohNumber && String(56100 / Number(values.ohNumber)),
      noFunctionality: String(values.noFunctionality),
      materialType: 'Resins',
      type: values.type,
      comments: values.comments,
    };

    const dbMaterialsData = {
      data: {
        id: id,
        key: material.key,
        title: material.title,
        ohNumber: String(material.ohNumber),
        noFunctionality: String(material.noFunctionality),
        materialType: material.materialType,
        eqWeight: String(material.eqWeight),
        comments: material.comments,
        owner: {
          connect: {
            id: selectedUser?.id,
          },
        },
        type: {
          create: {
            value: material.type,
            author: {
              connect: {
                id: selectedUser?.id,
              },
            },
          },
        },
      },
    };

    let isError = false;

    if (isOnlineAccount) {
      await addCustomMaterialToDB({ variables: dbMaterialsData })
        .then(() => {
          dispatch(addCustomMaterial(material));
        })
        .catch(() => {
          isError = true;
        });
    } else {
      dispatch(addCustomMaterial(material));
    }

    ReactGA.event({
      category: 'User',
      action: 'Created a Resin',
    });

    if (!isError) onSubmit();
  };

  const handleUpdateMaterial = async (values: ResinValuesProps) => {
    const updatedMaterial: ResinInterface = {
      ...(updateMaterial as ResinInterface),
      title: values.title,
      ohNumber: String(values.ohNumber),
      eqWeight: values.ohNumber && String(56100 / Number(values.ohNumber)),
      noFunctionality: String(values.noFunctionality),
      type: values.type,
      comments: values.comments,
    };

    const updateData = {
      where: {
        id: updatedMaterial.id,
      },
      data: {
        title: {
          set: updatedMaterial.title,
        },
        ohNumber: {
          set: updatedMaterial.ohNumber,
        },
        eqWeight: {
          set: String(
            updatedMaterial.ohNumber &&
              56100 / Number(updatedMaterial.ohNumber),
          ),
        },
        noFunctionality: {
          set: updatedMaterial.noFunctionality,
        },
        type: {
          update: {
            value: {
              set: updatedMaterial.type,
            },
          },
        },
        comments: {
          set: updatedMaterial.comments,
        },
      },
    };

    let isError = false;

    if (isOnlineAccount) {
      await updateCustomMaterialDB({ variables: updateData })
        .then(() => {
          dispatch(updateCustomMaterial(updatedMaterial));
        })
        .catch(() => {
          isError = true;
        });
    } else {
      dispatch(updateCustomMaterial(updatedMaterial));
    }

    ReactGA.event({
      category: 'User',
      action: 'Update a Resin',
    });

    if (!isError) onUpdate();
  };

  const addSelectOption = () => {
    dispatch(
      addMaterialType({
        userId: selectedUser?.id,
        value: customOption,
      }),
    );
    setCustomOption('');
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          title: updateMaterial?.title || '',
          ohNumber: updateMaterial?.ohNumber || null,
          noFunctionality: updateMaterial?.noFunctionality || null,
          type: updateMaterial?.type || '',
          comments: updateMaterial?.comments || '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          isUpdateState
            ? handleUpdateMaterial(values)
            : handleCreateMaterial(values)
        }
      >
        {({
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          values,
        }) => (
          <>
            {resinCreateFields.map(
              (item: {
                id: string;
                label: string;
                type: string;
                placeholder?: boolean;
                required: boolean;
                tooltip: boolean;
                text?: string;
              }) => {
                return (
                  <Formfields key={item.id}>
                    {item.id === 'ohNumber' ? (
                      <Row>
                        <Col xs={24} sm={24} md={12}>
                          <FormInputFields
                            item={item}
                            selectOptions={options}
                            tooltip={item.tooltip}
                            text={item?.text}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                            placeholder={item.placeholder}
                            maxLength={50}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <EquivalentWeightField
                            tooltip='56100/OH #'
                            result={
                              (values as any)[item.id]
                                ? 56100 / (values as any)[item.id]
                                : null
                            }
                          />
                        </Col>
                      </Row>
                    ) : (
                      <FormInputFields
                        item={item}
                        selectOptions={options}
                        tooltip={item.tooltip}
                        text={item?.text}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                        placeholder={item.placeholder}
                        maxLength={50}
                        setCustomOption={setCustomOption}
                        customOption={customOption}
                        addSelectOption={addSelectOption}
                      />
                    )}
                  </Formfields>
                );
              },
            )}
            <Buttons>
              <InternetConnectionStatus>
                {!isInternetConnection && 'No internet connection.'}
              </InternetConnectionStatus>
              <QueryResult
                error={isQueryError}
                errorMessage='Cannot connect to database. Try again later.'
              />
              <ButtonStyled
                data-cy='submit-button'
                type='ghost'
                size='large'
                onClick={() => navigate('/materials')}
              >
                Cancel
              </ButtonStyled>
              <ButtonStyled
                disabled={isOnlineAccount && !isInternetConnection}
                data-cy='submit-button'
                type='primary'
                size='large'
                onClick={() => !isLoading && handleSubmit()}
              >
                {isLoading ? (
                  <LoadingIndicator container />
                ) : isUpdateState ? (
                  'Update'
                ) : (
                  'Add'
                )}
              </ButtonStyled>
            </Buttons>
          </>
        )}
      </Formik>
    </div>
  );
};
