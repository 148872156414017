import styled from 'styled-components';
import { Layout, Menu } from 'antd';

export const Nav = styled(Layout.Header)`
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  height: ${({ theme }) => theme.nav.height};
  box-shadow: inset 0px -0.5px 0px rgba(166, 174, 191, 0.5);

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-color: ${(props) =>
      props.theme.texts.colors.brand.primaryGreen[60]} !important;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: ${(props) => props.theme.texts.colors.brand.primaryGreen[100]};
  }
  .ant-menu-horizontal > .ant-menu-item a:hover {
    color: black;
  }
  .ant-menu-item a {
    opacity: 0.5;
    color: black !important;
    font-weight: 600;
    &.active {
      opacity: 1;
    }
  }
`;

export const MenuMobile = styled(Menu)`
  display: none;
  @media (min-width: 640px) {
    display: flex;
    min-width: 270px;
  }
`;

export const NavLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  line-height: 0px;
  float: left;
  max-width: 100px;
  img {
    width: 100%;
    margin-bottom: 10px;
  }

  figcaption {
    font-weight: ${(props) => props.theme.texts.bold};
    color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
    font-size: ${(props) => props.theme.texts.largeText.size};
  }
`;

export const NavUser = styled.div`
  display: flex;
  gap: 15px;
  cursor: pointer;
  .ant-avatar {
    margin-right: 10px;
  }
  div > span.userFullName {
    display: none;
    @media (min-width: 640px) {
      display: inline-block;
    }
  }
`;
