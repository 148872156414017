// @ts-nocheck
import { AnyAction } from 'redux';
import { client } from '../../app/App';
import {
  AppStateActions,
  UserActions,
  FormulationActions,
  MaterialActions,
} from '../../constants/enums';
import {
  AppStateInterface,
  MaterialInterface,
  TypeInterface,
} from './interfaces';

const initialState: AppStateInterface = {
  users: [],
  selectedUser: undefined,
  materials: [],
  isAuthenticated: false,
  isFetching: true,
  types: [],
  formulations: [],
  userMaterialTypes: [],
  isOnline: true,
  userToken: undefined,
};
export const appStateReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: AnyAction,
): AppStateInterface => {
  switch (action.type) {
    case AppStateActions.ADD_ID:
      return {
        ...state,
        users: [
          ...state.users,
          { ...action.payload.user, id: action.payload.id },
        ],
      };
    case AppStateActions.ADD_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload.token,
      };
    case AppStateActions.GET_MATERIAL_TYPES:
      return {
        ...state,
        userMaterialTypes: state.types.filter(
          (type) => type.userId === state.selectedUser?.id,
        ),
      };
    case AppStateActions.ADD_MATERIAL_TYPE:
      return {
        ...state,
        types: [
          ...state.types,
          { ...action.payload.type, id: action.payload.id },
        ],
      };
    case AppStateActions.ADD_CUSTOM_MATERIAL: {
      const userIndex = state.users.findIndex(
        (x) => x.id === state.selectedUser?.id,
      );
      const updatedUser = state.users[userIndex];
      updatedUser.materials.push(action.payload.material);

      return {
        ...state,
        users: [
          ...state.users.map((obj) =>
            obj.id === updatedUser.id ? updatedUser : obj,
          ),
        ],
      };
    }
    case AppStateActions.FETCH_BASF_MATERIALS:
      return {
        ...state,
        materials: action.payload.materials,
      };
    case AppStateActions.GET_USER:
      return {
        ...state,
        selectedUser: state.users.find(
          (user) => user.id === action.payload.user,
        ),
      };
    case AppStateActions.LOGOUT_USER:
      client.resetStore();
      state.selectedUser = undefined;
      state.isAuthenticated = false;
      return state;
    case AppStateActions.IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isFetching: false,
      };
    case AppStateActions.GET_MATERIALS:
      return state;
    case AppStateActions.IS_FETCHING:
      state.isFetching = false;
      return state;
    case AppStateActions.IS_ONLINE:
      return { ...state, isOnline: action.payload.connection };
    case UserActions.SAVE_EDIT:
      return {
        ...state,
        users: state.users.map((x) => {
          if (x.id === state.selectedUser?.id) {
            Object.assign(x, action.payload.userData);
          }
          return x;
        }),
        selectedUser: Object.assign(
          state.selectedUser,
          action.payload.userData,
        ),
      };
    case UserActions.UPDATE_USER_DATA: {
      return {
        ...state,
        users: [...state.users],
        selectedUser: {
          ...state.users.find((user) => user.id === state.selectedUser?.id),
        },
      };
    }
    case UserActions.UPDATE_DB_USER: {
      const userIndex = state.users.findIndex(
        (user) => user.id === action.payload.userData.id,
      );
      const updatedUser = { ...state.users[userIndex] };
      updatedUser.formulations = action.payload.userData.formulations.map(
        (formulation: any) => formulation.id,
      );
      updatedUser.materials = action.payload.userData.materials.map(
        (material: MaterialInterface) => {
          return {
            ...material,
            owner: 'Personal',
            type: (material.type as TypeInterface).value,
          };
        },
      );

      return {
        ...state,
        users: [
          ...state.users.map((user) => {
            if (user.id === action.payload.userData.id) {
              Object.assign(user, updatedUser);
            }
            return user;
          }),
        ],
      };
    }
    case FormulationActions.ADD_FORMULATION: {
      const userIndex = state.users.findIndex(
        (x) => x.id === state.selectedUser?.id,
      );
      const updatedUser = state.users[userIndex];
      updatedUser.formulations.push(action.payload.formulation.key);

      return {
        ...state,
        users: [
          ...state.users.map((obj) =>
            obj.id === updatedUser.id ? updatedUser : obj,
          ),
        ],
        formulations: [
          ...[...state.formulations, { ...action.payload.formulation }].filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.key === value.key),
          ),
        ],
      };
    }
    case FormulationActions.FETCH_BASF_FORMULATIONS:
      return {
        ...state,
        formulations: action.payload.formulations,
      };
    case FormulationActions.REMOVE_FORMULATION:
      return {
        ...state,
        formulations: state.formulations.filter(
          (formulation) => formulation.key !== action.payload.formulationId,
        ),
      };
    case FormulationActions.DUPLICATE_FORMULATION:
      return {
        ...state,
        formulations: state.formulations.filter(
          (formulation) => formulation.key !== action.payload.formulationId,
        ),
      };
    case FormulationActions.UPDATE_FORMULATION: {
      return {
        ...state,
        formulations: [
          ...state.formulations.map((obj) =>
            obj.key === action.payload.formulation.key
              ? action.payload.formulation
              : obj,
          ),
        ],
      };
    }
    case FormulationActions.ADD_FORMULATIONS: {
      return {
        ...state,
        formulations: [
          ...[...state.formulations, ...action.payload.formulations].filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.key === value.key),
          ),
        ],
      };
    }
    case MaterialActions.REMOVE_MATERIAL: {
      const userIndex = state.users.findIndex(
        (x) => x.id === state.selectedUser?.id,
      );
      const updatedUser = state.users[userIndex];
      updatedUser.materials = [
        ...updatedUser.materials.filter((x) => x.key !== action.payload.key),
      ];

      return {
        ...state,
        materials: state.materials.filter((x) => x.key !== action.payload.key),
        users: [
          ...state.users.map((obj) =>
            obj.id === updatedUser.id ? updatedUser : obj,
          ),
        ],
      };
    }
    case MaterialActions.UPDATE_CUSTOM_MATERIAL: {
      const updatedMaterial = action.payload.updateMaterial;
      const userIndex = state.users.findIndex(
        (x) => x.id === state.selectedUser?.id,
      );

      const updatedUser = state.users[userIndex];
      updatedUser.materials = [
        ...updatedUser.materials.map((material) =>
          material.key === updatedMaterial.key ? updatedMaterial : material,
        ),
      ];

      return {
        ...state,
        users: [
          ...state.users.map((obj) =>
            obj.id === updatedUser.id ? updatedUser : obj,
          ),
        ],
      };
    }
    default:
      return state;
  }
};
