import React from 'react';
import { ButtonStyled } from './Button.styles';
import { ButtonProps } from './Button.interface';

export const Button = ({
  dataCy,
  className,
  children,
  loading = false,
  disabled,
  size,
  type,
  icon,
  outlined,
  onClick,
  ...props
}: ButtonProps): React.ReactElement => (
    <ButtonStyled
      data-cy={dataCy}
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      size={size}
      loading={loading}
      icon={icon}
      outlined={outlined}
      {...props}
    >
      {children}
    </ButtonStyled>
  );

