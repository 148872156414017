import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import localforage from 'localforage';
import { RegistrationPageCover, LoadingIndicator } from '../../components';
import { addBasfMaterials, getUser } from '../../redux/actions/app';
import { addFormulations } from '../../redux/actions/formulation';
import { AccountOption } from './components/AccountOptions';
import { AccountSetupForm } from './components/AccountSetupForm';
import { KeywordEntry } from './components/KeywordEntry';
import { WelcomeMessage } from './components/WelcomeMessage';
import { getBasfMaterials } from '../../helpers/getBasfMaterials';
import { getSelectedUser } from '../../redux/selectors/user';
import { AccountLogin } from './components/AccountLogin';
import { ResetPassword } from './components/ResetPassword';
import { useLocation } from 'react-router-dom';
import { LoginProps } from './LoginPage.interface';

const Container = styled.div`
  background: white;
  margin-top: ${({ theme }) => `-${theme.nav.height}`};
`;

export const LoginPage = ({ resetPassword = false }: LoginProps) => {
  const users = useSelector((state: { users: [] }) => state.users);
  const [storageEmptyStatus, setStorageEmptyStatus] = useState(false);
  const selectedUser = useSelector(getSelectedUser);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [accountSetup, setAccountSetup] = useState(false);
  const [onlineAccount, setOnlineAccount] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(false);
  const [isNewPasswordUrl, setIsNewPasswordUrl] = useState(false);
  const [token, setToken] = useState<string | undefined>();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const dispatch = useDispatch();
  const handleSelectAccount = useCallback(
    (userId) => {
      setSelectedAccount(!selectedAccount);
      dispatch(getUser(userId));
    },
    [selectedAccount, users, selectedUser],
  );
  const { state } = useLocation();

  const userList = async () => {
    const value = await localforage.getItem('reduxPersist:users');
    const basfMaterials = await localforage.getItem('reduxPersist:materials');
    const formulationsStorage = await localforage.getItem(
      'reduxPersist:formulations',
    );

    if (
      JSON.parse(value as string) &&
      JSON.parse(value as string).length !== 0
    ) {
      setStorageEmptyStatus(false);
    }
    if (
      JSON.parse(value as string)?.length === 0 ||
      !JSON.parse(value as string)
    ) {
      setWelcomeMessage(true);
      setAccountSetup(true);
      setStorageEmptyStatus(true);
    }
    if (JSON.parse(basfMaterials as string)?.length === 0 || !basfMaterials) {
      const materials = await getBasfMaterials();
      dispatch(addBasfMaterials(materials));
    }
  };

  useEffect(() => {
    if (state) {
      setIsNewPasswordUrl(false);
      setToken(undefined);
      setIsPasswordChanged(true);

      setTimeout(() => {
        setIsPasswordChanged(false);
      }, 5000);
    }
  }, [state]);

  useEffect(() => {
    setIsPasswordChanged(false);
    userList();
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const urlArray = url.split('/');

    if (resetPassword) {
      setIsNewPasswordUrl(true);
      setToken(urlArray.pop());
    }
  }, [resetPassword]);

  return (
    <Container>
      <Row>
        <Col xs={24} sm={24} md={10} style={{ position: 'relative' }}>
          <RegistrationPageCover />
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 15,
              fontWeight: 700,
              color: 'white',
              background: 'rgba(0, 0, 0, 0.5)',
              padding: '10px 15px',
            }}
          >
            <a className='optanon-toggle-display' style={{ color: 'white' }}>
              Cookies Preference
            </a>
            <a
              style={{ color: 'white', marginLeft: 15 }}
              href='https://www.basf.com/us/en/legal/data-protection.html'
              target='_blank'
            >
              Privacy Policy
            </a>
          </div>
        </Col>

        {isNewPasswordUrl ? (
          <Col xs={24} sm={24} md={14}>
            <ResetPassword token={token} />
          </Col>
        ) : (
          <Col xs={24} sm={24} md={14}>
            {!storageEmptyStatus && users.length === 0 && <LoadingIndicator />}
            {storageEmptyStatus && accountSetup && welcomeMessage && (
              <WelcomeMessage
                setOnlineAccount={setOnlineAccount}
                setWelcomeMessage={setWelcomeMessage}
                setNewAccount={setAccountSetup}
              />
            )}
            {accountSetup && !welcomeMessage && !onlineAccount && (
              <AccountSetupForm setNewAccount={setAccountSetup} />
            )}
            {!selectedAccount && !accountSetup && !onlineAccount && (
              <AccountOption
                selectAccount={handleSelectAccount}
                users={users}
                setAccountSetup={setAccountSetup}
                setOnlineAccount={setOnlineAccount}
                passwordChanged={isPasswordChanged}
              />
            )}
            {onlineAccount && (
              <AccountLogin
                setOnlineAccount={setOnlineAccount}
                setAccountSetup={setAccountSetup}
              />
            )}
            {selectedUser && selectedAccount && !accountSetup && (
              <KeywordEntry
                user={selectedUser}
                selectAccount={handleSelectAccount}
              />
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};
