import { Formik, Form, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import bcrypt from 'bcryptjs';
import { message, Modal } from 'antd';
import { Button } from '../../Button/Button';
import { ImportUserModalProps } from './ImportUserModal.interface';
import { FormInputFields } from '../../FormInputFields/FormInputFields';

export function ImportUserModal<
  T extends { key: string; title?: string; name?: string }
>({
  data = undefined,
  handleImport,
  validationSchema,
  ...modalProps
}: ImportUserModalProps<T>) {
  const formRef = useRef<FormikProps<{ password: string }>>(null);
  const [isError, setIsError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const handleSubmitPassword = () => {
    if (!data?.profile) {
      message.error('There is no profile on this file.');
      return;
    }
    
    handleImport();
    if (formRef.current) 
    formRef.current.resetForm();
    formRef.current?.setErrors({});
  };

  useEffect(() => {
    setIsError(true);
    setIsSubmitted(false);
  }, [isSubmitted]);

  const handleSubmit = () => {
    setIsSubmitted(true);

    if (formRef.current) {
      formRef?.current?.handleSubmit();
    }
  };

  const selectItem = () => {
    const item = {
      id: 'password',
      label: 'Password',
      type: 'password',
      required: true,
    };
    return item;
  };

  return (
    <Modal
      {...modalProps}
      centered
      visible={Boolean(data)}
      title='Import Profile'
      footer={[
        <Button
          key='import-modal-cancel-action'
          dataCy='import-modal-cancel-action'
          type='ghost'
          onClick={modalProps.onCancel}
          size='large'
        >
          Cancel
        </Button>,
        <Button
          key='import-modal-import-action'
          dataCy='import-modal-import-action'
          type='primary'
          disabled={false}
          onClick={() => handleSubmit()}
          size='large'
        >
          Import
        </Button>,
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ password: '' }}
        validate={(values) => {
          if (isError) setIsError(false);
          
          const matchesUserPassword = bcrypt.compareSync(
            values.password,
            data?.profile.password as string,
            );
            
          if (!matchesUserPassword) {
            return { password: '* Invalid password.' };
          }
          return {};
        }}
        
        onSubmit={() => handleSubmitPassword()}
      >
        {({ handleChange, setFieldValue, errors, touched, values }) => (
          <Form>
            <p>
              You are importing {data?.profile.fullName}&apos;s profile. To
              continue, please, enter the password for the account.
            </p>
            <FormInputFields
              item={selectItem()}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              errors={isError && errors}
              touched={touched}
              placeholder={false}
              values={values}
              autoFocus
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
