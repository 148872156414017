import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Layout, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as Yup from 'yup';
import {
  Container,
  CopyOutlinedIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
} from './FormulationPage.styles';
import {
  Button,
  TableFilters,
  useDataSource,
  GenericTable,
  ImportModal,
  DuplicateFormulationModal,
} from '../../components';
import { getFormulations } from '../../redux/selectors/formulations';
import { FormulationInterface } from '../../redux/reducers/interfaces';
import { formulationsFilters } from './FormulationPage.constants';
import { withConfirmation } from '../../redux/actions/ui';
import {
  removeFormulation,
  addSingleFormulation,
} from '../../redux/actions/formulation';
import { downloadJSON, uploadFile } from '../../helpers/hooks';
import { SpaceButtons } from '../MaterialsPage/MaterialsPage.styles';
import {
  Filter,
  FiltersMap,
} from '../../components/TableFilters/TableFilters.interface';
import { isEmpty } from 'lodash';

export const FormulationPage = () => {
  const [filters, setFilters] = useState<FiltersMap>({});
  const navigate = useNavigate();
  const { Content } = Layout;
  const dispatch = useDispatch();
  const formulations = useSelector(getFormulations);
  const { dataSource, applyFiltersToTable, updateDataSource } =
    useDataSource(formulations);
  const [formulationDuplicate, setFormulationDuplicate] =
    useState<FormulationInterface>();

  const [selectedFormulations, setSelectedFormulations] = useState<
    FormulationInterface[]
  >([]);

  const [uploadedFormulations, setUploadedFormulations] = useState<
    FormulationInterface[]
  >([]);

  const selectedFormulationKeys = useMemo(
    () => selectedFormulations.map((row) => row.key),
    [selectedFormulations],
  );

  const exportEnabled = selectedFormulations.length > 0;

  const exportSelectedFormulations = useCallback(
    () => downloadJSON(selectedFormulations, `formulations`),
    [selectedFormulations],
  );

  const handleFiltersChange = (
    value: string | number | undefined,
    filterName: string,
  ) => {
    let stringValue: string | undefined;

    if (typeof value === 'number') {
      stringValue = String(value);
    } else {
      stringValue = value;
    }

    setFilters((currentFilters) => ({
      ...currentFilters,
      [filterName]: stringValue,
    }));
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    // @ts-ignore
    filter: Filter<T>,
  ) => {
    const { value } = e.target;
    handleFiltersChange(value, filter.key);
  };

  const handleFiltersClear = () => {
    setFilters({});
  };

  const handleFormulationEdit = (formulationId: string) =>
    navigate(`/formulation/calculator/${formulationId}`);

  const handleFormulationRemove = (formulationId: string) =>
    dispatch(
      withConfirmation(removeFormulation(formulationId), {
        icon: null,
        title: 'Remove',
        content: 'Are you sure that you want to remove this object?',
        okText: 'Remove',
        autoFocusButton: null,
        okButtonProps: {
          danger: true,
        },
      }),
    );

  const columns: ColumnsType<FormulationInterface> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name: string, formulation: FormulationInterface) => (
        <Link to={`/formulation/calculator/${formulation.key}`}>{name}</Link>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
    },
    {
      title: 'Hydroxyl No.',
      dataIndex: 'hydroxilNo',
      render: (text: string) => Number(text).toFixed(2),
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      render: (text: string) => Number(text).toFixed(2),
    },
    {
      title: 'NCO %',
      dataIndex: 'nco',
      render: (text: string) => Number(text).toFixed(2),
    },
    {
      title: 'Index',
      dataIndex: 'index',
    },
    {
      title: 'Last Edit',
      dataIndex: 'lastEdit',
      render: (lastEdit: number) => {
        const date = new Date(lastEdit);

        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedYear = String(year);

        return `${formattedMonth}/${formattedDay}/${formattedYear}`;
      },
    },
    {
      title: '',
      dataIndex: 'key',
      render: (key: string, formulation: FormulationInterface) => (
        <div key={key}>
          <EditOutlinedIcon
            title='Edit'
            onClick={() => handleFormulationEdit(key)}
          />
          <CopyOutlinedIcon
            title='Duplicate'
            onClick={() => setFormulationDuplicate(formulation)}
          />
          <DeleteOutlinedIcon
            title='Remove'
            onClick={() => handleFormulationRemove(key)}
          />
        </div>
      ),
    },
  ];

  const handleUpload = () => {
    uploadFile(setUploadedFormulations);
  };
  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .required('reject')
          .notOneOf(
            formulations.map((formulation: FormulationInterface) =>
              formulation.name.trim(),
            ),
            'duplicated_name',
          ),
        key: Yup.string()
          .required('reject')
          .notOneOf(
            formulations.map(
              (formulation: FormulationInterface) => formulation.key,
            ),
            'duplicated_key',
          ),
        owner: Yup.string().required('reject'),
        hydroxilNo: Yup.number().nullable().required('reject'),
        ratio: Yup.number().nullable().required('reject'),
        nco: Yup.number().nullable().required('reject'),
        index: Yup.number().nullable().required('reject'),
        lastEdit: Yup.string().nullable().required('reject'),
        isoBatchWeight: Yup.number().nullable().required('reject'),
        resBatchWeight: Yup.number().nullable().required('reject'),
        materials: Yup.object().required('reject'),
      }),
    [formulations],
  );

  useEffect(() => {
    if (formulations.length) {
      updateDataSource(formulations);
      if (!isEmpty(filters)) {
        applyFiltersToTable(filters, formulationsFilters(formulations));
      }
    }
  }, [formulations]);

  return (
    <>
      <Content>
        <Container>
          <Row className='formulationButtonRow'>
            <Col>
              <SpaceButtons>
                <Button
                  dataCy='formulation-page-export-action'
                  disabled={!exportEnabled}
                  type='text'
                  onClick={exportSelectedFormulations}
                >
                  Export
                  {exportEnabled && ` (${selectedFormulations.length})`}
                </Button>
                <Button
                  dataCy='formulation-page-import-action'
                  onClick={handleUpload}
                >
                  Import
                </Button>
                <Button
                  dataCy='formulation-page-new-formulation-action'
                  type='primary'
                  onClick={() => navigate('/formulation/calculator')}
                >
                  + New Formulation
                </Button>
              </SpaceButtons>
            </Col>
          </Row>

          <TableFilters
            filters={filters}
            changeFilters={handleFiltersChange}
            searchWithFilter={handleSearchChange}
            clearFilters={handleFiltersClear}
            filtersList={formulationsFilters(formulations)}
            applyFiltersToTable={applyFiltersToTable}
          />
          <Card>
            <GenericTable
              columns={columns}
              dataSource={dataSource}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedFormulationKeys,
                onChange: (_, selectedRows) =>
                  setSelectedFormulations(selectedRows),
                getCheckboxProps: (record) => ({
                  name: record.name,
                }),
              }}
            />
          </Card>
        </Container>
      </Content>

      <DuplicateFormulationModal
        formulation={formulationDuplicate}
        formulations={formulations}
        onOk={() => setFormulationDuplicate(undefined)}
        onCancel={() => setFormulationDuplicate(undefined)}
      />
      <ImportModal
        data={uploadedFormulations}
        validationSchema={validationSchema}
        handleImport={addSingleFormulation}
        onOk={() => setUploadedFormulations([])}
        onCancel={() => setUploadedFormulations([])}
      />
    </>
  );
};
