import axios from 'axios';
import { UserInterface } from '../redux/reducers/interfaces';

export const ENDPOINT =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000/api';

const basfClient = axios.create({
  baseURL: ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const addUserToDb = async (data: UserInterface) => {
  try {
    const result = await basfClient
      .post('/users/signup', {
        data: JSON.stringify(data),
      })
      .then((x) => x.data)
      .catch((e) => e.response.data);

    return result;
  } catch (error) {
    return { error };
  }
};

export const loginUser = async (email: string, password: string) => {
  try {
    const result = await basfClient
      .post('/users/signin', {
        email,
        password,
      })
      .then((x) => x.data)
      .catch((e) => e.response.data);

    return result;
  } catch (error) {
    return { error };
  }
};

export const getUser = async (id: string, token: string) => {
  try {
    const result = await basfClient
      .get(`/users/full/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((x) => x.data)
      .catch((e) => e.response.data);

    return result;
  } catch (error) {
    return { error };
  }
};

export const sendResetLink = async (email: string) => {
  try {
    const result = await basfClient
      .post('/users/forgotPassword', { email })
      .then((x) => x.data)
      .catch((e) => e.response.data);

    return result;
  } catch (error) {
    return { error };
  }
};

export const resetPassword = async (
  newPassword: string,
  token?: string,
  id?: string,
) => {
  try {
    const result = await basfClient
      .post(`/users/resetPassword/${token}`, { newPassword, id })
      .then((x) => x.data)
      .catch((e) => e.response.data);

    return result;
  } catch (error) {
    return { error };
  }
};
