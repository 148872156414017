import { RootStateOrAny } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { store } from '../../config/store';
import { AppStateActions, MaterialActions } from '../../constants/enums';
import {
  MaterialInterface,
  TypeInterface,
  UserInterface,
} from '../reducers/interfaces';
import { withConfirmation } from './ui';
import { addToken, updateDbUser, updateSelectedUser } from './user';

export const getUser = (userId: string) => ({
  type: AppStateActions.GET_USER,
  payload: { user: userId },
});

export const isDataFetching = () => ({
  type: AppStateActions.IS_FETCHING,
});

export const addUser =
  (
    userData: UserInterface,
    token?: string,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const id = uuid();
    if (token) {
      dispatch({
        type: AppStateActions.ADD_ID,
        payload: {
          id: userData.id,
          user: {
            ...userData,
            onlineAccount: 'true',
            materials: [],
            formulations: [],
          },
        },
      });
      dispatch(getUser(userData.id!));
      dispatch(addToken(token));
    } else {
      dispatch({
        type: AppStateActions.ADD_ID,
        payload: {
          id,
          user: userData,
        },
      });
      dispatch(getUser(id));
    }
  };

export const authenticateUser =
  (
    user: UserInterface,
    token: string,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    if (token && user) {
      const { users } = store.getState();

      if (!users.find((x: any) => x.id === user.id)) {
        dispatch({
          type: AppStateActions.ADD_ID,
          payload: {
            id: user.id,
            user: {
              ...user,
              formulations: user.formulations.map(
                (formulation: any) => formulation.id,
              ),
              materials: user.materials.map((material: MaterialInterface) => {
                return {
                  ...material,
                  owner: 'Personal',
                  type: (material.type as TypeInterface).value,
                };
              }),
              onlineAccount: 'true',
            },
          },
        });
      } else {
        dispatch(updateDbUser(user));
      }

      dispatch(getUser(user.id as string));
      dispatch(addToken(token));
    }
  };

export const logoutUser = () => ({
  type: AppStateActions.LOGOUT_USER,
});

export const isUserAuthenticated = (isAuthenticated: boolean) => ({
  type: AppStateActions.IS_AUTHENTICATED,
  payload: { isAuthenticated },
});

export const getMaterials = () => ({ type: AppStateActions.GET_MATERIALS });

export const getMaterialTypes = () => ({
  type: AppStateActions.GET_MATERIAL_TYPES,
});

export const addMaterialType =
  (type: {}): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const id = uuid();
    dispatch({
      type: AppStateActions.ADD_MATERIAL_TYPE,
      payload: {
        id,
        type,
      },
    });
    dispatch(getMaterialTypes());
  };

export const addCustomMaterial =
  (
    material: MaterialInterface,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: AppStateActions.ADD_CUSTOM_MATERIAL,
      payload: {
        material,
      },
    });

    dispatch(updateSelectedUser());
    dispatch(getMaterials());
  };

export const addBasfMaterials =
  (
    materials: MaterialInterface[],
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: AppStateActions.FETCH_BASF_MATERIALS,
      payload: {
        materials,
      },
    });
    dispatch(getMaterials());
  };

export const removeMaterialComplete =
  (
    key: string,
    isOnline: boolean,
    removeMaterial:
      | (() => Promise<any>)
      | React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    id?: string,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    if (isOnline) {
      await (removeMaterial as ({}) => Promise<any>)({
        variables: { data: { id: id } },
      })
        .then(() => {
          dispatch({ type: MaterialActions.REMOVE_MATERIAL, payload: { key } });
          dispatch(updateSelectedUser());
        })
        .catch(() => {});
    } else {
      setLoading(true);
      removeMaterial(false);
      dispatch({ type: MaterialActions.REMOVE_MATERIAL, payload: { key } });
      dispatch(updateSelectedUser());
      setLoading(false);
      removeMaterial(true);
    }
  };

export const removeMaterial =
  (
    materialKey: string,
    materialType: string,
    selectedUser: UserInterface,
    isOnline: boolean,
    handleRemoveMaterial:
      | (() => Promise<any>)
      | React.Dispatch<React.SetStateAction<boolean>>,
    handleLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const { formulations } = store.getState();
    const arr: string[] = [];
    const id: string | undefined = selectedUser.materials.find(
      (material) => material.key === materialKey,
    )?.id;

    const text = arr.length
      ? `You need to remove the desired material from the following formulations before remove it. Formulations with material: ${arr}`
      : `Are you sure you want to delete this material?`;

    if (materialType === 'Resins') {
      formulations.map((x: any) =>
        x.materials.resins.map(
          (j: any) => j.materialKey === materialKey && arr.push(x.name),
        ),
      );

      dispatch(
        withConfirmation(
          arr.length
            ? () => {}
            : removeMaterialComplete(
                materialKey,
                isOnline,
                handleRemoveMaterial,
                handleLoading,
                id,
              ),
          {
            icon: null,
            title: 'Remove Material (Resin)',
            content: text,
            okText: arr.length ? 'OK' : 'Remove',
            autoFocusButton: null,
            cancelButtonProps: {
              style: { display: arr.length ? 'none' : 'initial' },
            },
          },
        ),
      );
    } else {
      formulations.map((x: any) =>
        x.materials.isocyanates.map(
          (j: any) => j.key === materialKey && arr.push(x.name),
        ),
      );

      dispatch(
        withConfirmation(
          arr.length
            ? () => {}
            : removeMaterialComplete(
                materialKey,
                isOnline,
                handleRemoveMaterial,
                handleLoading,
                id,
              ),
          {
            icon: null,
            title: 'Remove Material (Isocyanate)',
            content: text,
            okText: arr.length ? 'OK' : 'Remove',
            autoFocusButton: null,
            cancelButtonProps: {
              style: { display: arr.length ? 'none' : 'initial' },
            },
          },
        ),
      );
    }
  };

export const updateCustomMaterial =
  (
    updateMaterial: MaterialInterface,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: MaterialActions.UPDATE_CUSTOM_MATERIAL,
      payload: {
        updateMaterial,
      },
    });

    dispatch(updateSelectedUser());
  };
