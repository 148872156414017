import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import randomColor from 'randomcolor';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import bcrypt from 'bcryptjs';
import * as Yup from 'yup';
import { registrationFields } from '../../../constants/registrationFields';
import { countryOptions } from '../../../helpers/countryOptions';
import { addUser, isUserAuthenticated } from '../../../redux/actions/app';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { CheckboxInputStyled } from '../../../components/Input/Input.styles';
import { AccountSetupProps, ValueProps } from './AccountSetupForm.interface';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import LeftArrow from '../../../resources/arrow-left.svg';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import { securityQuestions } from '../../../constants/securityQuestions';
import { FocusOnError } from '../../../components/FocusOnError/FocusOnError';
import { PHONE_NUMBER_REGEX } from '../../../constants/regex';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { ArrowIcon } from './KeywordEntry.styles';
import { ErrorMessage, UserHeading } from './AccountLogin.styles';
import PDFFile from './../../../resources/termsconditions.pdf';
import Modal from 'antd/lib/modal/Modal';
import { Document as DocPdf, Page } from 'react-pdf/dist/umd/entry.webpack';
import { addUserToDb } from '../../../api/api';
import { LoadingIndicator } from '../../../components';

const Container = styled.div`
  display: flex;
  padding: 5rem;
  justify-content: center;

  div {
    min-width: 50%;
  }
  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  justify-content: flex-end;
`;

const PrivacyTerms = styled.div`
  margin-top: 2rem;
`;

const SpanLink = styled.span`
  cursor: pointer;
  color: #1890ff;
`;

export const AccountSetupForm = ({
  setNewAccount,
}: AccountSetupProps): React.ReactElement => {
  const salt = bcrypt.genSaltSync(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirect, setRedirect] = useState(false);
  const [tcModalVisible, setTCModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) setIsSubmitting(false);
  }, [errorMessage]);

  const validationSchema = Yup.object({
    onlineAccount: Yup.string().required('* Required'),
    fullName: Yup.string().trim().required('* Required'),
    email: Yup.string()
      .trim()
      .email('Invalid email address')
      .required('* Required'),
    password: Yup.string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        'Must Contain at least 6 Characters with One Uppercase or more and numbers',
      ),
    address: Yup.string().max(500),
    securityQuestion: Yup.string().required('* Required'),
    securityAnswer: Yup.string().required('* Required'),
    phoneNumber: Yup.string().max(20).matches(PHONE_NUMBER_REGEX, {
      message: 'Invalid phone number.',
      excludeEmptyString: false,
    }),
  });

  const handleSetSelectOptions = (id: string) => {
    let options = [];
    if (id === 'securityQuestion') {
      options = securityQuestions;
    } else if (id === 'country') {
      options = countryOptions();
    } else {
      options = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ];
    }
    return options;
  };

  const handleSubmitUserDetails = async (values: ValueProps) => {
    setErrorMessage(undefined);
    setIsSubmitting(true);
    if (values.shareRegistrationData) {
      ReactGA.event({
        category: 'Registration',
        action: 'Share Profile Accepted',
        label: `Name: ${values.fullName}, Company: ${values.company}, Role: ${values.role}, Address: ${values.address}, Phone: ${values.phoneNumber}, Country: ${values.country}`,
      });
    }

    let password;

    const user = {
      onlineAccount: values.onlineAccount,
      fullName: values.fullName.trim(),
      email: values.email.trim(),
      password: bcrypt.hashSync(values.password, salt),
      securityQuestion: values.securityQuestion,
      securityAnswer: bcrypt.hashSync(values.securityAnswer.trim(), salt),
      company: values.company.trim(),
      role: values.role.trim(),
      phoneNumber: values.phoneNumber,
      country: values.country,
      address: values.address.trim(),
      shareRegistrationData: values.shareRegistrationData,
      color: randomColor(),
      formulations: [],
      materials: [],
    };

    if (!isAccountOnline(user)) {
      password = bcrypt.hashSync(values.password, salt);
      dispatch(addUser({ ...user, password }));
    } else {
      password = values.password;
      const {
        user: fetchedUser,
        token,
        message,
        error,
      } = await addUserToDb({ ...user, password });

      if (message || error) {
        return setErrorMessage(
          message ? message : 'No internet connection! Try again later.',
        );
      }

      dispatch(addUser(fetchedUser, token));
    }

    dispatch(isUserAuthenticated(true));
    setRedirect(true);
  };

  if (redirect) return <Navigate replace to='/main/tour' />;

  return (
    <Container>
      <div>
        <UserHeading
          data-cy='setup-account'
          onClick={() => setNewAccount(false)}
        >
          <ArrowIcon src={LeftArrow} alt='Go back' />
          <SectionHeading label='Setup Account' />
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </UserHeading>
        <Formik
          initialValues={{
            onlineAccount: '',
            fullName: '',
            email: '',
            password: '',
            securityQuestion: '',
            securityAnswer: '',
            company: '',
            role: '',
            phoneNumber: '',
            country: '',
            address: '',
            shareRegistrationData: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitUserDetails(values)}
        >
          {({
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            values,
          }) => (
            <Form>
              {registrationFields.map(
                (item: {
                  id: string;
                  label: string;
                  type: string;
                  placeholder?: boolean;
                  required: boolean;
                  tooltip: boolean;
                  text?: string;
                }) => {
                  return (
                    <FormFields key={item.id}>
                      <FormInputFields
                        item={item}
                        selectOptions={handleSetSelectOptions(item.id)}
                        tooltip={item.tooltip}
                        text={item?.text}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                        placeholder={item.placeholder}
                      />
                    </FormFields>
                  );
                },
              )}
              <CheckboxInputStyled
                data-cy='data-permission-checkbox'
                id='shareRegistrationData'
                onChange={(e) =>
                  setFieldValue('shareRegistrationData', e.target.checked)
                }
              >
                Share my registration data with BASF for marketing purposes.
              </CheckboxInputStyled>
              <PrivacyTerms>
                By clicking on Create account, you agree to BASF&apos;s{' '}
                <a
                  data-cy='privacy-link'
                  href='https://www.basf.com/us/en/legal/data-protection.html'
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <SpanLink onClick={() => setTCModalVisible(true)}>
                  Terms of Service
                </SpanLink>
                <Modal
                  title='Terms of Service'
                  centered
                  visible={tcModalVisible}
                  onOk={() => setTCModalVisible(false)}
                  onCancel={() => setTCModalVisible(false)}
                  width='auto'
                >
                  <DocPdf
                    file={PDFFile}
                    options={{ workerSrc: '../../../resources/pdf.worker.js' }}
                  >
                    <Page pageNumber={1} />
                    <Page pageNumber={2} />
                    <Page pageNumber={3} />
                  </DocPdf>
                </Modal>
                .
              </PrivacyTerms>
              <Buttons>
                <ButtonStyled
                  data-cy='submit-button'
                  type='primary'
                  size='large'
                  onClick={() => handleSubmit()}
                >
                  {isSubmitting ? (
                    <LoadingIndicator container />
                  ) : (
                    'Create account'
                  )}
                </ButtonStyled>
              </Buttons>
              <FocusOnError />
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
