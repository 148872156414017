import styled from 'styled-components';

export const Container = styled.div`
  padding: 15rem;
  div {
    min-width: 50%;
  }
  @media (max-width: 1200px) {
    padding: 15rem 7rem;
  }
  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`;

export const UserHeading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
`;

export const ArrowIcon = styled.img`
  cursor: pointer;
  margin-right: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  justify-content: ${(props: { content: string }) => props.content};
  align-items: center;
`;

export const SecurityQuestion = styled.div`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-size: ${(props) => props.theme.texts.subtitle.size};
  margin-bottom: 1rem;
`;

export const PasswordReset = styled.div`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-size: ${(props) => props.theme.texts.subtitle.size};
  margin-bottom: 1rem;
`;

export const ForgotPassword = styled.div`
  color: ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
  font-size: ${(props) => props.theme.texts.text.size};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.texts.colors.brand.primaryGreen[100]};
  }
`;
