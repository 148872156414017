import { Col, Row } from 'antd';
import styled from 'styled-components';
import React, { useState } from 'react';
import UserProfile from './resources/user-profile.jpeg';
import { UserDetailsForm } from './components/UserDetailsForm';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { TabMenu, Footer } from '../../components';

const Image = styled.div`
  @media (min-width: 990px) {
    position: fixed;
    height: 100vh;
    width: 40%;
  }
  position: relative;
  background-image: url(${UserProfile});
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 40vh;
  width: 100%;
`;

const FullHeightRow = styled(Row)`
  @media (min-width: 990px) {
    min-height: ${({ theme }) => `calc(100vh - ${theme.nav.height})`};
  }
`;

export const UserProfilePage = (): React.ReactElement => {
  const [selected, setSelected] = useState('profile');
  const tabs = [
    { key: 'profile', label: 'Profile Information' },
    { key: 'password-reset', label: 'Change Password' },
  ];
  const handleSelectTab = (e: { key: any }) => {
    setSelected(e.key);
  };

  const selectedTabView = () => {
    if (selected === 'profile') {
      return <UserDetailsForm />;
    }
    return <ChangePasswordForm />;
  };

  return (
    <>
      <FullHeightRow>
        <Col xs={24} sm={24} lg={10}>
          <Image />
        </Col>
        <Col xs={24} sm={24} lg={14}>
          <TabMenu
            selected={selected}
            handleClick={handleSelectTab}
            tabs={tabs}
          />
          {selectedTabView()}
          <Footer mediaSize='1230px' />
        </Col>
      </FullHeightRow>
    </>
  );
};
