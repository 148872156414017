import React from 'react';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootStateOrAny } from 'react-redux';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const showConfirmationDialog = (
  options?: ModalFuncProps,
): ThunkAction<Promise<boolean>, RootStateOrAny, unknown, AnyAction> => () =>
  new Promise((resolve) => {
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      content: 'Confirm action?',
      centered: true,
      okButtonProps: { type: 'primary', className: 'cy-modal-ok-action' },
      cancelButtonProps: {
        type: 'text',
        className: 'cy-modal-cancel-action',
      },
      ...options,
      onOk: () => {
        resolve(true);
        options?.onOk?.();
      },
      onCancel: () => {
        resolve(false);
        options?.onCancel?.();
      },
    });
  });

export const withConfirmation = <
  Action extends ThunkAction<any, any, any, any>
>(
  action: Action,
  options?: ModalFuncProps,
): ThunkAction<void, RootStateOrAny, unknown, AnyAction> => async (
  dispatch,
) => {
  const confirmed = await dispatch(showConfirmationDialog(options));

  if (confirmed) {
    dispatch(action);
  }
};
