import { generatePdf } from '../../../helpers/generatePdf';
import { UserInterface } from '../../../redux/reducers/interfaces';
import { IsocyanatesTable } from '../components/CalculatorIsocyanates.interface';
import { ResinsTable } from '../components/CalculatorResins.interface';
import BASFOttoKalc from '../../../resources/BASFOttoKalcPDF.png';

export const download = (
  title: string,
  resBatchWeight: number,
  resins: ResinsTable[],
  isoBatchWeight: number,
  isocyanates: IsocyanatesTable[],
  resinEq: number,
  isoEq: number,
  ratio: number,
  ohNumber: number,
  ncoNumber: number,
  index: number,
  loggedUser: UserInterface,
) => {
  const date = new Date();

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedMonth = String(month).padStart(2, '0');
  const formattedDay = String(day).padStart(2, '0');
  const formattedYear = String(year);

  const formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;

  const test: any = {
    content: [
      {
        alignment: 'justify',
        columns: [
          { text: title, style: 'header' },
          {
            image: `${BASFOttoKalc}`,
            width: 90,
            alignment: 'right',
          },
        ],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: -50,
            y1: 5,
            x2: 600,
            y2: 5,
            lineWidth: 0.2,
            lineColor: '#A3A3A3',
          },
        ],
        margin: [0, 20],
      },
      { text: 'Resin Blend', style: 'subheader', margin: [0, 10] },
      {
        style: 'tableExample',
        headerRows: 1,
        layout: 'lightHorizontalLines',
        table: {
          body: [
            [
              { text: 'Component', bold: true },
              { text: 'Parts', bold: true },
              { text: 'OH #', bold: true },
              { text: 'Eq. Wt', bold: true },
              { text: 'Functionality', bold: true },
              { text: 'OH Contribution', bold: true },
              { text: 'Batch Weights', bold: true },
            ],
            ...resins.map((x) => {
              const obj = [
                { text: x.title, margin: [0, 10] },
                { text: x.parts.toString(), margin: [0, 10] },
                { text: x.ohNumber, margin: [0, 10] },
                { text: Number(x.eqWeight).toFixed(2), margin: [0, 10] },
                { text: x.functionality, margin: [0, 10] },
                { text: x.ohContribution.toFixed(3), margin: [0, 10] },
                { text: x.batchWeights.toFixed(3), margin: [0, 10] },
              ];
              return obj;
            }),
          ],
        },
      },
      { text: 'RESIN BATCH WEIGHT', style: 'labelItalic', margin: [0, 10] },
      { text: resBatchWeight, style: 'labelItalic' },
      {
        canvas: [
          {
            type: 'line',
            x1: -50,
            y1: 5,
            x2: 600,
            y2: 5,
            lineWidth: 0.2,
            lineColor: '#A3A3A3',
          },
        ],
        margin: [0, 20],
      },
      { text: 'Isocyanates Blend', style: 'subheader', margin: [0, 10] },
      {
        layout: 'lightHorizontalLines',
        headerRows: 1,
        table: {
          body: [
            [
              { text: 'Component', bold: true },
              { text: 'Parts', bold: true },
              { text: 'NCO %', bold: true },
              { text: 'Eq. Wt', bold: true },
              { text: 'Functionality', bold: true },
              { text: 'NCO Contribution', bold: true },
              { text: 'Batch Weights', bold: true },
            ],
            ...isocyanates.map((x) => {
              const obj = [
                { text: x.title, margin: [0, 10] },
                { text: x.parts.toString(), margin: [0, 10] },
                { text: x.nco, margin: [0, 10] },
                { text: Number(x.eqWeight)?.toFixed(2), margin: [0, 10] },
                { text: x.noFunctionality, margin: [0, 10] },
                { text: x.ncoContribution.toFixed(3), margin: [0, 10] },
                { text: x.batchWeights.toFixed(3), margin: [0, 10] },
              ];
              return obj;
            }),
          ],
        },
      },
      { text: 'ISO BATCH WEIGHT', style: 'labelItalic', margin: [0, 10] },
      { text: isoBatchWeight, style: 'labelItalic' },
      {
        canvas: [
          {
            type: 'line',
            x1: -50,
            y1: 5,
            x2: 600,
            y2: 5,
            lineWidth: 0.2,
            lineColor: '#A3A3A3',
          },
        ],
        margin: [0, 20],
        pageBreak: 'after',
      },
      {
        alignment: 'justify',
        columns: [
          { text: title, style: 'header' },
          {
            image: `${BASFOttoKalc}`,
            width: 90,
            alignment: 'right',
          },
        ],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: -50,
            y1: 5,
            x2: 600,
            y2: 5,
            lineWidth: 0.2,
            lineColor: '#A3A3A3',
          },
        ],
        margin: [0, 20],
      },
      { text: 'Formulation Output', style: 'subheader', margin: [0, 10] },
      {
        columns: [
          {
            text: `Resin Blend Equivalent Weight\n${resinEq.toFixed(
              3,
            )}\n\nIsocyanate Blend Equivalent Weight\n${isoEq.toFixed(
              3,
            )}\n\nRatio (Parts of Iso per 100 parts resin)\n${ratio.toFixed(
              2,
            )}`,
          },
          {
            text: `Resin Blend Hydroxyl Number\n${ohNumber.toFixed(
              3,
            )}\n\nIsocyanate Blend NCO %\n${ncoNumber.toFixed(
              3,
            )}\n\nIndex\n${index}`,
          },
        ],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: -50,
            y1: 5,
            x2: 600,
            y2: 5,
            lineWidth: 0.2,
            lineColor: '#A3A3A3',
          },
        ],
        margin: [0, 20],
      },
      { text: 'Materials', style: 'subheader', margin: [0, 15] },
      {
        layout: 'lightHorizontalLines',
        headerRows: 1,
        table: {
          body: [
            [
              { text: 'Title', bold: true },
              { text: 'Type', bold: true },
              { text: 'Owner', bold: true },
            ],
            ...[...resins, ...isocyanates].map((x) => {
              const obj = [
                { text: x.title, listType: 'none', margin: [0, 10] },
                {
                  text: x.materialType,
                  listType: 'none',
                  margin: [0, 10],
                },
                {
                  text: `${
                    x.owner === 'BASF' || x.owner === 'basf@email.com'
                      ? 'BASF'
                      : x.owner
                  }`,
                  listType: 'none',
                  margin: [0, 10],
                },
              ];
              return obj;
            }),
          ],
        },
        pageBreak: 'after',
      },
      {
        alignment: 'justify',
        columns: [
          { text: title, style: 'header' },
          {
            image: `${BASFOttoKalc}`,
            width: 90,
            alignment: 'right',
          },
        ],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: -50,
            y1: 5,
            x2: 600,
            y2: 5,
            lineWidth: 0.2,
            lineColor: '#A3A3A3',
          },
        ],
        margin: [0, 20],
      },
      {
        columns: [
          [
            {
              text: 'Always read and follow label directions.',
              style: 'smallDisclaimerBold',
              margin: [0, 10],
            },
            {
              text: 'This formulation was generated by OttoKalc™ the use of which is subject to the user terms and conditions provide with the app.  By generating, printing, and/or using formulations using OttoKalc™  you are acknowledging you are the author of the formulation.  Further, you are solely responsible for determining whether any licenses or rights to any intellectual property rights of any other person or entity are needed to use any the formulation, any third-party products used in the formulation, any mixtures containing such products, and any methods for using such products; and you are solely responsible for obtaining any necessary licenses or rights to practice any such intellectual property rights.  Unless otherwise notes all logos and other trademarks used in connection with OttoKalc™ are trademarks used or registered by BASF. Other trademarks used in connection with OttoKalc™ may be the trademarks of other rights holders.  The user is solely responsible for the formulation generated by OttoKalc™, its performance, and all uses thereof.',
              margin: [0, 5],
              style: 'smallDisclaimer',
            },
            {
              text: 'EXCEPT AS EXPRESSLY STATED ON THE LABEL OF A BASF PRODUCT, THIS TOOL IS PROVIDED “AS IS” AND WITH ALL FAULTS, AND BASF HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION (I) WARRANTIES AS TO ANY PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES AND RECOMMENDED BY BASF, AND (II) IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, AND NON-INFRINGEMENT AND ALL OTHER LIABILITIES AND OBLIGATIONS. NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED TO YOU BY A REPRESENTATIVE OF BASF SHALL CREATE A REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED.  WITH REGARD TO ANY HANDLING OF ANY PRODUCT, YOU AS THE BUYER ASSUME FULL RESPONSIBILITY FOR QUALITY CONTROL, TESTING AND DETERMINATION OF SUITABILITY OF PRODUCT FOR ITS INTENDED APPLICATION OR USE.  UNDER NO CIRCUMSTANCES WILL BASF BE LIABLE OR RESPONSIBLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES FROM LOSS OF BUSINESS, LOST PROFITS, HARM TO REPUTATION OR GOODWILL, OR THE LIKE), SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL THEORY (INCLUDING TORT, WARRANTY, CONTRACT, PRODUCT LIABILITY OR OTHERWISE), THAT ARISE, FOR EXAMPLE, FROM YOUR RELIANCE ON MODIFIED RATES OR PRODUCTS OR YOUR USE OF THE INFORMATION REGARDING RATES OR PRODUCTS, EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
              style: 'smallDisclaimer',
            },
          ],
        ],
      },
    ],
    styles: {
      labelItalic: {
        fontSize: 10,
        italics: true,
      },
      label: {
        fontSize: 10,
      },
      header: {
        fontSize: 20,
      },
      subheader: {
        fontSize: 16,
        bold: true,
      },
      smallDisclaimer: {
        fontSize: 8,
        textAlign: 'justify',
      },
      smallDisclaimerBold: {
        fontSize: 8,
        bold: true,
      },
    },
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [40, 0],
        columns: [
          {
            text: `${loggedUser.fullName}\n${formattedDate}`,
          },
          {
            text: `BASF OttoKalc™\nPage ${currentPage.toString()} of ${pageCount}`,
            alignment: 'right',
            bold: true,
          },
        ],
      };
    },
  };

  generatePdf(test);
};
