import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import TooltipIcon from '../../../resources/help-circle.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 1rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
    margin-left: 0;
  }
`;

const Label = styled.div`
  font-size: ${(props) => props.theme.texts.labelTextMedium.size};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-weight: 500;
  text-transform: uppercase;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 0.5rem;
  }
`;

const WeightResult = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    bottom: 10%;
  }
`;

export const EquivalentWeightField = ({
  tooltip,
  result,
}: {
  tooltip: string;
  result: number | null;
}): React.ReactElement => {
  return (
    <Container>
      <Heading>
        <Label>Equivalent Weight</Label>
        <Tooltip title={tooltip}>
          <img className='tooltip' src={TooltipIcon} alt='tooltip' />
        </Tooltip>
      </Heading>
      <WeightResult>{result?.toFixed(2) || '-'}</WeightResult>
    </Container>
  );
};
