export const registrationFields = [
  {
    id: 'onlineAccount',
    label: 'Use Online version',
    type: 'select',
    required: true,
    tooltip: true,
    text:
      'This will define if your data will be stored on the cloud or locally on your browser.',
  },
  {
    id: 'fullName',
    label: 'Full Name',
    type: 'text',
    required: true,
    tooltip: false,
  },
  {
    id: 'email',
    label: 'Email Address',
    type: 'text',
    required: true,
    tooltip: false,
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
    required: true,
    tooltip: false,
    placeholder: true,
  },
  {
    id: 'securityQuestion',
    label: 'Security Question',
    type: 'select',
    required: true,
    tooltip: true,
    text: 'This question/answer will be used in case you forget your password',
  },
  {
    id: 'securityAnswer',
    label: 'Security Answer',
    type: 'password',
    required: true,
    tooltip: false,
    placeholder: false,
  },
  {
    id: 'company',
    label: 'Company',
    type: 'text',
    required: false,
    tooltip: false,
  },
  {
    id: 'role',
    label: 'Role',
    type: 'text',
    required: false,
    tooltip: false,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
    required: false,
    tooltip: false,
  },
  {
    id: 'country',
    label: 'Country',
    type: 'select',
    required: false,
    tooltip: false,
  },
  {
    id: 'address',
    label: 'Address',
    type: 'text',
    required: false,
    tooltip: false,
  },
];
