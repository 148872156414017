import { message, notification } from 'antd';
import { v4 as uuid } from 'uuid';
import { RootStateOrAny } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { FormulationActions } from '../../constants/enums';
import { FormulationInterface } from '../reducers/interfaces';
import { getMaterials } from './app';
import { refreshDbUser, updateSelectedUser } from './user';
import { store } from '../../config/store';
import { client } from '../../app/App';
import {
  CREATE_FORMULATION_FOR_USER,
  DELETE_FORMULATION,
} from '../../graphql/mutations';
import createFormulationPayload from '../../helpers/createFormulationPayload';
import { isAccountOnline } from '../../helpers/nameInitials';

export const removeFormulation =
  (
    formulationId: string,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const { selectedUser } = store.getState();
    if (selectedUser) {
      const isOnlineAccount = isAccountOnline(selectedUser);
      if (isOnlineAccount) {
        client
          .mutate({
            mutation: DELETE_FORMULATION,
            variables: {
              where: {
                id: formulationId,
              },
            },
          })
          .then((response) => {
            if (response.data) {
              dispatch({
                type: FormulationActions.REMOVE_FORMULATION,
                payload: { formulationId },
              });
              message.success({
                content: 'Formulation removed successfully.',
                style: {
                  marginTop: '90vh',
                },
              });
            }
          })
          .catch((err) => {
            notification.error({
              message: err.message,
              style: {
                marginTop: '80vh',
              },
              description: `Formulation was not deleted.`,
            });
          });
        return;
      }
      dispatch({
        type: FormulationActions.REMOVE_FORMULATION,
        payload: { formulationId },
      });
      message.success({
        content: 'Formulation removed successfully.',
        style: {
          marginTop: '90vh',
        },
      });
    }
  };

export const addSingleFormulation =
  (
    formulation: FormulationInterface,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const { selectedUser } = store.getState();
    dispatch({
      type: FormulationActions.ADD_FORMULATION,
      payload: {
        formulation: {
          ...formulation,
        },
      },
    });

    if (isAccountOnline(selectedUser)) {
      dispatch(refreshDbUser());
    }
    dispatch(updateSelectedUser());
    dispatch(getMaterials());
  };

export const updateFormulation = (formulation: FormulationInterface) => ({
  type: FormulationActions.UPDATE_FORMULATION,
  payload: {
    formulation,
  },
});

export const getSingleFormulation = (key: string) => ({
  type: FormulationActions.GET_FORMULATION,
  payload: { key },
});

export const addFormulations =
  (
    formulations: FormulationInterface[],
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: FormulationActions.FETCH_BASF_FORMULATIONS,
      payload: {
        formulations,
      },
    });
    dispatch(getMaterials());
  };

export const duplicateFormulation =
  (
    formulation: FormulationInterface,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const newFormulation = { ...formulation, key: uuid() };
    const state = store.getState();
    const payload = createFormulationPayload(newFormulation);
    const isOnline = isAccountOnline(state.selectedUser);
    if (isOnline) {
      client
        .mutate({
          mutation: CREATE_FORMULATION_FOR_USER,
          variables: payload,
        })
        .then((res) => {
          if (res.data) {
            dispatch(addSingleFormulation(newFormulation));
            message.success({
              content: 'Formulation duplicated successfully.',
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: err.message,
            style: {
              marginTop: '80vh',
            },
            description: `Formulation was not duplicated successfully.`,
          });
        });
      return;
    }
    dispatch(addSingleFormulation(newFormulation));
    message.success({
      content: 'Formulation duplicated successfully.',
    });
  };

export const updateFormulationsInDB =
  (
    formulations: FormulationInterface[],
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const { formulations: oldFormulations } = store.getState();
    formulations = formulations.filter((formulation) => {
      return !oldFormulations.some((oldFor) => oldFor.key === formulation.key);
    });
    dispatch({
      type: FormulationActions.ADD_FORMULATIONS,
      payload: {
        formulations,
      },
    });
  };
