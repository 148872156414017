import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  padding: 25px 50px;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.3);
  .arrow-output {
    transition: 300ms ease-in-out;
    &.rotate {
      transform: rotate(180deg);
    }
  }

  .ant-row {
    &.hide {
      transition: height 500ms ease-in-out;
      opacity: 0;
      height: 0;
    }
    &.show {
      opacity: 1;
      height: auto;
    }
  }
`;

export const IndexContainer = styled.div`
  max-width: 400px;
  width: 100%;
`;
