import { generateFilterOptions } from '../../components/TableFilters/TableFilters.helpers';
import { FormulationInterface } from '../../redux/reducers/interfaces';
import {
  Filter,
  FilterType,
} from '../../components/TableFilters/TableFilters.interface';
import { ResinsTable } from '../CalculatorsPage/components/CalculatorResins.interface';
import { IsocyanatesTable } from '../CalculatorsPage/components/CalculatorIsocyanates.interface';

export const formulationsFilters = (
  formulations: FormulationInterface[],
): Filter<FormulationInterface>[] => [
  {
    key: 'name',
    columns: [{ field: 'name' }],
    type: FilterType.SEARCH,
    placeholder: 'Search by name',
  },
  {
    key: 'owner',
    columns: [{ field: 'owner' }],
    type: FilterType.SELECT,
    options: generateFilterOptions('owner', formulations),
    placeholder: 'Owner',
  },
  {
    key: 'materials',
    columns: [
      {
        field: 'materials',
        comparator: (
          materials: { isocyanates: IsocyanatesTable[]; resins: ResinsTable[] },
          v2,
        ) => {
          const material =
            materials.isocyanates.find((m) =>
              m.title.toLowerCase().includes(v2),
            ) ||
            materials.resins.find((m) => m.title.toLowerCase().includes(v2));
          return Boolean(material);
        },
      },
    ],
    type: FilterType.SELECT,
    options: generateFilterOptions('title', [
      ...([] as IsocyanatesTable[]).concat(
        ...formulations.map((formulation) => formulation.materials.isocyanates),
      ),
      ...([] as ResinsTable[]).concat(
        ...formulations.map((formulation) => formulation.materials.resins),
      ),
    ]),
    placeholder: 'Material used',
  },
  {
    key: 'date',
    columns: [{ field: 'lastEdit' }],
    type: FilterType.DATE,
    placeholder: 'Date',
  },
];
