import React from 'react';
import styled, { css } from 'styled-components';

interface SectionHeadingProps {
  label: string;
  reset?: boolean;
  children?: any;
}

const Heading = styled.h1`
  margin: 0;
  font-size: ${(props) => props.theme.texts.layoutTitle.size};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-weight: ${(props) => props.theme.texts.bold};
`;

const Container = styled.div<{reset?: boolean}>`
  position: relative;
  ${props => props.reset && css`
    padding-bottom: 2rem; 
  `}
`;

export const SectionHeading: React.FC<SectionHeadingProps> = ({
  label,
  reset,
  children
}) => {
  return (
    <Container reset={reset}>
      <Heading data-cy='heading'>{label}</Heading>
      {children}
    </Container>
  );
};
