import { Avatar } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import {
  getNameInitials,
  isAccountOnline,
} from '../../../helpers/nameInitials';
import { UserInterface } from '../../../redux/reducers/interfaces';
import RightArrow from '../../../resources/arrow-right.svg';
import PlusIcon from '../../../resources/plus.svg';
import CloudIcon from '../../../resources/cloud-icon.svg';
import { AccountProps } from '../LoginPage.interface';
import { PasswordChanged } from './AccountLogin.styles';

const Container = styled.div`
  padding: 15rem;
  div {
    min-width: 50%;
  }
  @media (max-width: 1200px) {
    padding: 15rem 7rem;
  }
  @media (max-width: 767px) {
    padding: 1.5rem;
  }
`;

const AvatarName = styled.span`
  margin-left: 1rem;
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-size: 24px;
`;

const AvatarInitials = styled.h2`
  opacity: 0.6;
`;

const CloudIconContainer = styled.img`
  width: 15px;
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0.3;
`;
const UserOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 2rem;
  position: relative;
  :hover {
    background-color: ${(props) => props.theme.texts.colors.brand.neutral[10]};
    background-image: url(${RightArrow});
    background-repeat: no-repeat;
    background-position: 95% 50%;
  }
`;

const AddUserAccount = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  color: ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
  font-weight: ${(props) => props.theme.texts.bold};
  font-size: ${(props) => props.theme.texts.text.size};
  img {
    margin-right: 0.5rem;
  }
  :hover {
    color: ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
  }
`;

export const AccountOption = ({
  selectAccount,
  users,
  setAccountSetup,
  setOnlineAccount,
  passwordChanged
}: AccountProps): React.ReactElement => {
  return (
    <Container data-cy='account-options'>
      <SectionHeading label='Choose User Profile' />
      {passwordChanged && 
        <PasswordChanged>
          Password successfuly changed! Please log in with new password.
        </PasswordChanged>
      }     
      {users.map((user: UserInterface) => {
        return (
          <UserOption
            key={user.id}
            onClick={() => selectAccount(user.id as string)}
          >
            <Avatar
              data-cy='user-avatar'
              style={{ verticalAlign: 'middle', backgroundColor: user.color }}
              size={64}
              shape='square'
            >
              <AvatarInitials>
                {user?.fullName && getNameInitials(user?.fullName)}
              </AvatarInitials>
            </Avatar>
            <AvatarName data-cy='user-fullname'>{user?.fullName}</AvatarName>
            {isAccountOnline(user) && (
              <CloudIconContainer src={CloudIcon} alt='cloud' />
            )}
          </UserOption>
        );
      })}
      <AddUserAccount
        data-cy='add-account-link'
        onClick={() => setAccountSetup(true)}
      >
        <img src={PlusIcon} alt='add' />
        <span>New user profile</span>
      </AddUserAccount>
      <AddUserAccount
        data-cy='use-online-account'
        onClick={() => setOnlineAccount(true)}
      >
        <img src={PlusIcon} alt='add' />
        <span>Use Online Account</span>
      </AddUserAccount>
    </Container>
  );
};
