import { IsocyanatesTable } from '../containers/CalculatorsPage/components/CalculatorIsocyanates.interface';
import { ResinsTable } from '../containers/CalculatorsPage/components/CalculatorResins.interface';
import { FormulationInterface } from '../redux/reducers/interfaces';

export const modifyDBFormulations = (
  retrievedFormulations: any,
  selecteduserEmail: string,
): FormulationInterface[] => {
  const updatedFormulations = retrievedFormulations.map((formulation: any) => {
    const isoCyanatesMaterials: IsocyanatesTable[] = [];
    const resinMaterials: ResinsTable[] = [];
    formulation.materialsInFormulation.forEach((material: any) => {
      let modifiedMaterial = {
        ...material.material,
        parts: material.part,
        batchWeights: material.batchWeight,
        ncoContribution: material.ncoContribution,
        ohContribution: material.ohContribution,
        owner: material.material.owner.email,
        matCost: material.matCost,
        calcCost: material.calcCost,
        materialInFormulationId: material.id,
        materialKey: material.material.id,
      };
      delete modifiedMaterial.typeId;
      delete modifiedMaterial.ownerId;
      if (material.material.materialType === 'Isocyanates') {
        isoCyanatesMaterials.push(modifiedMaterial);
      }

      if (material.material.materialType === 'Resins') {
        modifiedMaterial.functionality = material.material.noFunctionality;
        resinMaterials.push(modifiedMaterial);
      }
    });
    return {
      hydroxilNo: formulation.hydroxilNo,
      key: formulation.id,
      index: formulation.index,
      isoBatchWeight: formulation.isoBatchWeight,
      name: formulation.name,
      nco: formulation.nco,
      ratio: formulation.ratio,
      resBatchWeight: formulation.resBatchWeight,
      materials: {
        isocyanates: isoCyanatesMaterials,
        resins: resinMaterials,
      },
      lastEdit: formulation.updatedAt,
      owner:
        formulation.user.email === selecteduserEmail
          ? 'Personal'
          : formulation.user.email,
    };
  });

  return updatedFormulations;
};
