import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { countryOptions } from '../../../helpers/countryOptions';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import { userProfileFields } from '../../../constants/userProfileFields';
import { saveUserChanges } from '../../../redux/actions/user';
import { withConfirmation } from '../../../redux/actions/ui';
import { FocusOnError } from '../../../components/FocusOnError/FocusOnError';
import { PHONE_NUMBER_REGEX } from '../../../constants/regex';
import { getSelectedUser } from '../../../redux/selectors/user';
import { UPDATE_USER_PROFILE } from '../../../graphql/mutations';
import { useMutation } from '@apollo/client';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { LoadingIndicator } from '../../../components';
import { QueryResult } from '../../../components/QueryResult/QueryResult';

const Container = styled.div`
  display: flex;
  padding: 1rem 10rem;
  justify-content: center;
  div {
    min-width: 50%;
  }
  .form {
    width: 100%;
    background-color: #fff;
    padding: 1rem;
  }
  @media (max-width: 1100px) {
    padding: 1rem 3rem 3rem;
  }
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  justify-content: flex-end;
`;

const validationSchema = Yup.object({
  fullName: Yup.string().trim().required('* Required'),
  email: Yup.string()
    .trim()
    .email('Invalid email address')
    .required('* Required'),
  address: Yup.string().max(500),
  phoneNumber: Yup.string().max(20).matches(PHONE_NUMBER_REGEX, {
    message: 'Invalid phone number.',
    excludeEmptyString: false,
  }),
});
export const UserDetailsForm = (): React.ReactElement => {
  const [updateUserProfile, { loading, error }] =
  useMutation(UPDATE_USER_PROFILE);
  const dispatch = useDispatch();
  const options = countryOptions();
  const selectedUser = useSelector(getSelectedUser);
  const isOnlineAccount = isAccountOnline(selectedUser);
  
  const handleEditProfile = async (userData: {
    fullName: string;
    email: string;
    company: string | undefined;
    role: string;
    phoneNumber: string | undefined;
    country: string;
    address: string | undefined;
  }) => {
    const user = {
      fullName: userData.fullName.trim(),
      email: userData.email.trim(),
      company: userData.company?.trim(),
      role: userData.role.trim(),
      phoneNumber: userData.phoneNumber,
      country: userData.country,
      address: userData.address?.trim(),
    };

    const userDb = {
      data: {
        role: {
          set: user.role
        },
        fullName: {
          set: user.fullName
        },
        email: {
          set: user.email
        },
        company: {
          set: user.company
        },
        country: {
          set: user.country
        },
        phoneNumber: {
          set: user.phoneNumber
        },
        address: {
          set: user.address
        }
      },
      where: {
        id: selectedUser?.id
      }
    };

    if (isOnlineAccount) {
      dispatch(
        withConfirmation(saveUserChanges(user, userDb, updateUserProfile), {
          content: 'Do you really want to save the changes?',
        }),
      );
    } else {
      dispatch(
        withConfirmation(saveUserChanges(user), {
          content: 'Do you really want to save the changes?',
        }),
      );
    }
  };

  return (
    <Container data-cy='profile-info-container'>
      <div className='form'>
        <Formik
          initialValues={{
            fullName: selectedUser?.fullName || '',
            email: selectedUser?.email || '',
            company: selectedUser?.company || '',
            role: selectedUser?.role || '',
            phoneNumber: selectedUser?.phoneNumber || '',
            country: selectedUser?.country || '',
            address: selectedUser?.address || '',
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleEditProfile}
        >
          {({
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            values,
          }) => (
            <Form>
              {userProfileFields.map(
                (item: {
                  id: string;
                  label: string;
                  type: string;
                  required: boolean;
                }) => {
                  return (
                    <FormFields key={item.id}>
                      <FormInputFields
                        item={item}
                        selectOptions={options}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                      />
                    </FormFields>
                  );
                },
              )}
              <Buttons>
                <QueryResult
                  error={error}
                  errorMessage='Cannot connect to database. Try again later.'
                />
                <ButtonStyled
                  data-cy='save-button'
                  type='primary'
                  size='large'
                  onClick={() => handleSubmit()}
                >
                  {loading
                    ? <LoadingIndicator container />
                    : 'Save'}
                </ButtonStyled>
              </Buttons>
              <FocusOnError />
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
