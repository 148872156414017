import React, { useState } from 'react';
import styled from 'styled-components';
import { Document as DocPdf, Page } from 'react-pdf/dist/umd/entry.webpack';
import { GlobalOutlined, MailOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import PDFFile from '../../resources/termsconditions.pdf';

interface FooterProps {
  mediaSize?: string;
}

const Container = styled.div`
  padding: 15px 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 0;
  @media (min-width: 990px) {
    height: ${({ theme }) => theme.footer.height};
  }
  @media (max-width: ${(props: { mediaSize: string }) => props.mediaSize}) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  @media (max-width: 770px) {
    margin-bottom: 0.5rem;
  }
`;

const BasfDetails = styled.div`
  display: flex;
  @media (max-width: 770px) {
    flex-direction: column;
  } ;
`;

const SpanLink = styled.span`
  cursor: pointer;
  color: #1890ff;
`;

export const Footer = ({ mediaSize = '770px' }: FooterProps) => {
  const [tcModalVisible, setTCModalVisible] = useState(false);

  return (
    <Container data-cy='footer-container' mediaSize={mediaSize}>
      <BasfDetails>
        <FooterItem>
          <MailOutlined style={{ marginRight: '10px' }} />
          <a data-cy='email-link' href='mailto:PolyurethanesNA@basf.com'>
            PolyurethanesNA@basf.com
          </a>
        </FooterItem>
        <FooterItem>
          <GlobalOutlined style={{ marginRight: '10px' }} />
          <a
            data-cy='basf-link'
            href='http://polyurethanes.basf.us'
            target='_blank'
            rel='noopener noreferrer'
          >
            polyurethanes.basf.us
          </a>
        </FooterItem>
      </BasfDetails>
      <FooterItem>
        <a
          data-cy='privacy-link'
          href='https://www.basf.com/us/en/legal/data-protection.html'
        >
          Privacy Policy
        </a>
        ,{' '}
        <SpanLink onClick={() => setTCModalVisible(true)}>
          Terms of Service
        </SpanLink>
        <a className='optanon-toggle-display'>Cookies Preference</a>
      </FooterItem>
      <Modal
        title='Terms of Service'
        centered
        visible={tcModalVisible}
        onOk={() => setTCModalVisible(false)}
        onCancel={() => setTCModalVisible(false)}
        width='auto'
      >
        <DocPdf
          file={PDFFile}
          options={{ workerSrc: '../../resources/pdf.worker.js' }}
        >
          <Page pageNumber={1} />
          <Page pageNumber={2} />
          <Page pageNumber={3} />
        </DocPdf>
      </Modal>
    </Container>
  );
};

Footer.defaultProps = {
  mediaSize: '770px',
};
