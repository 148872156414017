import { Collapse, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Document as DocPdf, Page } from 'react-pdf/dist/umd/entry.webpack';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PDFFile from '../../resources/termsconditions.pdf';

const Container = styled.div`
  background: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  padding: 50px 50px;
`;

const Header = styled.p`
  width: max-content;
  font-size: 32px;
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  border-bottom: 2px solid
    ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
`;

const CustomCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 700;
    background-color: #65ac1e;
    border: 2px solid #65ac1e;
    color: white;
  }
`;
export const Faq = () => {
  const [tcModalVisible, setTCModalVisible] = useState(false);

  const handleModal = () => {
    setTCModalVisible((curr) => !curr);
  };
  return (
    <>
      <Content>
        <Container>
          <Header>FAQ</Header>
          <CustomCollapse defaultActiveKey={['1']}>
            <Collapse.Panel
              header='What is the difference between the online version vs. local version of the OttoKalc app?'
              key='1'
            >
              <p>
                The online version of Ottokalc stores your formulations and
                information within a secure cloud environment. The local version
                of Ottokalc will store formulations and information within your
                personal web browser. Be aware within the local app, when
                browser cookies are cleared, you will lose your associated data
                within Ottokalc.
              </p>
            </Collapse.Panel>
            <Collapse.Panel header='Who has access to my data?' key='2'>
              <p>
                BASF cannot access the detailed information within your Ottokalc
                profile. If you are using the online version of OttoKalc, all
                information is stored in an encrypted cloud. If you are using
                the local version of OttoKalc, all information is stored on your
                personal browser. If you have any questions around data privacy,
                please view the{' '}
                <a
                  target='_blank'
                  href='https://www.basf.com/us/en/legal/data-protection.html'
                >
                  Privacy Policy
                </a>
                ,{' '}
                <span
                  onClick={() => handleModal()}
                  style={{
                    cursor: 'pointer',

                    color: '#096dd9',
                  }}
                >
                  Terms of Service
                </span>{' '}
                or reach out to{' '}
                <a
                  target='_blank'
                  href='mailto:polyurethanesNA@basf.com?subject=BASF OttoKalc™'
                >
                  polyurethanesNA@basf.com
                </a>
                .
              </p>
            </Collapse.Panel>
            <Collapse.Panel header='How do I use the OttoKalc app?' key='3'>
              <p>
                The <Link to='/main/tour'>app tour</Link> outlines major
                functionally and can be found by clicking your name in top right
                hand corner
              </p>
            </Collapse.Panel>
            <Collapse.Panel
              header='Can I share my formulations with others?'
              key='4'
            >
              <p>
                Yes! Within the desired formulation, utilize the export feature
                to extract the formulation. The shared user can then use the
                Import feature to add the formulation to their library.
              </p>
            </Collapse.Panel>
          </CustomCollapse>
        </Container>
      </Content>
      <Modal
        title='Terms of Service'
        centered
        visible={tcModalVisible}
        onOk={() => setTCModalVisible(false)}
        onCancel={() => setTCModalVisible(false)}
        width='auto'
      >
        <DocPdf
          file={PDFFile}
          options={{ workerSrc: '../../resources/pdf.worker.js' }}
        >
          <Page pageNumber={1} />
          <Page pageNumber={2} />
          <Page pageNumber={3} />
        </DocPdf>
      </Modal>
    </>
  );
};
