export const userProfileFields = [
  {
    id: 'fullName',
    label: 'Full Name',
    type: 'text',
    required: true,
  },
  {
    id: 'email',
    label: 'Email Address',
    type: 'text',
    required: true,
  },
  {
    id: 'company',
    label: 'Company',
    type: 'text',
    required: false,
  },
  {
    id: 'role',
    label: 'Role',
    type: 'text',
    required: false,
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
    required: false,
  },
  {
    id: 'country',
    label: 'Country',
    type: 'select',
    required: false,
  },
  {
    id: 'address',
    label: 'Address',
    type: 'text',
    required: false,
  },
];
