import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonStyled } from '../../components/Button/Button.styles';
import {
  IsocyanateInterface,
  MaterialInterface,
  ResinInterface,
} from '../../redux/reducers/interfaces';
import { getSelectedUser } from '../../redux/selectors/user';
import { IsocyanateCreateForm } from './components/IsocyanateCreateForm';
import { ResinCreateForm } from './components/ResinCreateForm';
import {
  AddFormContainer,
  ButtonGroup,
  Container,
  Heading,
  SwitchContainer,
} from './MaterialCreate.styles';

export const MaterialCreate = (): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState('resin');
  const [isUpdateState, setIsUpdateState] = useState(false);
  const [material, setMaterial] = useState<MaterialInterface>();

  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useSelector(getSelectedUser);
  const handleSubmit = () =>
    navigate('/materials', {
      state: { isMaterialCreated: true },
    });
  const handleUpdate = () =>
    navigate('/materials', { state: { isMaterialUpdated: true } });

  useEffect(() => {
    if ((state as { key: string })?.key) {
      const key = (state as { key: string }).key;
      const updateMaterial = user?.materials.find((item) => item.key === key);
      const materialType = updateMaterial?.materialType as string;
      const transformedType = materialType
        .toLowerCase()
        .substring(0, materialType.length - 1);

      setIsUpdateState(true);
      setSelectedTab(transformedType);
      setMaterial(updateMaterial);
    }
  }, []);

  const createButtons = (
    <ButtonGroup>
      <ButtonStyled
        type={selectedTab === 'resin' ? 'primary' : 'default'}
        data-cy='resin-tab'
        size='small'
        onClick={() => setSelectedTab('resin')}
      >
        Resin
      </ButtonStyled>
      <ButtonStyled
        type={selectedTab === 'isocyanate' ? 'primary' : 'default'}
        data-cy='iso-tab'
        size='small'
        onClick={() => setSelectedTab('isocyanate')}
      >
        Isocyanate
      </ButtonStyled>
    </ButtonGroup>
  );

  return (
    <Container>
      <AddFormContainer>
        <Heading>
          {isUpdateState ? 'Update Material' : 'Add New Material'}
        </Heading>
        {!isUpdateState && createButtons}

        {selectedTab === 'resin' && (
          <ResinCreateForm
            updateMaterial={material as ResinInterface}
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
          />
        )}

        {selectedTab === 'isocyanate' && (
          <IsocyanateCreateForm
            updateMaterial={material as IsocyanateInterface}
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
          />
        )}
      </AddFormContainer>
    </Container>
  );
};
