import { useMutation } from '@apollo/client';
import { Col, Layout, Row } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, TabMenu, ImportModal } from '../../components';
import { QueryResult } from '../../components/QueryResult/QueryResult';
import { DELETE_MATERIAL } from '../../graphql/mutations';
import { getConnectionStatus } from '../../helpers/checkConnection';
import { downloadJSON, uploadFile } from '../../helpers/hooks';
import { isAccountOnline } from '../../helpers/nameInitials';
import { addCustomMaterial } from '../../redux/actions/app';
import { MaterialInterface } from '../../redux/reducers/interfaces';
import { getMaterials } from '../../redux/selectors/materials';
import { getSelectedUser } from '../../redux/selectors/user';
import { IsocyanatesContent } from './components/IsocyanatesContent/IsocyanatesContent';
import { ResinsContent } from './components/ResinsContent/ResinsContent';
import { Container, SpaceButtons } from './MaterialsPage.styles';

const tabs = [
  {
    key: 'resins',
    label: 'Resins',
  },
  {
    key: 'iso',
    label: 'Isocyanates',
  },
];

export const MaterialsPage = () => {
  const [deleteDbMaterial, { loading, error, data }] =
    useMutation(DELETE_MATERIAL);

  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0].key);
  const [uploadedMaterials, setUploadedMaterials] = useState<
    MaterialInterface[]
  >([]);
  const [isConnectedToInternet, setIsConnectedToInternet] = useState(false);
  const [selectedMaterials, setSelectedMaterials] = useState<
    MaterialInterface[]
  >([]);
  const [isMaterialCreated, setIsMaterialCreated] = useState(false);
  const [isMaterialUpdated, setIsMaterialUpdated] = useState(false);
  const [isMaterialDeleted, setIsMaterialDeleted] = useState(false);
  const [isMaterialOfflineDeleted, setIsMaterialOfflineDeleted] =
    useState(false);
  const [isOfflineLoading, setIsOfflineLoading] = useState(false);
  const [isBoth, setIsBoth] = useState(false);
  const navigate = useNavigate();
  let { state } = useLocation();
  const selectedUser = useSelector(getSelectedUser);
  const isOnlineAccount = isAccountOnline(selectedUser);
  const materials = useSelector(getMaterials);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (data || isMaterialOfflineDeleted) {
      setIsMaterialDeleted(true);

      timer = setTimeout(() => {
        setIsMaterialDeleted(false);
      }, 5000);
    }
    if (state) {
      if ((state as { isMaterialUpdated: boolean }).isMaterialUpdated) {
        setIsMaterialUpdated(true);

        setTimeout(() => {
          setIsMaterialUpdated(false);
        }, 5000);
      } else {
        setIsMaterialCreated(true);
        setIsBoth((state as { isBoth: boolean }).isBoth);

        setTimeout(() => {
          setIsMaterialCreated(false);
        }, 5000);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state, data, isMaterialOfflineDeleted]);

  useEffect(() => {
    getConnectionStatus().then((result) => {
      setIsConnectedToInternet(result);
    });
  });

  const { Content } = Layout;

  const selectedMaterialKeys = useMemo(
    () => selectedMaterials.map((row) => row.key),
    [selectedMaterials],
  );

  const exportEnabled = selectedMaterials.length > 0;

  const exportSelectedMaterials = useCallback(() => {
    downloadJSON(
      selectedMaterials,
      `${selectedMaterials.length}-materials-${new Date().getTime()}`,
    );
    setSelectedMaterials([]);
  }, [selectedMaterials]);

  const handleSelectTab = (e: { key: string }) => {
    setSelectedTabKey(e.key);
  };

  const renderSelected = () => {
    const rowSelection: TableRowSelection<MaterialInterface> = {
      type: 'checkbox',
      selectedRowKeys: selectedMaterialKeys,
      onChange: (_, selectedRows) => setSelectedMaterials(selectedRows),
      getCheckboxProps: (record) => ({
        disabled: record.owner === 'BASF',
        name: record.title,
      }),
    };

    if (selectedTabKey === 'iso')
      return (
        <IsocyanatesContent
          rowSelection={rowSelection}
          deleteMaterial={
            isOnlineAccount ? deleteDbMaterial : setIsMaterialOfflineDeleted
          }
          setIsLoading={setIsOfflineLoading}
        />
      );

    if (selectedTabKey === 'resins')
      return (
        <ResinsContent
          rowSelection={rowSelection}
          deleteMaterial={
            isOnlineAccount ? deleteDbMaterial : setIsMaterialOfflineDeleted
          }
          setIsLoading={setIsOfflineLoading}
        />
      );

    return null;
  };

  const handleUpload = () => {
    uploadFile(setUploadedMaterials);
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string()
          .trim()
          .required('reject')
          .notOneOf(
            materials.map((material: MaterialInterface) =>
              material.title.trim(),
            ),
            'duplicated_name',
          ),
        key: Yup.string()
          .required('reject')
          .notOneOf(
            materials.map((material: MaterialInterface) => material.key),
            'duplicated_key',
          ),
        owner: Yup.string().required('reject'),
        eqWeight: Yup.number().nullable().required('reject'),
        materialType: Yup.string().required('reject'),
        type: Yup.string().required('reject'),
        comments: Yup.string().nullable().notRequired(),
      }),
    [materials],
  );

  return (
    <>
      <Content>
        <Container>
          <Row className='formulationButtonRow' justify='space-between'>
            <Col>
              <TabMenu
                selected={selectedTabKey}
                handleClick={handleSelectTab}
                tabs={tabs}
                fullWidth
              />
            </Col>
            <Col>
              <QueryResult
                error={error}
                errorMessage='Error while performing this action. If deleting a Material, please check if the material doenst belong to a formulation or try again later'
                successMessage={
                  (isMaterialDeleted && 'Material successfuly deleted!') ||
                  (isMaterialCreated &&
                    `${
                      isBoth ? 'Materials' : 'Material'
                    } successfuly created!`) ||
                  (isMaterialUpdated && 'Material successfuly updated!')
                }
                loading={loading || isOfflineLoading}
                container
                green
                data={
                  isMaterialDeleted || isMaterialCreated || isMaterialUpdated
                }
              />
            </Col>
            <Col>
              <SpaceButtons>
                <Button
                  dataCy='materials-page-export-action'
                  disabled={!exportEnabled}
                  type='text'
                  onClick={exportSelectedMaterials}
                >
                  Export
                  {exportEnabled && ` (${selectedMaterials.length})`}
                </Button>
                <Button
                  dataCy='materials-page-import-action'
                  onClick={handleUpload}
                >
                  Import
                </Button>
                <Button
                  dataCy='materials-page-new-material-action'
                  type='primary'
                  onClick={() =>
                    navigate(
                      `/materials/new/${
                        !isConnectedToInternet ? 'noInternetConnection' : ''
                      }`,
                    )
                  }
                >
                  + New Material
                </Button>
              </SpaceButtons>
            </Col>
          </Row>
          {renderSelected()}
        </Container>
      </Content>
      <ImportModal
        data={uploadedMaterials}
        validationSchema={validationSchema}
        handleImport={addCustomMaterial}
        onOk={() => setUploadedMaterials([])}
        onCancel={() => setUploadedMaterials([])}
      />
    </>
  );
};
