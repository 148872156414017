import styled from 'styled-components';
import { Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  margin-top: 3rem;
  padding: 24px 0px;
`;

export const Calculator = styled(Table)`
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }

  tr {
    th,
    td {
      &:first-child {
        padding-left: 24px;
      }
    }
    &:first-child {
      th {
        background-color: #ffffff;
        border-bottom: 1px solid #c6cddc;
      }
    }
  }

  .ant-table-tbody {
    tr:last-child {
      &:only-child {
        border-top: 0px solid transparent;
      }
    }
    .ant-input,
    .ant-input-affix-wrapper {
      min-width: 80px;
    }
  }
  .ant-table-cell.hidden {
    transition: 500ms ease-in-out;
    display: none;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.texts.colors.brand.neutral[80]};
  font-size: ${({ theme }) => theme.texts.largeText.size};
  padding: 0 24px;
`;
export const InputLabel = styled.p`
  color: ${({ theme }) => theme.texts.colors.brand.neutral[100]};
  font-weight: ${({ theme }) => theme.texts.bold};
  margin: 10px 0px;
`;

export const BatchContainer = styled.div`
  padding: 0px 24px;
  .ant-input {
    min-width: 150px;
    max-width: 150px;
  }
`;

export const RemoveButton = styled(CloseOutlined)`
  font-size: 18px;
  padding: 0.5em;
  color: silver;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.texts.colors.brand.neutral[40]};
    background-color: rgba(0, 0, 0, 0.018);
  }
`;
