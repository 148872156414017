import React from 'react';
import { LabeledTextProps } from './LabeledText.interface';
import { LabelText } from './LabeledText.styles';

export const LabeledText = ({
  label,
  text = '-',
  ...props
}: LabeledTextProps) => {
  return (
    <>
      <LabelText {...props}>{label}</LabelText>
      {text}
    </>
  );
};
