import styled from 'styled-components';
import Paragraph from 'antd/lib/typography/Paragraph';

interface EditableContent {
  active?: boolean;
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .title {
    width: 100%;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }

    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.texts.largeText.size};
  }
  .ant-space {
    flex-wrap: wrap;

    &.actions {
      .ant-divider {
        display: none;
      }
      .ant-space-item {
        margin-right: 0px;
        margin-bottom: 8px;
        width: 100%;
        button {
          width: 100%;
          &.ant-switch {
            width: 30px;
          }
        }
      }
    }
  }
  @media (min-width: 640px) {
    .title {
      margin-bottom: 0px;
    }
    .ant-space {
      flex-wrap: nowrap;
      &.actions {
        .ant-divider {
          display: block;
        }
        .ant-space-item {
          margin-bottom: 0px;
          width: initial;
        }
      }
    }

    flex-wrap: nowrap;
    .title {
      width: initial;
    }
  }
`;

export const Container = styled.div`
  padding: 50px 50px;
`;

export const FormulationTitle = styled(Paragraph)`
  border-bottom: 1px solid
    ${(props) => props.theme.texts.colors.brand.neutral[40]};
  &.ant-typography {
    margin-bottom: 0px;
  }
  .ant-input {
    font-size: 20px;
    background: transparent;
    border: 0px;
  }
`;

export const ContentEditable = styled.div<EditableContent>`
  padding: 1px;
  &:hover {
    background-color: ${({ active }) =>
      active ? 'rgb(0 0 0 / 13%)' : 'transparent'};
  }
`;

export const CalculatorsFrame = styled.div`
  margin: 15px 0px;
`;
