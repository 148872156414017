import { Space } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { ImportUserModal } from '../../../components/Modals/ImportUserModal/ImportUserModal';
import { UserUploadProps } from '../../../components/Modals/ImportUserModal/ImportUserModal.interface';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import { uploadFile } from '../../../helpers/hooks';
import { addCustomMaterial, addUser } from '../../../redux/actions/app';
import { addSingleFormulation } from '../../../redux/actions/formulation';
import { UserInterface } from '../../../redux/reducers/interfaces';
import { WelcomeMessageProps } from '../LoginPage.interface';

const Container = styled.div`
  padding: 50px 50px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > div {
    margin: auto;
  }
  @media (min-width: 767px) {
    height: ${({ theme }) => `calc(100vh - ${theme.nav.height})`};
  }
`;

const TextParagraph = styled.p`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-size: ${(props) => props.theme.texts.smallText.size};
  max-width: 32em;
  &:only-child {
    margin-bottom: 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (min-width: 767px) {
    justify-content: flex-end;
  }
`;

export const WelcomeMessage = ({
  setOnlineAccount,
  setWelcomeMessage,
  setNewAccount,
}: WelcomeMessageProps): React.ReactElement => {
  const dispatch = useDispatch();
  const [profileUpload, setProfileUpload] = useState<UserUploadProps>();

  const validationSchema = Yup.object({
    profile: Yup.object().required('reject'),
  });

  const handleUpload = () => {
    uploadFile(setProfileUpload);
  };

  const handleAddUser = () => {
    dispatch(addUser(profileUpload?.profile as UserInterface));
    if (profileUpload?.materials) {
      profileUpload.materials.forEach((x) => dispatch(addCustomMaterial(x)));
    }
    if (profileUpload?.formulations) {
      profileUpload.formulations.forEach((x) =>
        dispatch(addSingleFormulation(x)),
      );
    }
    setWelcomeMessage(false);
    setNewAccount(false);
  };

  return (
    <>
      <Container>
        <Space direction='vertical' size='large'>
          <SectionHeading label='Welcome' />
          <TextParagraph>
            Welcome to OttoKalc™ by BASF! This is a fully functioning offline
            app meaning you do not have to be connected to the internet to use
            it. You will find a list of materials and build in formulations so
            that you can calculate your product needs independently. Create an
            user profile to get started!
          </TextParagraph>
          <SectionHeading label='Important' />
          <TextParagraph>
            This application uses your browser database to keep all the
            information, with that in mind, if you clear your browser data it
            may happen that all your information will be lost.
          </TextParagraph>
          <TextParagraph>
            You can Export all your profile (including materials and
            formulations) during the use of it and Import to retrieve everything
            in case this happens.
          </TextParagraph>
          <Buttons>
            <Space>
              <ButtonStyled
                data-cy='go-to-form-button'
                type='text'
                size='large'
                onClick={() => handleUpload()}
              >
                Import Account
              </ButtonStyled>
              <ButtonStyled
                data-cy='go-to-form-button'
                type='primary'
                size='large'
                onClick={() => {
                  setOnlineAccount(true);
                  setWelcomeMessage(false);
                }}
              >
                Login
              </ButtonStyled>
              <ButtonStyled
                data-cy='go-to-form-button'
                type='primary'
                size='large'
                onClick={() => setWelcomeMessage(false)}
              >
                Create account
              </ButtonStyled>
            </Space>
          </Buttons>
        </Space>
      </Container>
      <ImportUserModal
        data={profileUpload}
        validationSchema={validationSchema}
        handleImport={handleAddUser}
        onOk={() => setProfileUpload(undefined)}
        onCancel={() => setProfileUpload(undefined)}
      />
    </>
  );
};
