import * as Sentry from '@sentry/react';
import React from 'react';
import ReactGA from 'react-ga4';
import Favicon from 'react-favicon';
import { message } from 'antd';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { store } from '../config/store';
import { theme } from '../constants/theme';
import { RouterLayout } from '../containers/RouterLayout';
import { ScrollToTop } from '../helpers/scrollToTop';
import './index.css';
import BASFLogo from '../resources/BASF-logo-full.svg';
import { getConnectionStatus } from '../helpers/checkConnection';
import { ProtectedRoutes } from '../components/ProtectedRoutes/ProtectedRoutes';
import localforage from 'localforage';
import { ENDPOINT } from '../api/api';
import { UtagProvider } from '../providers/UTagProvider/UTagProvider';

const FallbackComponent = () => {
  return <div>An error has occurred</div>;
};

const httpLink = createHttpLink({
  uri: `${ENDPOINT}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await localforage.getItem('reduxPersist:userToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${JSON.parse(token as string)}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const history = createBrowserHistory();
history.listen(async ({ location }) => {
  ReactGA.set({ page: location.hash, online: await getConnectionStatus() });
  ReactGA.send({ hitType: 'pageview', path: location.hash });
});

export const App: React.FC = () => {
  message.config({ maxCount: 1 });

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <Favicon url={BASFLogo} />
            <Router>
              <UtagProvider>
                <RouterLayout>
                  <ScrollToTop />
                  <ProtectedRoutes />
                </RouterLayout>
              </UtagProvider>
            </Router>
          </ApolloProvider>
        </Provider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};
