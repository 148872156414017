import { IsocyanatesTable } from '../containers/CalculatorsPage/components/CalculatorIsocyanates.interface';
import { ResinsTable } from '../containers/CalculatorsPage/components/CalculatorResins.interface';
import { v4 as uuid } from 'uuid';

const createFormulationPayload = (newFormulation: any) => {
  let payload: any = {
    data: {
      name: newFormulation.name,
      materialsInFormulation: {
        createMany: {
          data: [],
        },
      },
      index: newFormulation.index,
      hydroxilNo: newFormulation.hydroxilNo,
      isoBatchWeight: newFormulation.isoBatchWeight,
      resBatchWeight: newFormulation.resBatchWeight,
      nco: newFormulation.nco,
      ratio: newFormulation.ratio,
      id: newFormulation.id,
      updatedAt: new Date(newFormulation.lastEdit).toISOString(),
    },
  };

  newFormulation.materials.isocyanates.forEach((iso: IsocyanatesTable) => {
    if (!iso.materialKey && (!iso.key || iso.key === '0')) return;
    const materialForFormulationData = {
      id: uuid(),
      part: String(iso.parts),
      materialId: iso.materialKey,
      batchWeight: String(iso.batchWeights),
      calcCost: iso.calcCost,
      matCost: iso.matCost,
      ncoContribution: iso.ncoContribution,
    };

    iso.materialInFormulationId = materialForFormulationData.id;
    payload.data.materialsInFormulation.createMany.data.push(
      materialForFormulationData,
    );
  });

  newFormulation.materials.resins.forEach((resin: ResinsTable) => {
    if (!resin.materialKey && (!resin.key || resin.key === '0')) return;
    const materialForFormulationData = {
      id: uuid(),
      part: String(resin.parts),
      materialId: resin.materialKey,
      batchWeight: String(resin.batchWeights),
      calcCost: resin.calcCost,
      matCost: resin.matCost,
      ohContribution: resin.ohContribution,
    };
    resin.materialInFormulationId = materialForFormulationData.id;
    payload.data.materialsInFormulation.createMany.data.push(
      materialForFormulationData,
    );
  });

  return payload;
};

export default createFormulationPayload;
