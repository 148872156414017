export enum AppStateActions {
  ADD_ID = 'ADD_ID',
  GET_USER = 'GET_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  IS_AUTHENTICATED = 'IS_AUTHENTICATED',
  GET_MATERIALS = 'GET_MATERIALS',
  IS_FETCHING = 'IS_FETCHING',
  FETCH_BASF_MATERIALS = 'FETCH_BASF_MATERIALS',
  GET_MATERIAL_TYPES = 'GET_MATERIAL_TYPES',
  ADD_MATERIAL_TYPE = 'ADD_MATERIAL_TYPE',
  ADD_CUSTOM_MATERIAL = 'ADD_CUSTOM_MATERIAL',
  IS_ONLINE = 'IS_ONLINE',
  ADD_USER_TOKEN = 'ADD_USER_TOKEN',
}

export enum MaterialActions {
  REMOVE_MATERIAL = 'REMOVE_MATERIAL',
  UPDATE_CUSTOM_MATERIAL = 'UPDATE_CUSTOM_MATERIAL',
}

export enum UserActions {
  SAVE_EDIT = 'SAVE_EDIT',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  UPDATE_DB_USER = 'UPDATE_DB_USER',
  GET_LOGGED_USER = 'GET_LOGGED_USER',
  EXPORT_USER_DATA = 'EXPORT_USER_DATA',
}

export enum FormulationActions {
  FETCH_BASF_FORMULATIONS = 'FETCH_BASF_FORMULATIONS',
  ADD_FORMULATION = 'ADD_FORMULATION',
  GET_FORMULATION = 'GET_FORMULATION',
  DUPLICATE_FORMULATION = 'DUPLICATE_FORMULATION',
  REMOVE_FORMULATION = 'REMOVE_FORMULATION',
  UPDATE_FORMULATION = 'UPDATE_FORMULATION',
  ADD_FORMULATIONS = 'ADD_FORMULATIONS',
}
