import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

const Container = styled.div`
  background: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  padding: 50px 50px;
`;

const Header = styled.p`
  width: max-content;
  font-size: 32px;
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  border-bottom: 2px solid
    ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
`;

export const CookiePage = () => {
  return (
    <Content>
      <Container>
        <Header>Cookies Declaration</Header>
        <button id='ot-sdk-btn' className='ot-sdk-show-settings'>
          Cookie Settings
        </button>
        <div id='ot-sdk-cookie-policy'></div>
      </Container>
    </Content>
  );
};
