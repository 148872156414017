import { applyMiddleware, createStore, compose } from 'redux';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import thunk from 'redux-thunk';
import * as localforage from 'localforage';
import { appStateReducer } from '../redux/reducers/appReducer';

offlineConfig.persistOptions = { storage: localforage };

export const store = createStore(
  appStateReducer,
  compose(applyMiddleware(thunk), offline(offlineConfig)),
);
