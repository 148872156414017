import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Container, IndexContainer } from './CalculatorOutput.styles';
import { LabeledText } from '../../../components/LabeledText/LabeledText';
import { CalculatorOutputProps } from './CalculatorOutput.interface';
import { ResinsTable } from './CalculatorResins.interface';
import { BoldText } from './CalculatorResins.styles';
import { NumberFieldInputStyled } from '../../../components/Input/Input.styles';
import DownSizeIcon from '../../../resources/down-small.svg';
import { IsocyanatesTable } from './CalculatorIsocyanates.interface';
import { Button } from '../../../components/Button/Button';

export const CalculatorOutput = ({
  resins,
  isocyanates,
  handleIndex,
  handleRatio,
  index,
  enabled,
  download,
  saved,
}: CalculatorOutputProps) => {
  const [ratio, setRatio] = useState(1.0);
  const [minify, setMinify] = useState(false);

  useEffect(() => {
    if (index) setRatio(index);
  }, [index]);

  const getResinsEqWt = (): number => {
    if (!resins?.length) return 0;
    let total = 0;
    resins?.forEach((x: ResinsTable) => {
      total += x.ohContribution;
    });
    return total > 0 ? 56100 / total : 0;
  };

  const getResinsOHNumber = (): number => {
    if (!resins?.length) return 0;
    let total = 0;
    resins?.forEach((x: ResinsTable) => {
      total += x.ohContribution;
    });
    return total;
  };

  const getIsoEqWeight = (): number => {
    if (!isocyanates?.length) return 0;
    let total = 0;
    isocyanates?.forEach((x: IsocyanatesTable) => {
      total += x.ncoContribution;
    });
    return total > 0 ? 4202 / total : 0;
  };

  const getIsoNCOPercent = (): number => {
    if (!isocyanates?.length) return 0;
    let total = 0;
    isocyanates?.forEach((x: IsocyanatesTable) => {
      total += x.ncoContribution;
    });
    return total;
  };

  const calculateRatio = () => {
    const isoEqWt = getIsoEqWeight();
    const resinEqWt = getResinsEqWt();

    const calcRatio = resinEqWt ? (isoEqWt * ratio * 100) / resinEqWt : 0;
    handleRatio(calcRatio);
    return calcRatio.toLocaleString('en-US', { maximumFractionDigits: 2 });
  };

  useEffect(() => {
    calculateRatio();
  }, [isocyanates, resins]);

  return (
    <Container>
      <Row gutter={15} justify='space-between'>
        <Col>
          <LabeledText
            data-cy='calculator-output-resin-blend-eqwt'
            label='Resin Blend Equivalent Weight'
            text={getResinsEqWt().toFixed(3)}
          />
        </Col>
        <Col>
          <LabeledText
            data-cy='calculator-output-resin-hydroxyl-no'
            label='Resin Blend Hydroxyl Number'
            text={getResinsOHNumber().toFixed(3)}
          />
        </Col>
        <Col>
          <LabeledText
            data-cy='calculator-output-isocyantes-blend-eqwt'
            label='Isocyanate Blend Equivalent Weight'
            text={getIsoEqWeight().toFixed(3)}
          />
        </Col>
        <Col>
          <LabeledText
            data-cy='calculator-output-isocyantes-blend-nco'
            label='Isocyanate Blend NCO %'
            text={getIsoNCOPercent().toFixed(3)}
          />
        </Col>
        <Col>
          <LabeledText
            data-cy='calculator-output-ratio'
            label='Ratio (Parts of Iso per 100 parts Resin)'
            text={calculateRatio()}
          />
        </Col>
        <Col
          data-cy='calculator-output-minify-div'
          onClick={() => setMinify((currentMinifyState) => !currentMinifyState)}
        >
          <img
            className={`arrow-output ${minify ? 'rotate' : ''}`}
            src={DownSizeIcon}
            alt='Reduce Calculation Output size'
          />
        </Col>
      </Row>
      <Row gutter={15} className={minify ? 'hide' : 'show'} align='middle'>
        <Col>
          <IndexContainer>
            <BoldText>Index</BoldText>
            {enabled ? (
              <NumberFieldInputStyled
                data-cy='calculator-output-index-input'
                defaultValue={index || 1.0}
                type='number'
                step='.01'
                onChange={(e) => {
                  handleIndex(Number(e));
                  setRatio(Number(e));
                }}
              />
            ) : (
              <div data-cy='calculator-output-view-index'>{index}</div>
            )}
          </IndexContainer>
        </Col>
        <Col>
          <br />
          <br />
          <Button type='primary' onClick={download} disabled={saved && enabled}>
            <PrinterOutlined /> Print Ticket
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
