import { gql } from '@apollo/client';

export const CREATE_MATERIAL = gql`
  mutation createMaterial($data: MaterialCreateInput!) {
    createMaterial(data: $data) {
      title
      ohNumber
      noFunctionality
      typeId
      type {
        value
        id
        authorId
      }
      ownerId
      materialType
      eqWeight
      nco
      id
      key
      comments
      cost
    }
  }
`;
