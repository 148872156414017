import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import { TabMenuProps } from './TabMenu.interface';

const CustomMenu = styled(Menu)`
  border-bottom: 0;
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  background-color: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  font-size: ${(props) => props.theme.texts.text.size};
  font-weight: ${(props) => props.theme.texts.bold};
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
    border-bottom: 2px solid
      ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
  }
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin-left: 0px;
    margin-right: 2rem;
    &:after {
      border: 0px;
    }
  }
`;

const Container = styled.div<{ fullWidth: boolean }>`
  display: flex;
  padding: ${(props) => (props.fullWidth ? '0px' : '1rem 10rem')};
  justify-content: flex-start;
  @media (max-width: 1100px) {
    padding: ${(props) => (props.fullWidth ? '0px' : '1rem 3rem')};
  }
  div {
    min-width: 50%;
  }
`;

export const TabMenu = ({
  selected,
  handleClick,
  tabs,
  fullWidth = false,
}: TabMenuProps): React.ReactElement => {
  return (
    <Container fullWidth={fullWidth}>
      <CustomMenu
        data-cy='tab-menu'
        onClick={(e) => handleClick(e)}
        selectedKeys={[selected]}
        mode='horizontal'
      >
        {tabs.map((item) => {
          return (
            <Menu.Item data-cy={item.key} key={item.key}>
              {item.label}
            </Menu.Item>
          );
        })}
      </CustomMenu>
    </Container>
  );
};
