import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{container?: boolean, green?: boolean}>`
  height: 100${props => props.container ? '%' : 'vh'};
  display: flex;
  justify-content: center;
  align-items: center;
  & div span > i { background-color: ${props => props.green ? props.theme.texts.colors.brand.primaryGreen[60] : "white"} }
`;

export const LoadingIndicator: React.FC<{container?: boolean, green?: boolean}> = props => {
  return (
    <Container container={props.container} green={props.green} data-cy='loading-indicator'>
      <Spin style={{lineHeight: '0'}}/>
    </Container>
  );
};
