import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LeftArrow from '../../resources/arrow-left.svg';
import { ArrowHeadingProps } from './ArrowHeading.interface';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ArrowIcon = styled.img`
  margin-right: 0.5rem;
`;

export const ArrowHeading = ({
  title,
  path,
}: ArrowHeadingProps): React.ReactElement => {
  const { Title } = Typography;
  return (
    <Header>
      <Link data-cy='link' to={path}>
        <ArrowIcon src={LeftArrow} alt='Go back' />
      </Link>
      <Title
        data-cy='heading-title'
        level={2}
        style={{ color: '#454D5F', margin: 0 }}
      >
        {title}
      </Title>
    </Header>
  );
};
