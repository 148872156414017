import { OperationVariables } from '@apollo/client';
import { message } from 'antd';
import { RootStateOrAny } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getUser } from '../../api/api';
import { store } from '../../config/store';
import { AppStateActions, UserActions } from '../../constants/enums';

export const saveUserChanges =
  (
    userData: {},
    userDb?: {},
    updateUserProfile?: (variables: OperationVariables) => Promise<any>,
  ): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    if (updateUserProfile) {
      await updateUserProfile({ variables: userDb })
        .then(() => {
          dispatch({
            type: UserActions.SAVE_EDIT,
            payload: { userData },
          });
          message.success({
            content: 'Changes saved successfully.',
            style: {
              marginTop: '90vh',
            },
          });
        })
        .catch(() => {});
    } else {
      dispatch({
        type: UserActions.SAVE_EDIT,
        payload: { userData },
      });
      message.success({
        content: 'Changes saved successfully.',
        style: {
          marginTop: '90vh',
        },
      });
    }
  };

export const updateDbUser =
  (userData: {}): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: UserActions.UPDATE_DB_USER,
      payload: { userData },
    });
    dispatch(updateSelectedUser());
  };

export const refreshDbUser =
  (): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    const { selectedUser, userToken } = store.getState();
    await getUser(selectedUser!.id as string, userToken as string).then(
      (res) => {
        dispatch(updateDbUser(res.data.user));
      },
    );
  };

export const updateSelectedUser =
  (): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: UserActions.UPDATE_USER_DATA,
    });
  };

export const addToken =
  (token: string): ThunkAction<void, RootStateOrAny, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({
      type: AppStateActions.ADD_USER_TOKEN,
      payload: { token },
    });
  };
