import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const FocusOnError = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[for="${keys[0]}"]`);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isSubmitting, isValidating, errors]);

  return null;
};
