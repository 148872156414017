import { Layout, message, notification, Space, Switch, Typography } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
import { downloadJSON, usePrompt } from '../../helpers/hooks';
import { Divider } from '../../app/App.styles';
import { Button, LoadingIndicator } from '../../components';
import {
  addSingleFormulation,
  updateFormulation,
} from '../../redux/actions/formulation';
import {
  FormulationInterface,
  UserInterface,
} from '../../redux/reducers/interfaces';
import {
  getFormulationData,
  getFormulations,
} from '../../redux/selectors/formulations';
import { getSelectedUser } from '../../redux/selectors/user';
import ArrowLeft from '../../resources/arrow-left.svg';
import { CalculatorsPageProps } from './CalculatorsPage.interface';
import {
  CalculatorsFrame,
  Container,
  ContentEditable,
  Header,
} from './CalculatorsPage.styles';
import { CalculatorIsocyanates } from './components/CalculatorIsocyanates';
import { IsocyanatesTable } from './components/CalculatorIsocyanates.interface';
import { CalculatorOutput } from './components/CalculatorOutput';
import { CalculatorResins } from './components/CalculatorResins';
import { ResinsTable } from './components/CalculatorResins.interface';
import { download } from './helpers/download';
import { useMutation } from '@apollo/client';
import {
  CREATE_FORMULATION_FOR_USER,
  UPDATE_FORMULATION,
} from '../../graphql/mutations';
import createFormulationPayload from '../../helpers/createFormulationPayload';
import { isAccountOnline } from '../../helpers/nameInitials';

export const CalculatorsPage = ({ saved = false }: CalculatorsPageProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(!saved);
  const navigate = useNavigate();
  const { name } = useParams<{ name: string }>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [editableTitle, setEditableTitle] = useState('Formulation Name');
  const [resinsValues, setResinsValues] = useState<ResinsTable[]>([]);
  const [isocyanatesValues, setIsocyanatesValues] = useState<
    IsocyanatesTable[]
  >([]);

  const [isoBatchWeight, setIsoBatchWeight] = useState(1500);
  const [resBatchWeight, setResBatchWeight] = useState(1500);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const [index, setIndex] = useState(1.0);
  const [ratio, setRatio] = useState(0);
  const dispatch = useDispatch();
  const formulations = useSelector(getFormulations);
  const loadedFormulation = useSelector(getFormulationData(name));
  const loggedUser = useSelector(getSelectedUser);
  const [loadedFormulationError, setLoadedFormulationError] = useState<
    string | undefined
  >();
  const [addFormulationToDB] = useMutation(CREATE_FORMULATION_FOR_USER, {
    onError: (err) => {
      notification.error({
        message: err.message,
        style: {
          marginTop: '80vh',
        },
        description: (
          <div>
            Formulation was not created.
            <br />
          </div>
        ),
      });
      setIsSaving(false);
    },
  });
  const exportSelectedFormulations = useCallback(
    () => downloadJSON([loadedFormulation], `formulations`),
    [loadedFormulation],
  );

  const [updateFormulationToOnlineDB] = useMutation(UPDATE_FORMULATION, {
    onError: (err) => {
      notification.error({
        message: err.message,
        style: {
          marginTop: '80vh',
        },
        description: (
          <div>
            Formulation was not updated.
            <br />
          </div>
        ),
      });
      setIsSaving(false);
    },
    onCompleted: () => {
      setIsSaving(false);
      setIsEditing(false);
      navigate('/formulation');
    },
  });
  const titleRef = useRef<HTMLDivElement>(null);

  const checkIfBlocked = () => {
    if (!isEditing && saved && !isSaving) {
      return false;
    }
    if (
      (resinsValues.filter((x) => x.title !== '').length ||
        isocyanatesValues.filter((x) => x.title !== '').length) &&
      !isSaving &&
      isEditing &&
      !saved
    ) {
      return true;
    }
    if (isEditing && !isSaving && saved) {
      return true;
    }
    if (saved) {
      return isEditing;
    }
    return false;
  };

  usePrompt(
    `You might have unsaved changes, are you sure you want to proceed?`,
    checkIfBlocked(),
  );
  message.config({ maxCount: 1 });

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .required('* Required')
          .notOneOf(
            formulations.map((formulation) => formulation.name),
            'Must be unique',
          ),
      }),
    [formulations],
  );

  useEffect(() => {
    try {
      if (isEditing && isEditingTitle) {
        validationSchema.validateSync({ name: editableTitle });
        setLoadedFormulationError(undefined);
      }
    } catch (err) {
      setLoadedFormulationError((err as any).message);
    }
  }, [editableTitle, isEditing]);

  useEffect(() => {
    if (loadedFormulation && isFirstLoad && saved) {
      if (isEditing) {
        setIsFirstLoad(false);
      }
      setEditableTitle(loadedFormulation.name);
      setIsoBatchWeight(loadedFormulation.isoBatchWeight);
      setResBatchWeight(loadedFormulation.resBatchWeight);
      setIndex(loadedFormulation.index);
    }
  }, [loadedFormulation]);

  const getHydroxilNo = (): number => {
    let total = 0;
    resinsValues.forEach((x) => {
      if (x.ohNumber) {
        total += Number(x.ohContribution);
      }
    });
    return total;
  };

  const getIsoEqWeight = (): number => {
    if (!isocyanatesValues?.length) return 0;
    let total = 0;
    isocyanatesValues?.forEach((x: IsocyanatesTable) => {
      total += x.ncoContribution;
    });
    return total > 0 ? 4202 / total : 0;
  };

  const getResinsEqWt = (): number => {
    if (!resinsValues?.length) return 0;
    let total = 0;
    resinsValues?.forEach((x: ResinsTable) => {
      total += x.ohContribution;
    });
    return total > 0 ? 56100 / total : 0;
  };

  const getNcoNumber = (): number => {
    let total = 0;
    isocyanatesValues.forEach((x) => {
      if (x.nco) {
        total += Number(x.ncoContribution);
      }
    });
    return total;
  };

  const successCallback = (newFormulation: any) => {
    dispatch(addSingleFormulation(newFormulation));
    ReactGA.event({
      category: 'User',
      action: 'Created a Formulation',
    });
    notification.success({
      message: 'Formulation Created',
      style: {
        marginTop: '80vh',
      },
      description: (
        <div>
          <b>{newFormulation.name}</b> was created with success.
          <br />
          <button
            type='button'
            onClick={() =>
              navigate(`/formulation/calculator/${newFormulation.key}`)
            }
          >
            View
          </button>
        </div>
      ),
    });
  };

  const findDeletedMaterialForFormulations = (
    formulation: FormulationInterface,
  ) => {
    const deletedMaterialsForFormulations = [];
    const isocyantesMaterial =
      (loadedFormulation && [...loadedFormulation?.materials.isocyanates]) ||
      [];
    const resinMaterials =
      (loadedFormulation && [...loadedFormulation.materials.resins]) || [];

    deletedMaterialsForFormulations.push(
      ...isocyantesMaterial
        .filter(
          (material) =>
            !formulation.materials.isocyanates.find(
              (newMaterial) =>
                newMaterial.materialInFormulationId ===
                material.materialInFormulationId,
            ),
        )
        .map((material) => {
          return { id: material.materialInFormulationId };
        }),
    );
    deletedMaterialsForFormulations.push(
      ...resinMaterials
        .filter(
          (material) =>
            !formulation.materials.resins.find(
              (newMaterial) =>
                newMaterial.materialInFormulationId ===
                material.materialInFormulationId,
            ),
        )
        .map((material) => {
          return { id: material.materialInFormulationId };
        }),
    );

    return deletedMaterialsForFormulations;
  };

  const payloadForUpdateMutation = (
    updatedFormulation: FormulationInterface,
  ) => {
    let payload: any = {
      data: {
        name: {
          set: updatedFormulation.name,
        },
        materialsInFormulation: {
          upsert: [],
          deleteMany: [],
        },
        index: {
          set: updatedFormulation.index,
        },
        hydroxilNo: {
          set: updatedFormulation.hydroxilNo,
        },
        isoBatchWeight: {
          set: updatedFormulation.isoBatchWeight,
        },
        resBatchWeight: {
          set: updatedFormulation.resBatchWeight,
        },
        nco: { set: updatedFormulation.nco },
        ratio: { set: updatedFormulation.ratio },
        updatedAt: { set: new Date(updatedFormulation.lastEdit).toISOString() },
      },
      where: {
        id: updatedFormulation.key,
      },
    };

    updatedFormulation.materials.isocyanates.forEach(
      (iso: IsocyanatesTable) => {
        if (!iso.materialKey || !iso.key) return;
        const materialForFormulationData = {
          id: iso.materialInFormulationId
            ? iso.materialInFormulationId
            : uuid(),
          part: String(iso.parts),
          material: {
            connect: {
              id: iso.materialKey,
            },
          },
          batchWeight: String(iso.batchWeights),
          calcCost: iso.calcCost,
          matCost: iso.matCost,
          ncoContribution: iso.ncoContribution,
        };
        iso.materialInFormulationId = materialForFormulationData.id;
        payload.data.materialsInFormulation.upsert.push({
          where: {
            id: materialForFormulationData.id,
          },
          update: {
            part: { set: String(iso.parts) },
            batchWeight: { set: String(iso.batchWeights) },
            calcCost: { set: iso.calcCost },
            matCost: { set: iso.matCost },
            ncoContribution: { set: iso.ncoContribution },
            material: materialForFormulationData.material,
          },
          create: materialForFormulationData,
        });
      },
    );

    updatedFormulation.materials.resins.forEach((resin: ResinsTable) => {
      if (!resin.materialKey || !resin.key) return;
      const materialForFormulationData = {
        id: resin.materialInFormulationId
          ? resin.materialInFormulationId
          : uuid(),
        part: String(resin.parts),
        material: {
          connect: {
            id: resin.materialKey,
          },
        },
        batchWeight: String(resin.batchWeights),
        calcCost: resin.calcCost,
        matCost: resin.matCost,
        ohContribution: resin.ohContribution,
      };
      resin.materialInFormulationId = materialForFormulationData.id;
      payload.data.materialsInFormulation.upsert.push({
        where: {
          id: materialForFormulationData.id,
        },
        update: {
          part: { set: String(resin.parts) },
          material: materialForFormulationData.material,
          batchWeight: { set: String(resin.batchWeights) },
          calcCost: { set: resin.calcCost },
          matCost: { set: resin.matCost },
          ohContribution: { set: resin.ohContribution },
        },
        create: materialForFormulationData,
      });
    });

    const deletedFormulations =
      findDeletedMaterialForFormulations(updatedFormulation);
    deletedFormulations.forEach((deleteId) => {
      payload.data.materialsInFormulation.deleteMany.push({
        id: {
          equals: deleteId.id,
        },
      });
    });

    return payload;
  };

  const saveFormulation = async () => {
    setIsSaving(() => true);

    const titleNow = titleRef.current?.textContent;
    if (!titleNow) {
      setLoadedFormulationError('Formulation must have a title.');
      setIsSaving(() => false);
      return;
    }

    if (
      isocyanatesValues.filter((x) => x.title === '').length &&
      resinsValues.filter((x) => x.title === '').length
    ) {
      message.error({
        content: 'You need to select at least one material.',
        style: { marginTop: '15vh' },
      });
      setIsSaving(() => false);
      return;
    }

    if (saved) {
      if (titleNow !== loadedFormulation?.name) {
        try {
          validationSchema.validateSync({ name: titleNow });
          setLoadedFormulationError(undefined);
        } catch (err) {
          setIsSaving(() => false);
          setLoadedFormulationError((err as any).message);
          return;
        }
      }
      const updatedFormulation = {
        key: loadedFormulation?.key as string,
        name: titleNow,
        owner: 'Personal',
        lastEdit: Date.now(),
        hydroxilNo: getHydroxilNo(),
        index,
        nco: getNcoNumber(),
        ratio,
        isoBatchWeight,
        resBatchWeight,
        materials: { isocyanates: isocyanatesValues, resins: resinsValues },
      };
      if (isAccountOnline(loggedUser)) {
        const payload = payloadForUpdateMutation(updatedFormulation);

        await updateFormulationToOnlineDB({ variables: payload }).then(
          (res) => {
            if (res.data) {
              dispatch(updateFormulation(updatedFormulation));
            }
          },
        );
        return;
      }
      dispatch(updateFormulation(updatedFormulation));
    } else {
      try {
        validationSchema.validateSync({ name: titleNow });
        setLoadedFormulationError(undefined);
      } catch (err) {
        setLoadedFormulationError((err as any).message);
        setIsSaving(() => false);
        return;
      }
      const id = uuid();
      const newFormulation = {
        id: id,
        key: id,
        name: titleNow,
        owner: 'Personal',
        lastEdit: Date.now(),
        hydroxilNo: getHydroxilNo(),
        index,
        nco: getNcoNumber(),
        ratio,
        isoBatchWeight,
        resBatchWeight,
        materials: { isocyanates: isocyanatesValues, resins: resinsValues },
      };

      if (isAccountOnline(loggedUser)) {
        const payload = createFormulationPayload(newFormulation);

        await addFormulationToDB({ variables: payload }).then((res) => {
          if (res.data) {
            successCallback(newFormulation);
            setIsSaving(false);
            setIsEditing(false);
            navigate('/formulation');
          }
        });

        return;
      }
      successCallback(newFormulation);
    }
    navigate('/formulation');
    setIsSaving(false);
    setIsEditing(false);
  };

  const handleResinsCalculator = (value: ResinsTable[]) => {
    setResinsValues(value);
  };

  const handleIsocyanatesCalculator = (value: IsocyanatesTable[]) => {
    setIsocyanatesValues(value);
  };

  const handleSwitch = () => {
    setShowExtra((currentShowExtra) => !currentShowExtra);
  };

  const handleCancel = () => {
    if (!isEditing) navigate('/formulation');
    setIsEditing(false);
  };

  const downloadPDF = () => {
    const titleNow = titleRef?.current?.textContent;
    return download(
      titleNow as string,
      resBatchWeight,
      resinsValues,
      isoBatchWeight,
      isocyanatesValues,
      getResinsEqWt(),
      getIsoEqWeight(),
      ratio,
      getHydroxilNo(),
      getNcoNumber(),
      index,
      loggedUser as UserInterface,
    );
  };

  const { Content } = Layout;

  return (
    <Content>
      <Container>
        <Header>
          <div className='title'>
            <Link to='/formulation' data-cy='calculator-page-back-arrow'>
              <img src={ArrowLeft} alt='Back arrow' />
            </Link>
            <Space size='small' align='baseline'>
              {(saved && isEditing) || !saved ? (
                <ContentEditable
                  ref={titleRef}
                  active
                  contentEditable='true'
                  suppressContentEditableWarning
                  onInput={(e) => {
                    if (
                      e.currentTarget.textContent &&
                      e.currentTarget.textContent.length < 1
                    ) {
                      setLoadedFormulationError(
                        'Formulation must have a title.',
                      );
                    } else {
                      setLoadedFormulationError(undefined);
                    }
                  }}
                >
                  {editableTitle}
                </ContentEditable>
              ) : (
                <ContentEditable
                  suppressContentEditableWarning
                  contentEditable='false'
                >
                  {editableTitle}
                </ContentEditable>
              )}
              {loadedFormulationError && (
                <Typography.Title type='danger' level={5}>
                  {loadedFormulationError}
                </Typography.Title>
              )}
            </Space>
          </div>
          <Space className='actions'>
            <div>
              <Switch
                onChange={handleSwitch}
                data-cy='calculator-page-switch-calculations'
              />{' '}
              Show Cost Calculations
            </div>
            <Button
              onClick={exportSelectedFormulations}
              dataCy='calculator-export-action'
              type='link'
            >
              Export
            </Button>
            <Divider type='vertical' />
            <Button
              dataCy='calculator-cancel-action'
              type='ghost'
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {!isEditing ? (
              <Button
                dataCy='calculator-save-action'
                type='primary'
                onClick={() =>
                  setIsEditing((currentEditing) => !currentEditing)
                }
              >
                Edit
              </Button>
            ) : (
              <Button
                dataCy='calculator-save-action'
                type='primary'
                onClick={() => saveFormulation()}
                disabled={Boolean(loadedFormulationError)}
              >
                {isSaving ? <LoadingIndicator container /> : 'Save'}
              </Button>
            )}
          </Space>
        </Header>
        <CalculatorsFrame>
          <CalculatorResins
            isFirstLoad={isFirstLoad}
            loadedFormulation={loadedFormulation}
            enabled={isEditing}
            showExtraColumns={showExtra}
            handleResins={handleResinsCalculator}
            handleResinsBatchWeight={setResBatchWeight}
          />
          <CalculatorIsocyanates
            isFirstLoad={isFirstLoad}
            loadedFormulation={loadedFormulation}
            enabled={isEditing}
            showExtraColumns={showExtra}
            handleIsocyanates={handleIsocyanatesCalculator}
            handleIsoBatchWeight={setIsoBatchWeight}
          />
        </CalculatorsFrame>
      </Container>
      <CalculatorOutput
        resins={resinsValues}
        isocyanates={isocyanatesValues}
        handleIndex={setIndex}
        handleRatio={setRatio}
        index={index}
        enabled={isEditing}
        download={downloadPDF}
        saved={saved}
      />
    </Content>
  );
};
