import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from './Button.interface';

export const ButtonStyled = styled(Button)<ButtonProps>`
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.buttonMain};
  border-radius: 0px;
  font-weight: 500;
  font-size: ${(props) => props.theme.texts.text.size};

  &.ant-btn-lg {
    height: 60px;
    font-weight: 600;
  }

  &.ant-btn-sm {
    height: 50px;
  }
  &.ant-btn-primary {
    background-color: ${(props) => props.theme.colors.buttonMain};
    color: ${(props) => props.theme.colors.textMain};
    padding: 0.3rem 2rem;
    &:hover {
      opacity: 90%;
    }
    &[disabled] {
      background-color: transparent;
      color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
      border: none;
    }
  }
  &.ant-btn-default {
    background-color: ${(props) => props.theme.colors.buttonSecondary};
    color: ${(props) => props.theme.colors.textSecondary};
    padding: 0.3rem 2rem;
    border: ${(props) => !props.outlined && 'none'};
    &:hover {
      opacity: 90%;
    }
    &[disabled] {
      color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
      border: none;
    }
  }
  &.ant-btn-ghost {
    color: ${(props) => props.theme.colors.textSecondary};
    &[disabled] {
      color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
    }
  }
  &.ant-btn-text {
    color: ${(props) => props.theme.colors.textSecondary};
    &[disabled] {
      color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
    }
    border: 0px;
  }
  &.ant-btn-link {
    color: ${(props) => props.theme.colors.textSecondary};
    &[disabled] {
      color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
    }
  }
  &.ant-btn-ghost {
    color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
    &[disabled] {
      color: ${(props) => props.theme.texts.colors.brand.neutral[60]};
    }
    margin-right: 1rem;
    border: ${(props) => !props.outlined && 'none'};
  }
  &:focus {
    border-color: #40a9ff;
  }
`;
