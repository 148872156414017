import styled from 'styled-components';
import { Layout, Typography } from 'antd';

export const Content = styled(Layout.Content)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  background: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  padding: 50px 50px;
`;

export const NotFoundIcon = styled.img`
  width: 100px;
  opacity: 50%;
  margin: 20px;
`;

export const Center = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Typography.Title)`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
`;

export const Subtitle = styled(Typography.Paragraph)`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
`;
