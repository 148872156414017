import { UserInterface } from '../redux/reducers/interfaces';

export const getNameInitials = (name: string) => {
  const namesArray = name.split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
  return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(
    0,
  )}`;
};

export const isAccountOnline = (user?: UserInterface) => {
  return user?.onlineAccount === 'true';
};
