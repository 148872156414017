import React from 'react';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { Query } from './interfaces';
import { ErrorComponent, SuccessComponent } from './styles';

export const QueryResult: React.FC<Query> = (
  { loading, error, data, errorMessage, successMessage, container, green, children }
) => {
  if (error) {
    return <>
            <ErrorComponent>{errorMessage}</ErrorComponent>
            {children}
           </>;
  }
  if (loading) {
    return <LoadingIndicator container={container} green={green} />;
  }
  if (data) {
    return <SuccessComponent>{successMessage}</SuccessComponent>;
  }
    
  return null;
};
