import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { App } from './app/App';
import {
  addConnectionListeners,
  addLeaveWindowListener,
} from './helpers/checkConnection';

Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

addConnectionListeners();
addLeaveWindowListener();

ReactDOM.render(<App />, document.getElementById('root'));
