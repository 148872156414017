import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Card, Col, Table } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { recentFormulationsColumns } from '../../../constants/tablesColumns';
import {
  getHomeInformation,
  getRecentFormulations,
} from '../../../redux/selectors/user';

const Container = styled.div`
  background: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  padding: 50px 50px;
`;

const LargeText = styled.span`
  font-size: ${(props) => props.theme.texts.largeText.size};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  margin-bottom: 15px;
  display: block;
`;

const HeroTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.texts.heroTitle.size};
  font-weight: ${(props) => props.theme.texts.bold};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
`;

const ArrowRight = styled(ArrowRightOutlined)`
  position: absolute;
  right: 20px;
  bottom: 40px;
  color: ${(props) => props.theme.texts.colors.brand.primaryGreen[60]};
  font-size: 22px;
  cursor: pointer;
`;

export const DashboardPage = () => {
  const navigate = useNavigate();
  const customInformation = useSelector(getHomeInformation);
  const recentFormulations = useSelector(getRecentFormulations);

  return (
    <Container>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card data-cy='custom-cyanates-card'>
            <LargeText>Custom Isocyanates</LargeText>
            <HeroTitle>{customInformation.customIsocyanates}</HeroTitle>
            <ArrowRight onClick={() => navigate('/materials')} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card data-cy='custom-resins-card'>
            <LargeText>Custom Resins</LargeText>
            <HeroTitle>{customInformation.customResins}</HeroTitle>
            <ArrowRight onClick={() => navigate('/materials')} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Card data-cy='custom-formulations-card'>
            <LargeText>Custom Formulations</LargeText>
            <HeroTitle>{customInformation.customFormulations}</HeroTitle>
            <ArrowRight onClick={() => navigate('/formulation')} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Card data-cy='recent-formulations-card' style={{ marginTop: 16 }}>
            <LargeText>Recent Formulations</LargeText>
            <Table
              data-cy='dashboard-recent-formulations'
              rowClassName='row-click'
              onRow={(record: any) => {
                return {
                  onClick: () =>
                    navigate(`/formulation/calculator/${record.key}`),
                };
              }}
              columns={recentFormulationsColumns}
              dataSource={recentFormulations}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
