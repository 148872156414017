export const resinCreateFields = [
  {
    id: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    tooltip: false,
  },
  {
    id: 'ohNumber',
    label: 'OH #',
    type: 'number',
    required: true,
    tooltip: false,
  },
  {
    id: 'noFunctionality',
    label: 'Functionality',
    type: 'number',
    required: true,
    tooltip: false,
  },
  {
    id: 'type',
    label: 'Type',
    type: 'editable-select',
    required: true,
    tooltip: false,
  },
  {
    id: 'comments',
    label: 'Comments',
    type: 'textarea',
    required: false,
    tooltip: false,
  },
];

export const isocyanateCreateFields = [
  {
    id: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    tooltip: false,
  },
  {
    id: 'nco',
    label: 'NCO %',
    type: 'number',
    required: true,
    tooltip: false,
  },
  {
    id: 'noFunctionality',
    label: 'Functionality',
    type: 'number',
    required: true,
    tooltip: false,
  },
  {
    id: 'type',
    label: 'Type',
    type: 'editable-select',
    required: true,
    tooltip: false,
  },
  {
    id: 'comments',
    label: 'Comments',
    type: 'textarea',
    required: false,
    tooltip: false,
  },
];
