import bcrypt from 'bcryptjs';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { message } from 'antd';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import { changePasswordFields } from '../../../constants/changePasswordFields';
import { saveUserChanges } from '../../../redux/actions/user';
import { getSelectedUser } from '../../../redux/selectors/user';
import { resetPassword } from '../../../api/api';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { LoadingIndicator } from '../../../components';
import { QueryResult } from '../../../components/QueryResult/QueryResult';

const Container = styled.div`
  display: flex;
  padding: 1rem 10rem;
  justify-content: center;
  flex: 1 0 100%;
  div {
    min-width: 50%;
  }
  .form {
    width: 100%;
    background-color: #fff;
    padding: 1rem;
  }
  @media (max-width: 1100px) {
    padding: 1rem 3rem 3rem;
  }
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  justify-content: flex-end;
`;

export const ChangePasswordForm = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const dispatch = useDispatch();
  const salt = bcrypt.genSaltSync(10);

  const selectedUser = useSelector(getSelectedUser);
  const isOnlineAccount = isAccountOnline(selectedUser);

  const comparePassword = (value: string | null | undefined) => {
    if (!value || !selectedUser) return false;
    return bcrypt.compareSync(value, selectedUser.password as string);
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(
      'Please Enter your current password',
    ),
    newPassword: Yup.string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        'Must Contain at least 6 Characters with One Uppercase or more and numbers',
      ),
    newPasswordRepeat: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Password confirm is required'),
  });

  const onlineValidationSchema = Yup.object({
    newPassword: Yup.string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
        'Must Contain at least 6 Characters with One Uppercase or more and numbers',
      ),
    newPasswordRepeat: Yup.string()
      .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
      .required('Password confirm is required'),
  });

  const handlePasswordChange = (newPassword: string) => {
    dispatch(saveUserChanges({ password: bcrypt.hashSync(newPassword, salt) }));
  };

  const handlePasswordSubmit = async (values: {
    currentPassword?: string;
    newPassword: string;
    newPasswordRepeat: string;
  }) => {
    if (isOnlineAccount) {
      setIsError(false);
      setErrorMessage(undefined);
      setIsLoading(true);

      const {status, message: responseMessage} = await resetPassword(values.newPassword, undefined, selectedUser?.id);

        if (status === "success") {
          setIsLoading(false);

          message.success({
            content: 'Your password has been changed.',
            style: {
              marginTop: '90vh',
            },
          });
        } else {
          setIsLoading(false);
          setIsError(true);
          setErrorMessage(responseMessage);
        }
    } else {
      handlePasswordChange(values.newPassword);
        message.success({
          content: 'Your password has been changed.',
          style: {
            marginTop: '90vh',
          },
        });
    }
  };
  
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  };

  return (
    <Container data-cy='change-password-container'>
      <div className='form'>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={isOnlineAccount ? onlineValidationSchema : validationSchema}
          onSubmit={async (values, actions) => {
            if (!isOnlineAccount ? comparePassword(values.currentPassword) : true) {
              await handlePasswordSubmit(values);
              actions.resetForm({
                values: initialValues,
              });
            } else {
              actions.setErrors({
                currentPassword: 'Incorrect password',
              });
            }
          }}
        >
          {({
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            values,
          }) => (
            <>
              {(isOnlineAccount ? [...changePasswordFields].slice(1) : changePasswordFields).map(
                (item: {
                  id: string;
                  label: string;
                  type: string;
                  required: boolean;
                }) => {
                  return (
                    <FormFields key={item.id}>
                      <FormInputFields
                        item={item}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        values={values}
                      />
                    </FormFields>
                  );
                },
              )}
              <Buttons>
                <QueryResult
                    error={isError}
                    errorMessage={errorMessage}
                />
                <ButtonStyled
                  data-cy='save-button'
                  type='primary'
                  size='large'
                  onClick={() => handleSubmit()}
                >
                  {isLoading
                    ? <LoadingIndicator container />
                    : 'Save'}
                </ButtonStyled>
              </Buttons>
            </>
          )}
        </Formik>
      </div>
    </Container>
  );
};
