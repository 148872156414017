import React from 'react';
import { TableProps } from 'antd/lib/table';
import { TableDesign } from './GenericTable.styles';

/** ANTD's Table with defaults */
export function GenericTable<T extends object = any>(
  tableProps: TableProps<T>,
) {
  return (
    // @ts-ignore StyledComponents don't preserve generics
    <TableDesign
      dataSource={[] as T[]}
      pagination={{
        position: ['bottomLeft'],
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
      }}
      {...tableProps}
    />
  );
}
