import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const AddFormContainer = styled.div`
  width: 50%;
  height: 80%;
  background-color: #fff;
  padding: 2rem;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export const Heading = styled.div`
  font-size: ${(props) => props.theme.texts.subtitle.size};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  margin-bottom: 1rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  button {
    width: 100%;
    margin-bottom: 8px;
    &.ant-switch {
      width: 30px;
    }
  }
  @media (min-width: 640px) {
    flex-wrap: initial;
    button {
      width: initial;
      margin-bottom: 0px;
      &.ant-switch {
        width: initial;
      }
    }
  }
`;

export const SwitchContainer = styled.div`
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;

export const InternetConnectionStatus = styled.div`
  margin: 1rem auto 0;
  color: ${(props) => props.theme.texts.colors.semantic.error[60]};
`;
