import { message } from 'antd';
import localforage from 'localforage';

export const connectionStatus = async (status: boolean): Promise<void> => {
  await localforage.setItem('connectionStatus', status);
  if (status) {
    message.info({
      content: 'You are connected to the internet.',
      style: { marginTop: '90vh' },
    });
  } else {
    message.info({
      content: 'You are disconnected from the internet.',
      style: { marginTop: '90vh' },
    });
  }
};

export const getConnectionStatus = async (): Promise<boolean> => {
  try {
    return (await localforage.getItem('connectionStatus')) as boolean;
  } catch {
    return false;
  }
};

export const addConnectionListeners = () => {
  window.addEventListener(
    'load',
    () => {
      if (navigator.onLine) {
        connectionStatus(true);
      } else {
        connectionStatus(false);
      }
    },
    false,
  );

  window.addEventListener(
    'online',
    () => {
      connectionStatus(true);
    },
    false,
  );

  window.addEventListener(
    'offline',
    () => {
      connectionStatus(false);
    },
    false,
  );
};

export const addLeaveWindowListener = () => {
  window.addEventListener('beforeunload', (e) => {
    e.preventDefault();
    e.returnValue =
      'Its important to Export your Profile before leaving the application, this can prevent you to loose your data. Are you sure you want to proceed?';
    return e;
  });
};
