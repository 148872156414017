export const changePasswordFields = [
  {
    id: 'currentPassword',
    label: 'Current password',
    type: 'password',
    required: true,
    tooltip: false,
  },
  {
    id: 'newPassword',
    label: 'New password',
    type: 'password',
    required: true,
    tooltip: false,
  },
  {
    id: 'newPasswordRepeat',
    label: 'Repeat new password',
    type: 'password',
    required: true,
    tooltip: false,
  },
];
