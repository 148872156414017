import { Layout } from 'antd';
import { SelectValue } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/Button/Button';
import {
  SelectInputStyled,
  TextFieldInputStyled,
} from '../../../components/Input/Input.styles';
import { getIsocyanates } from '../../../redux/selectors/materials';
import {
  CalculatorIsocyanatesProps,
  IsocyanatesTable,
} from './CalculatorIsocyanates.interface';
import {
  BatchContainer,
  Calculator,
  Container,
  InputLabel,
  RemoveButton,
  Title,
} from './CalculatorIsocyanates.styles';

export const CalculatorIsocyanates = ({
  showExtraColumns,
  handleIsocyanates,
  handleIsoBatchWeight,
  enabled,
  loadedFormulation,
  isFirstLoad,
}: CalculatorIsocyanatesProps) => {
  const TextInputField = TextFieldInputStyled;
  const SelectField = SelectInputStyled;

  const emptyRecord: IsocyanatesTable = {
    key: '0',
    title: '',
    parts: 0,
    nco: 0,
    eqWeight: 0,
    noFunctionality: 0,
    ncoContribution: 0,
    batchWeights: 0,
    matCost: 0,
    calcCost: 0,
    materialType: '',
    owner: '',
    materialKey: '',
  };

  const [dataSource, setDataSource] = useState([emptyRecord]);
  const [total, setTotal] = useState({
    parts: 0,
    batchWeights: 0,
    ncoContribution: 0,
    calcCost: 0,
    totalOfMat: 0,
  });
  const [checkCost, setCheckCost] = useState(false);
  const [isoBatchWeight, setIsoBatchWeight] = useState(1500);
  const materials = useSelector(getIsocyanates);

  const generateIsoOptions = () => {
    const options: any = [];
    materials.map((material) =>
      options.push({ value: material.key, label: material.title }),
    );
    return options;
  };

  const materialList = generateIsoOptions();

  const handleRecordUpdate = (material: SelectValue, index: number) => {
    const selectedMaterial = materials.find((item) => {
      return item.key === material;
    });
    if (selectedMaterial) {
      const {
        title,
        nco,
        eqWeight,
        noFunctionality,
        materialType,
        owner,
        id,
        key,
      } = selectedMaterial;
      const dataSourceCopy = [...dataSource];
      dataSourceCopy[index] = {
        ...dataSourceCopy[index],
        key,
        title,
        nco: Number(nco),
        eqWeight: Number(eqWeight),
        noFunctionality: Number(noFunctionality),
        materialType,
        owner,
        materialKey: (id as string) || key,
      };

      setDataSource(dataSourceCopy);
    }
  };

  const calcTotal = (column: keyof IsocyanatesTable) => {
    return dataSource
      .map((record) => {
        return typeof record[column] !== 'number'
          ? Number(record[column])
          : Number(record[column]) || 0;
      })
      .reduce((a, b) => a + b, 0);
  };

  const updateTotal = () => {
    const parts = calcTotal('parts');
    const batchWeights = calcTotal('batchWeights');
    const ncoContribution = calcTotal('ncoContribution');
    const totalOfMat = calcTotal('matCost');
    setTotal({
      ...total,
      parts,
      ncoContribution,
      batchWeights,
      totalOfMat,
    });
  };

  const updateCost = () => {
    const updatedDataSource = [...dataSource];

    updatedDataSource.forEach((item) => {
      if (
        typeof item.batchWeights === 'number' &&
        typeof item.matCost === 'number'
      )
        item.calcCost = (item.matCost * Number(item.parts)) / total.parts;
    });
    const calcCost = calcTotal('calcCost');

    setDataSource(updatedDataSource);
    setCheckCost(false);
    setTotal({
      ...total,
      calcCost,
    });
  };

  const updateWeights = () => {
    const updatedDataSource = [...dataSource];
    updatedDataSource.forEach((item) => {
      if (item.parts) {
        item.batchWeights = (Number(item.parts) * isoBatchWeight) / total.parts;
      }
      item.ncoContribution = item.nco
        ? (Number(item.parts) * Number(item.nco)) / total.parts
        : 0;
    });
    setDataSource(updatedDataSource);
    setCheckCost(false);
  };

  useEffect(() => {
    if (dataSource) {
      updateTotal();
      if (checkCost) {
        updateCost();
      }
      const materialsList = dataSource.filter((x) => x.title !== 'addMaterial');
      handleIsocyanates(materialsList);
    }
  }, [dataSource]);

  useEffect(() => {
    updateWeights();
    updateCost();
  }, [isoBatchWeight, total.parts]);

  const handleRemoveMaterial = (index: number) => {
    const updatedDataSource = [...dataSource];
    updatedDataSource.splice(index, 1);
    setDataSource(updatedDataSource);
  };

  const handlePartsCalculation = (index: number, e: any) => {
    const parts = Number(e.target.value);
    const dataSourceCopy = [...dataSource];
    dataSourceCopy[index] = {
      ...dataSourceCopy[index],
      parts,
    };
    setDataSource(dataSourceCopy);
    setCheckCost(true);
  };

  const handleCostCalculation = (index: number, e: any) => {
    const matCost = Number(e.target.value);
    const dataSourceCopy = [...dataSource];
    dataSourceCopy[index] = {
      ...dataSourceCopy[index],
      matCost,
    };
    setDataSource(dataSourceCopy);
    setCheckCost(true);
  };

  const handleAddMaterial = () => {
    const updatedDataSource = [...dataSource];
    setDataSource([
      ...updatedDataSource,
      { ...emptyRecord, key: String(dataSource.length + 1) },
    ]);
  };
  useEffect(() => {
    if (loadedFormulation && isFirstLoad) {
      setDataSource(loadedFormulation.materials.isocyanates);
      setIsoBatchWeight(loadedFormulation.isoBatchWeight);
    }
  }, [loadedFormulation]);

  const columns = [
    {
      title: 'Material',
      dataIndex: 'title',
      render: (title: string, record: any, index: number) => {
        return enabled ? (
          <SelectField
            showSearch
            options={materialList}
            defaultValue={title}
            data-cy='isocyanates-material-selection'
            onChange={(value) =>
              handleRecordUpdate(value as SelectValue, index)
            }
            optionFilterProp='label'
            filterOption={(input, option) => {
              if (option) {
                if (option.label) {
                  return (
                    (option.label as string)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) !== -1
                  );
                }
              }
              return true;
            }}
          />
        ) : (
          <div>{title}</div>
        );
      },
    },
    {
      title: 'Parts',
      dataIndex: 'parts',
      render: (parts: number | string, record: any, index: number) =>
        enabled ? (
          <TextInputField
            min='0'
            type='number'
            data-cy='isocyanates-parts-input'
            defaultValue={parts}
            value={record.parts}
            onChange={(e) => handlePartsCalculation(index, e)}
            disabled={!dataSource[index].title}
          />
        ) : (
          <div>{parts}</div>
        ),
    },
    {
      title: 'NCO %',
      dataIndex: 'nco',
      render: (nco: number, record: any) => record.key !== 'total' && nco,
    },
    {
      title: 'Eq. Wt.',
      dataIndex: 'eqWeight',
      render: (eqWeight: number, record: any) =>
        record.key !== 'total' && eqWeight && Number(eqWeight).toFixed(2),
    },
    {
      title: 'Functionality',
      dataIndex: 'noFunctionality',
      render: (noFunctionality: number, record: any) =>
        record.key !== 'total' && noFunctionality,
    },
    {
      title: 'NCO Contribution',
      dataIndex: 'ncoContribution',
      render: (ncoContribution: number, record: any) => {
        let result = 0;
        if (record.nco && record.parts) {
          result =
            (Number(record.nco) * Number(record.parts)) / Number(total.parts);
        }
        return result.toFixed(2);
      },
    },
    {
      title: 'Batch Weights',
      dataIndex: 'batchWeights',
      render: (batchWeights: number, record: any) => {
        let result = 0;
        if (record.parts)
          result =
            (Number(isoBatchWeight) * Number(record.parts)) / total.parts;
        return result.toFixed(2) || 0;
      },
    },
    {
      title: 'Raw Mat. Cost $/Lb',
      dataIndex: 'matCost',
      className: showExtraColumns ? 'visible' : 'hidden',
      render: (matCost: number | string, record: any, index: number) =>
        enabled && record.key !== 'total' ? (
          <TextInputField
            min='0'
            prefix='$'
            type='number'
            data-cy='isocyanates-material-cost'
            disabled={!dataSource[index].title}
            defaultValue={matCost}
            onChange={(e) => handleCostCalculation(index, e)}
          />
        ) : (
          <div>{matCost}</div>
        ),
    },
    {
      title: 'Blend Cost $/Lb',
      dataIndex: 'calcCost',
      className: showExtraColumns ? 'visible' : 'hidden',
      render: (calcCost: number | string, record: any) => {
        let result = 0;
        if (record.matCost && record.parts)
          result = (record.matCost * record.parts) / total.parts;
        return `$ ${result.toFixed(2)}`;
      },
    },
    {
      title: '',
      dataIndex: 'remove',
      render: (remove: string, record: any, index: number) =>
        enabled && (
          <span
            data-cy='isocyanates-remove-action'
            onClick={() => handleRemoveMaterial(index)}
          >
            <RemoveButton />
          </span>
        ),
    },
  ];

  const { Content } = Layout;

  return (
    <Content>
      <Container>
        <Title>Isocyanate Blend</Title>
        <Calculator
          data-cy='isocyanates-blend-material-selection'
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          summary={() => {
            return (
              <>
                <Calculator.Summary.Row>
                  <Calculator.Summary.Cell index={1}>
                    {enabled && (
                      <Button type='text' onClick={handleAddMaterial}>
                        + Add Material
                      </Button>
                    )}
                  </Calculator.Summary.Cell>
                  <Calculator.Summary.Cell index={2}>
                    {total.parts}
                  </Calculator.Summary.Cell>
                  <Calculator.Summary.Cell index={3} />
                  <Calculator.Summary.Cell index={4} />
                  <Calculator.Summary.Cell index={5} />
                  <Calculator.Summary.Cell index={6}>
                    {total.ncoContribution.toFixed(2)}
                  </Calculator.Summary.Cell>
                  <Calculator.Summary.Cell index={7}>
                    {total.batchWeights.toFixed(2)}
                  </Calculator.Summary.Cell>
                  <Calculator.Summary.Cell
                    index={8}
                    className={showExtraColumns ? 'visible' : 'hidden'}
                  >
                    $ {total.totalOfMat.toFixed(2)}
                  </Calculator.Summary.Cell>
                  <Calculator.Summary.Cell
                    index={9}
                    className={showExtraColumns ? 'visible' : 'hidden'}
                  >
                    $ {total.calcCost.toFixed(2)}
                  </Calculator.Summary.Cell>
                  <Calculator.Summary.Cell index={8} />
                </Calculator.Summary.Row>
              </>
            );
          }}
        />
        <BatchContainer>
          <InputLabel>Iso Batch Weight</InputLabel>
          {enabled ? (
            <TextInputField
              type='number'
              value={isoBatchWeight}
              defaultValue={
                loadedFormulation
                  ? loadedFormulation.isoBatchWeight
                  : isoBatchWeight
              }
              min={0}
              data-cy='isocyanates-batch-weight'
              onChange={(e) => {
                setIsoBatchWeight(Number(e.target.value));
                handleIsoBatchWeight(Number(e.target.value));
              }}
            />
          ) : (
            <span>{isoBatchWeight}</span>
          )}
        </BatchContainer>
      </Container>
    </Content>
  );
};
