import { useState, useEffect } from 'react';
import moment from 'moment';
import { Comparators, Filter, FiltersMap } from './TableFilters.interface';

const comparators: Comparators = {
  default: (value1: string, value2: string) => {
    return value1.toLowerCase().includes(value2);
  },
  date: (value1: string, value2: string) => {
    const date1 = moment.unix(Number.parseInt(value1, 10) / 1000);
    const date2 = moment.unix(Number.parseInt(value2, 10));

    return date1.isSame(date2, 'date');
  },
};

export function useDataSource<T>(initialDataSource: T[]) {
  const [dataSource, setDataSource] = useState(initialDataSource);

  useEffect(() => setDataSource(initialDataSource), [initialDataSource]);

  const updateDataSource = (newDataSource: T[]) => {
    setDataSource(newDataSource);
  };

  const applyFiltersToTable = (
    filters: FiltersMap,
    filtersList: Filter<T>[],
  ) => {
    const newDataSource = [
      ...initialDataSource.filter((entry) => {
        const matchingFilters = filtersList.filter((filter) => {
          const filterValue = filters[filter.key];
          if (!filterValue) return true;

          // At least one filter in column must match
          return filter.columns.find((field) => {
            const entryValue = entry[field.field];
            if (!entryValue || !filterValue) return false;

            const filterValueFormatted = filterValue.toLowerCase();

            // Use custom comparator if provided
            const comparator =
              field.comparator ||
              comparators[filter.type] ||
              comparators.default;

            return comparator(entryValue, filterValueFormatted);
          });
        });

        // All filters must match
        return matchingFilters.length === filtersList.length;
      }),
    ];

    setDataSource(newDataSource);
  };

  return { dataSource, applyFiltersToTable, updateDataSource };
}
