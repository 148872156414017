import React from 'react';
import { Layout } from 'antd';
import { TourPage } from './components/TourPage';
import { DashboardPage } from './components/DashboardPage';
import { HomePageProps } from './HomePage.interface';

export const HomePage = ({
  tour,
  dashboard,
}: HomePageProps): React.ReactElement => {
  const { Content } = Layout;
  const renderContent = (): React.ReactElement => {
    if (tour) return <TourPage />;
    if (dashboard) return <DashboardPage />;
    return <DashboardPage />;
  };

  return <Content data-cy='homePage-content'>{renderContent()}</Content>;
};
