import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import bcrypt from 'bcryptjs';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import { isUserAuthenticated } from '../../../redux/actions/app';
import { Container, Buttons } from './KeywordEntry.styles';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import {
  saveUserChanges,
  updateSelectedUser,
} from '../../../redux/actions/user';
import { resetPassword } from '../../../api/api';
import { ErrorMessage } from './AccountLogin.styles';
import { LoadingIndicator } from '../../../components';

interface Values {
  newPassword: string;
  newPasswordConfirm: string;
}

interface Props {
  token?: string | undefined;
}

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Must Contain at least 6 Characters with One Uppercase or more and numbers',
    ),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
    .required('Password confirm is required'),
});

const initialValues: Values = {
  newPassword: '',
  newPasswordConfirm: '',
};

export const ResetPassword = ({token}: Props): React.ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const salt = bcrypt.genSaltSync(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (errorMessage) setIsLoading(false);
  }, [errorMessage]);
  
  const handleSubmitPassword = async (values: Values) => {
    const isNewPasswordValid = values.newPassword === values.newPasswordConfirm;
    
    if (isNewPasswordValid) {
      setIsLoading(true);

      if (token) {
        setErrorMessage(undefined);
        
        const { status, message, error } = await resetPassword(values.newPassword, token);

        if (status === "fail" || status === "error" || error) {
          return setErrorMessage(error && status === undefined
            ? 'No internet connection! Try again later.'
            : message
          ); 
        }

        return navigate('/', {state: {passwordChanged: true}});
      }
      
      const newPasswordHash = bcrypt.hashSync(values.newPassword, salt);
      
      dispatch(saveUserChanges({ password: newPasswordHash }));
      dispatch(updateSelectedUser());
      dispatch(isUserAuthenticated(true));
      navigate('/main');
    }
  };

  return (
    <Container>
      <SectionHeading label='Update password' reset>
        {errorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage> 
        )}
      </SectionHeading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitPassword}
      >
        {({
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          values,
        }) => (
          <Form>
            <FormInputFields
              item={{
                id: 'newPassword',
                label: 'New password',
                type: 'password',
                required: true,
              }}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              placeholder={false}
              values={values}
              autoFocus
            />
            <FormInputFields
              item={{
                id: 'newPasswordConfirm',
                label: 'Confirm new password',
                type: 'password',
                required: true,
              }}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              placeholder={false}
              values={values}
            />
            <Buttons content='flex-end'>
              <ButtonStyled
                data-cy='change-password-button'
                type='primary'
                htmlType='submit'
                onClick={() => handleSubmit()}
              > 
                {isLoading 
                  ? <LoadingIndicator container /> 
                  : 'Change password'}
              </ButtonStyled>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
