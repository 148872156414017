import { message } from "antd";
import { NotificationDetails } from "../components/Modals/ImportModal/ImportModal.interface";

export const sendNotification = (level: string, {text, names, notificationStyle, duration}: NotificationDetails) => {
  const style = !notificationStyle ? { marginTop: '90vh' } : notificationStyle;
  const messageContent = {
    content: names ? `${text}: ${names}` : text,
    style: style
  };

  switch(level) {
    case 'error':
      message.error(messageContent, duration);
      break;
    case 'warn':
      message.warn(messageContent, duration);
      break;
    case 'info':
      message.info(messageContent, duration);
      break;
    case 'success':
      message.success(messageContent, duration);
      break;
  }
};