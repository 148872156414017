import { EditOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.texts.colors.brand.neutral[20]};
  padding: 50px 50px;

  .ant-row.formulationButtonRow {
    justify-content: center;
    button {
      margin-top: 10px;
      &::first-child {
        margin-top: 0px;
      }
    }
    @media (min-width: 640px) {
      justify-content: flex-end;
    }
  }
`;

export const EditOutlinedIcon = styled(EditOutlined)`
  font-size: 18px;
  padding-right: 10px;
  color: silver;
`;

export const CopyOutlinedIcon = styled(CopyOutlined)`
  font-size: 18px;
  padding-right: 10px;
  color: silver;
`;

export const DeleteOutlinedIcon = styled(DeleteOutlined)`
  font-size: 18px;
  padding-right: 10px;
  color: silver;
`;
