import styled from 'styled-components';
import { Table } from 'antd';

export const TableDesign = styled(Table)`
  a {
    color: ${(props) => props.theme.texts.colors.brand.primaryGreen[80]};
  }
  ul.ant-pagination {
    background: white;
    padding: 16px;
    width: 100%;
    margin: 0px;

    .ant-pagination-item-active {
      border-color: ${(props) =>
        props.theme.texts.colors.brand.primaryGreen[80]};
    }
  }
`;
