import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import * as Yup from 'yup';
import { ButtonStyled } from '../../../components/Button/Button.styles';
import { FormInputFields } from '../../../components/FormInputFields/FormInputFields';
import { SectionHeading } from '../../../components/SectionHeading/SectionHeading';
import {
  authenticateUser,
  isUserAuthenticated,
} from '../../../redux/actions/app';
import LeftArrow from '../../../resources/arrow-left.svg';
import { AccountLoginProps } from './AccountLogin.interfaces';
import {
  Container,
  UserHeading,
  ArrowIcon,
  Buttons,
  ForgotPassword,
  ErrorMessage,
  EmailSent,
} from './AccountLogin.styles';
import { loginUser, sendResetLink } from '../../../api/api';
import { LoadingIndicator } from '../../../components/LoadingIndicator/LoadingIndicator';
import { updateFormulationsInDB } from '../../../redux/actions/formulation';
import { modifyDBFormulations } from '../../../helpers/modifyDbFormulations';
import { PasswordReset } from './KeywordEntry.styles';

const options = [
  {
    id: 'email',
    label: 'Email',
    type: 'text',
    required: true,
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
    required: true,
  },
];

const email = {
  id: 'passwordResetEmail',
  label: 'Email',
  type: 'text',
  required: true,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Invalid email address')
    .required('* Required'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'Must Contain at least 6 Characters with One Uppercase or more and numbers',
    ),
});

const resetValidationSchema = Yup.object({
  passwordResetEmail: Yup.string()
    .trim()
    .email('Invalid email address')
    .required('* Required'),
});

export const AccountLogin = ({
  setOnlineAccount,
  setAccountSetup,
}: AccountLoginProps): React.ReactElement => {
  const [isForgottenPassword, setIsForgottenPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLogin, setIsLogin] = useState(false);
  const [isResetLinkSent, setIsResetLinkSent] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage || isResetLinkSent) setIsLogin(false);
    if (isResetLinkSent) setErrorMessage(undefined);
  }, [errorMessage, isResetLinkSent]);

  const handleLogin = async (values: { email: string; password: string }) => {
    setErrorMessage(undefined);
    setIsLogin(true);

    const {
      user: fetchedUser,
      token,
      status,
      error,
    } = await loginUser(values.email, values.password);

    if (status === 'error' || error) {
      return setErrorMessage(
        status === 'error'
          ? 'No database connection! Try again later.'
          : 'No internet connection! Try again later.',
      );
    }
    if (!fetchedUser) {
      return setErrorMessage('Invalid login details!');
    }

    dispatch(authenticateUser(fetchedUser, token));
    const newFormulations = modifyDBFormulations(
      fetchedUser.formulations,
      fetchedUser.email,
    );
    dispatch(updateFormulationsInDB(newFormulations));
    ReactGA.set({ userId: fetchedUser.id });
    navigate('/main');
    dispatch(isUserAuthenticated(true));
  };

  const handleSendResetLink = async (values: {
    passwordResetEmail: string;
  }) => {
    setErrorMessage(undefined);
    setIsLogin(true);
    setIsResetLinkSent(false);

    const { status, message, error } = await sendResetLink(
      values.passwordResetEmail,
    );

    if (status === 'fail' || status === 'error' || error) {
      return setErrorMessage(
        error && status === undefined
          ? 'No internet connection! Try again later.'
          : message,
      );
    }

    setIsResetLinkSent(true);

    setTimeout(() => {
      setIsResetLinkSent(false);
    }, 5000);
  };

  return (
    <Container>
      <UserHeading
        data-cy='selected-user'
        onClick={() => {
          setOnlineAccount(false);
          setAccountSetup(false);
        }}
      >
        <ArrowIcon src={LeftArrow} alt='Go back' />
        <SectionHeading label='Login' />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {isResetLinkSent && (
          <EmailSent>Your reset link is waiting in your mailbox.</EmailSent>
        )}
      </UserHeading>
      <Formik
        initialValues={{ email: '', password: '', passwordResetEmail: '' }}
        validationSchema={
          isForgottenPassword ? resetValidationSchema : validationSchema
        }
        onSubmit={(values) =>
          isForgottenPassword
            ? handleSendResetLink(values)
            : handleLogin(values)
        }
      >
        {({
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          values,
        }) => (
          <Form>
            {!isForgottenPassword ? (
              options.map((item: any, index: number) => (
                <FormInputFields
                  key={index}
                  item={item}
                  tooltip={item.tooltip}
                  text={item?.text}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                  placeholder={item.placeholder}
                  autoFocus={index === 0}
                />
              ))
            ) : (
              <>
                <PasswordReset>
                  Type in your e-mail and we will send you a link to change your
                  password.
                </PasswordReset>
                <FormInputFields
                  item={email}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  placeholder={false}
                  values={values}
                  autoFocus
                />
              </>
            )}

            <Buttons
              content={isForgottenPassword ? 'flex-end' : 'space-between'}
            >
              {!isForgottenPassword && (
                <ForgotPassword
                  data-cy='forget-password-action'
                  onClick={() => {
                    if (errorMessage) setErrorMessage(undefined);
                    setIsForgottenPassword(true);
                  }}
                >
                  Forgot your password?
                </ForgotPassword>
              )}
              <ButtonStyled
                data-cy={
                  isForgottenPassword
                    ? 'send-reset-link-button'
                    : 'login-button'
                }
                type='primary'
                onClick={() => !isLogin && handleSubmit()}
              >
                {isLogin ? (
                  <LoadingIndicator container />
                ) : isForgottenPassword ? (
                  'Send'
                ) : (
                  'Log in'
                )}
              </ButtonStyled>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
