import { v4 as uuid } from 'uuid';
import { ENDPOINT } from '../api/api';
import {
  IsocyanateInterface,
  MaterialInterface,
  MaterialResponseInterface,
  ResinInterface,
} from '../redux/reducers/interfaces';
import BASFProducts from '../resources/products.json';

export const isResin = (
  material: MaterialInterface,
): material is ResinInterface => material.materialType === 'Resins';

export const getBasfMaterials = async () => {
  let materials: MaterialInterface[] = [];
  await fetch(ENDPOINT + '/materials/basf') // TODO: add the correct endpoint
    .then((response) => response.json())
    .then((json) => {
      if (json.data.materials) {
        materials = json.data.materials.map((x: MaterialResponseInterface) => {
          if (isResin(x)) {
            return {
              ...x,
              type: x.type.value,
              materialType: 'Resins',
              owner: 'BASF',
              ohNumber: x.ohNumber,
              eqWeight: x.ohNumber && 56100 / Number(x.ohNumber),
            };
          }
          return {
            ...x,
            type: x.type.value,
            materialType: 'Isocyanates',
            owner: 'BASF',
            nco: x.nco,
            eqWeight: x.nco && 4202 / Number(x.nco),
          };
        });
      }
    })
    .catch(() => {
      materials = (BASFProducts.data as any).map((x: MaterialInterface) => {
        if ((x as ResinInterface).ohNumber) {
          return {
            ...x,
            key: uuid(),
            materialType: 'Resins',
            owner: 'BASF',
            ohNumber: (x as ResinInterface).ohNumber,
            eqWeight:
              (x as ResinInterface).ohNumber &&
              56100 / Number((x as ResinInterface).ohNumber!),
          };
        }
        return {
          ...x,
          key: uuid(),
          materialType: 'Isocyanates',
          owner: 'BASF',
          nco: (x as IsocyanateInterface).nco,
          eqWeight:
            (x as IsocyanateInterface).nco &&
            4202 / Number((x as IsocyanateInterface).nco!),
        };
      });
    });
  return materials;
};
