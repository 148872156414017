import { LabeledValue } from 'antd/lib/select';
import React, { useEffect } from 'react';
import moment from 'moment';
import { Button } from '../Button/Button';
import {
  DatePickerStyled,
  SelectInputStyled,
  TextFieldInputStyled,
} from '../Input/Input.styles';
import { Filter, FiltersProps } from './TableFilters.interface';
import {
  Container,
  SearchIcon,
  CalendarIcon,
  SpaceStretch,
  CloseCircleIcon,
} from './TableFilters.styles';

export function TableFilters<T>({
  applyFiltersToTable,
  filtersList,
  filters = {},
  changeFilters,
  clearFilters,
  searchWithFilter,
}: FiltersProps<T>) {
  useEffect(() => {
    applyFiltersToTable(filters, filtersList);
  }, [filters]);

  const getFilterComponent = (filter: Filter<T>) => {
    let field;
    const filterValue = filters[filter.key];

    switch (filter.type) {
      case 'search':
        field = (
          <TextFieldInputStyled
            data-cy={`filter_${filter.type}_${filter.key}`}
            key={`${filter.type}_${filter.key}`}
            placeholder={filter.placeholder}
            prefix={<SearchIcon />}
            value={filterValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              searchWithFilter(e, filter);
            }}
          />
        );
        break;
      case 'select':
        field = (
          <SelectInputStyled
            data-cy={`filter_${filter.type}_${filter.key}`}
            showSearch
            placeholder={filter.placeholder}
            key={`${filter.type}_${filter.key}`}
            options={filter.options}
            labelInValue
            value={filter.options?.find(
              (option) => option.value === filterValue || '',
            )}
            onChange={(labeledValue) => {
              changeFilters((labeledValue as LabeledValue).value, filter.key);
            }}
          />
        );
        break;
      case 'date':
        field = (
          <DatePickerStyled
            data-cy={`filter_${filter.type}_${filter.key}`}
            suffixIcon={<CalendarIcon />}
            clearIcon={<CloseCircleIcon />}
            placeholder={filter.placeholder}
            key={`${filter.type}_${filter.key}`}
            value={
              filterValue
                ? moment.unix(Number.parseInt(filterValue, 10))
                : undefined
            }
            onChange={(date) => {
              changeFilters(date ? date.unix() : undefined, filter.key);
            }}
          />
        );
        break;
      default: {
        break;
      }
    }
    return field;
  };

  return (
    <Container>
      <SpaceStretch size='large'>
        {filtersList.map((filter) => getFilterComponent(filter))}
      </SpaceStretch>
      <Button type='ghost' size='large' onClick={clearFilters}>
        Clear all
      </Button>
    </Container>
  );
}
