import React, { useState } from 'react';
import { Menu, Avatar, Dropdown } from 'antd';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import styled from 'styled-components';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { MenuMobile, Nav, NavLogo, NavUser } from './Navigation.styles';
import { getNameInitials, isAccountOnline } from '../../helpers/nameInitials';
import {
  addCustomMaterial,
  addUser,
  logoutUser,
} from '../../redux/actions/app';
import BASFLogo from './resources/BASF-logo-full.svg';
import MaterialsIcon from './resources/materials.svg';
import FormulatorIcon from './resources/formulations.svg';
import { getSelectedUser } from '../../redux/selectors/user';
import { getFormulations } from '../../redux/selectors/formulations';
import {
  FormulationInterface,
  UserInterface,
} from '../../redux/reducers/interfaces';
import { downloadJSON, uploadFile } from '../../helpers/hooks';
import { ImportUserModal } from '../Modals/ImportUserModal/ImportUserModal';
import { UserUploadProps } from '../Modals/ImportUserModal/ImportUserModal.interface';
import { addSingleFormulation } from '../../redux/actions/formulation';
import { showConfirmationDialog } from '../../redux/actions/ui';
import { sendNotification } from '../../helpers/sendNotification';
import CloudIcon from '../../resources/cloud-icon.svg';
import QuestionIcon from '../../resources/question.svg';

const UserContainer = styled.div`
  position: relative;
`;

const CloudIconContainer = styled.img`
  width: 10px;
  position: absolute;
  top: 15px;
  left: 3px;
  opacity: 0.3;
`;

const CustomNavLink = styled(NavLink)`
  z-index: 5;
  font-weight: 600;
  color: black;
  opacity: 50%;
  &:hover {
    border-bottom: 2px solid
      ${(props) => props.theme.texts.colors.brand.primaryGreen[60]} !important;
  }
  &.active {
    opacity: 100%;
    color: ${(props) =>
      props.theme.texts.colors.brand.primaryGreen[60]} !important;
  }
`;

export const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profileUpload, setProfileUpload] = useState<UserUploadProps>();

  const selectedUser = useSelector(getSelectedUser);
  const isOnlineAccount = isAccountOnline(selectedUser);
  const formulations = useSelector(getFormulations);

  const validationSchema = Yup.object({
    profile: Yup.object().required('reject'),
  });

  const handleUpload = () => {
    uploadFile(setProfileUpload);
  };

  const handleAddUser = () => {
    let materialsCountErrors = 0;
    let materialsCount = 0;
    let formulationsCountErrors = 0;
    let formulationsCount = 0;

    if (
      profileUpload?.profile.fullName !== selectedUser?.fullName &&
      profileUpload?.profile.email !== selectedUser?.email
    ) {
      dispatch(addUser(profileUpload?.profile as UserInterface));
    }
    if (profileUpload?.profile.materials) {
      profileUpload.profile.materials.forEach((x) => {
        if (selectedUser?.materials.some((j) => j.key === x.key)) {
          materialsCountErrors += 1;
        } else {
          dispatch(addCustomMaterial(x));
          materialsCount += 1;
        }
      });
    }
    if (profileUpload?.formulations) {
      profileUpload.formulations.forEach((x) => {
        if (selectedUser?.formulations.includes(x.key)) {
          formulationsCountErrors += 1;
        } else {
          dispatch(addSingleFormulation(x));
          formulationsCount += 1;
        }
      });
    }
    let text = `Number of Materials: ${profileUpload?.profile.materials.length} | Errors: ${materialsCountErrors} | Completed: ${materialsCount}`;
    const duration = 2.5;
    sendNotification('info', { text, duration });

    setTimeout(() => {
      text = `Number of Formulations: ${profileUpload?.profile.formulations.length} | Errors: ${formulationsCountErrors} | Completed: ${formulationsCount}`;
      sendNotification('info', { text, duration });
    }, duration * 1000);

    setProfileUpload(undefined);
  };

  const exportUserProfile = () => {
    const userObject: {
      profile?: UserInterface;
      formulations?: FormulationInterface[];
    } = { profile: undefined, formulations: undefined };

    userObject.profile = selectedUser;
    userObject.formulations = formulations;

    dispatch(
      showConfirmationDialog({
        content:
          'This will export all your information (profile, formulations and materials) to an external file.',
        title: 'Confirm Action',
        onOk: () =>
          downloadJSON(
            userObject,
            `export-${selectedUser?.fullName.trim().split(' ').join('-')}-${
              new Date().getMonth() + 1
            }-${new Date().getDay()}-${new Date().getFullYear()}`,
          ),
        onCancel: () => {},
      }),
    );
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const menu = (
    <Menu>
      <Menu.Item
        key='0'
        className='cyAttr'
        data-cy='dropdown-navigation-viewprofile'
      >
        <Link to='/me'>View Profile</Link>
      </Menu.Item>
      <Menu.Item
        key='1'
        className='cyAttr'
        data-cy='dropdown-navigation-apptour'
      >
        <Link to='/main/tour'>App tour</Link>
      </Menu.Item>
      {!isOnlineAccount && (
        <>
          <Menu.Item
            key='2'
            className='cyAttr'
            data-cy='dropdown-navigation-apptour'
            onClick={() => exportUserProfile()}
          >
            Export Profile
          </Menu.Item>
          <Menu.Item
            key='3'
            className='cyAttr'
            data-cy='dropdown-navigation-apptour'
            onClick={() => handleUpload()}
          >
            Import Profile
          </Menu.Item>
        </>
      )}
      <Menu.Item
        onClick={handleLogout}
        key='4'
        className='cyAttr'
        data-cy='dropdown-navigation-logout'
      >
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Nav>
        <NavLogo data-cy='header-logo'>
          <Link to='/main'>
            <figure>
              <img src={BASFLogo} alt='BASF Logo' />
              <figcaption>OttoKalc™</figcaption>
            </figure>
          </Link>
        </NavLogo>
        <MenuMobile mode='horizontal'>
          <Menu.Item key='/materials' data-cy='header-menu-materials'>
            <NavLink to='/materials'>
              <img src={`${MaterialsIcon}`} alt='Menu - Materials' /> Materials
            </NavLink>
          </Menu.Item>
          <Menu.Item key='/formulation' data-cy='header-menu-formulator'>
            <NavLink to='/formulation'>
              <img src={`${FormulatorIcon}`} alt='Menu - Formulator' />{' '}
              Formulator
            </NavLink>
          </Menu.Item>
        </MenuMobile>
        <Dropdown
          className='mobileMenu'
          overlay={() => (
            <Menu mode='vertical'>
              <Menu.Item key='/materials' data-cy='header-menu-materials'>
                <NavLink to='/materials'>
                  <img src={`${MaterialsIcon}`} alt='Menu - Materials' />
                  Materials
                </NavLink>
              </Menu.Item>
              <Menu.Item key='/formulation' data-cy='header-menu-formulator'>
                <NavLink to='/formulation'>
                  <img src={`${FormulatorIcon}`} alt='Menu - Formulator' />
                  Formulator
                </NavLink>
              </Menu.Item>
            </Menu>
          )}
        >
          <div>
            <MenuOutlined />
          </div>
        </Dropdown>
        <NavUser data-cy='header-user'>
          <CustomNavLink to='/faq' style={{ opacity: 1 }}>
            <div
              style={{
                display: 'flex',
                gap: '5px',
                minWidth: 65,
                alignItems: 'center',
              }}
            >
              <img src={QuestionIcon} style={{ width: 25, height: 25 }} />
              FAQ
            </div>
          </CustomNavLink>
          {selectedUser && (
            <Dropdown overlay={menu}>
              <UserContainer>
                <Avatar
                  style={{
                    verticalAlign: 'middle',
                    backgroundColor: selectedUser.color,
                  }}
                  size='large'
                  shape='square'
                >
                  {selectedUser?.fullName &&
                    getNameInitials(selectedUser?.fullName)}
                </Avatar>
                <span className='userFullName'>
                  {selectedUser?.fullName} <DownOutlined />
                </span>
                {isAccountOnline(selectedUser) && (
                  <CloudIconContainer src={CloudIcon} alt='cloud' />
                )}
              </UserContainer>
            </Dropdown>
          )}
        </NavUser>
      </Nav>
      <ImportUserModal
        data={profileUpload}
        validationSchema={validationSchema}
        handleImport={handleAddUser}
        onOk={() => setProfileUpload(undefined)}
        onCancel={() => setProfileUpload(undefined)}
      />
    </>
  );
};
