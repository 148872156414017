import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';
import { Row, Col, Carousel, Space } from 'antd';
import { useSelector } from 'react-redux';
import VideoOne from '../../../resources/tour-1-video.mp4';
import VideoTwo from '../../../resources/tour-2-video.mp4';
import VideoThree from '../../../resources/tour-3-video.mp4';
import { Button } from '../../../components/Button/Button';
import { isAccountOnline } from '../../../helpers/nameInitials';
import { getSelectedUser } from '../../../redux/selectors/user';
import { CarouselRef } from 'antd/lib/carousel';
import { LoadingIndicator } from '../../../components';

const Container = styled.div`
  padding: 50px 50px;
  background: white;
`;

const Subtitle = styled.span`
  font-size: ${(props) => props.theme.texts.subtitle.size};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-weight: ${(props) => props.theme.texts.bold};
  margin-bottom: 24px;
  display: block;
`;

const Title = styled.span`
  font-size: ${(props) => props.theme.texts.layoutTitle.size};
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-weight: ${(props) => props.theme.texts.bold};
`;

const TextParagraph = styled.p`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-size: ${(props) => props.theme.texts.smallText.size};
`;

const TextParagraphWarning = styled.p`
  text-align: center;
  color: #ff6262;
  font-size: ${(props) => props.theme.texts.smallText.size};
  border: 1px solid #ff6262;
  padding: 10px 5px;
`;

const CustomCarousel = styled(Carousel)`
  .slick-dots {
    visibility: hidden;
  }
`;

export const TourPage = () => {
  const user = useSelector(getSelectedUser);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carouselNumber, setCarouselNumber] = useState(0);
  const carousel = useRef<CarouselRef>(null);

  const nextSlide = () => {
    setCarouselNumber(carouselNumber + 1);
    (carousel.current as CarouselRef).next();
  };
  const previousSlide = () => {
    setCarouselNumber(carouselNumber - 1);
    (carousel.current as CarouselRef).prev();
  };

  const handleSkipDone = () => {
    setCarouselNumber(0);
    setRedirect(true);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  if (redirect) return <Navigate replace to='/main/dashboard' />;
  if (loading) return <LoadingIndicator />;

  return (
    <Container>
      <Title
        data-cy='tour-title'
        style={{ display: carouselNumber === 0 ? 'none' : 'block' }}
      >
        Application Tour
      </Title>
      <CustomCarousel data-cy='carousel' effect='fade' ref={carousel}>
        {!isAccountOnline(user) && (
          <div>
            <Row>
              <Col xs={24} md={{ span: 12, offset: 6 }}>
                <Subtitle data-cy='material-tour'>Important</Subtitle>
                <TextParagraphWarning>
                  This application uses your browser storage as a database for
                  all the information that you create and may be erased when
                  performing a cache/data cleaning.
                </TextParagraphWarning>

                <TextParagraph>
                  All the formulations, materials and profile information are
                  stored using your browser database. This works as a local
                  storage and this information is never shared with anyone.
                  <br /> The only way to share information is by using the
                  provided Export and Import functions across the application.
                  <br />
                  <br />
                  <b>
                    It is recommended that you Export and save your
                    profile&apos;s information after every use.
                  </b>
                  <br />
                  <br />
                  To do this you only need to place the mouse over your name on
                  the top right corner and click on Export Profile.
                  <br />
                  You can import all your data using the Import action found at
                  the same place as the Export. You may also find the Import
                  action at the start of the application if there is no account
                  created.
                  <br />
                  <b>
                    You will need your password to complete the Import action.
                  </b>
                </TextParagraph>
                <Space align='end'>
                  <Button
                    dataCy='skip-button'
                    size='large'
                    className='cyAttr'
                    onClick={() => handleSkipDone()}
                  >
                    Skip Tour
                  </Button>
                  <Button
                    dataCy='next-button'
                    type='primary'
                    size='large'
                    className='cyAttr'
                    onClick={() => nextSlide()}
                  >
                    Start Tour
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        )}
        <div>
          <Row>
            <Col xs={24} lg={16}>
              <video width='100%' height='100%' controls>
                <source src={VideoOne} type='video/mp4' />
                <track hidden />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col xs={23} lg={7} offset={1}>
              <Subtitle data-cy='material-tour'>Material list</Subtitle>
              <TextParagraph>
                The Materials tab is where you will find a list of all BASF Raw
                Material product offerings. Browse the list, filter by type, or
                sort by name to identify the raw material product information
                that you need.
              </TextParagraph>

              <TextParagraph>
                You will see the option to add materials to this list, allowing
                you to customize this list and add some of your own raw
                materials. This will allow you to use the formulation calculator
                in the most accurate way possible to meet your individual needs.
                Once you have added a custom material list, you have the ability
                to export this list and share with a colleague. Your colleague
                can then import the list you have shared so that they do not
                have to manually add the custom raw material list themselves.
              </TextParagraph>
              <Space align='end'>
                <Button
                  dataCy='skip-button'
                  size='large'
                  className='cyAttr'
                  onClick={() => handleSkipDone()}
                >
                  Skip Tour
                </Button>
                <Button
                  dataCy='next-button'
                  type='primary'
                  size='large'
                  className='cyAttr'
                  onClick={() => previousSlide()}
                >
                  Previous
                </Button>
                <Button
                  dataCy='next-button'
                  type='primary'
                  size='large'
                  className='cyAttr'
                  onClick={() => nextSlide()}
                >
                  Next
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col xs={24} lg={16}>
              <video width='100%' height='100%' controls>
                <source src={VideoTwo} type='video/mp4' />
                <track hidden />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col xs={23} lg={7} offset={1}>
              <Subtitle data-cy='formulation-tour'>Formulation list</Subtitle>
              <TextParagraph>
                The Formulator tab is where you will find a list of all
                formulations that have been generated in this application. If it
                is your first time accessing the application, this section will
                be empty. Select the New Formulation action to get started
                generating your own formulations.
              </TextParagraph>
              <TextParagraph>
                Once you have saved a few formulations, you will find this view
                useful, allowing you to filter and sort through the list.
              </TextParagraph>
              <Space>
                <Button
                  dataCy='skip-button'
                  size='large'
                  className='cyAttr'
                  onClick={() => handleSkipDone()}
                >
                  Skip Tour
                </Button>
                <Button
                  dataCy='previous-button'
                  type='primary'
                  size='large'
                  className='cyAttr'
                  onClick={() => previousSlide()}
                >
                  Previous
                </Button>
                <Button
                  dataCy='next-button'
                  type='primary'
                  size='large'
                  className='cyAttr'
                  onClick={() => nextSlide()}
                >
                  Next
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col xs={24} lg={16}>
              <video width='100%' height='100%' controls>
                <source src={VideoThree} type='video/mp4' />
                <track hidden />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col xs={23} lg={7} offset={1}>
              <Subtitle data-cy='formulator-list-tour'>
                Formulator list
              </Subtitle>
              <TextParagraph>
                To get started with a new formulation, select a calculator type,
                materials, and add parts. You will see that totals are generated
                for you at the bottom of the page automatically as you begin
                using the calculator. Once you are happy with the calculation,
                you can save the formulation to your formulation list or
                generate a pdf of your formulation for print or export.
              </TextParagraph>
              <Space>
                <Button
                  dataCy='previous-button'
                  type='primary'
                  size='large'
                  className='cyAttr'
                  onClick={() => previousSlide()}
                >
                  Previous
                </Button>

                <Button
                  dataCy='done-button'
                  size='large'
                  className='cyAttr'
                  onClick={() => handleSkipDone()}
                >
                  Done
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </CustomCarousel>
    </Container>
  );
};
