import { Table } from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

export const Container = styled.div`
  background: white;
  padding: 25px 0px;
`;

export const RemoveMaterial = styled(CloseOutlined)`
  font-size: 18px;
  padding-right: 10px;
  color: silver;
`;

export const Title = styled.span`
  color: ${(props) => props.theme.texts.colors.brand.neutral[80]};
  font-size: ${(props) => props.theme.texts.largeText.size};
  padding: 0 25px;
  font-weight: 500;
`;
export const BoldText = styled.p`
  color: ${(props) => props.theme.texts.colors.brand.neutral[100]};
  font-weight: ${(props) => props.theme.texts.bold};
  margin: 10px 0px;
`;

export const BatchContainer = styled.div`
  padding: 25px;
  max-width: 150px;
`;

export const CalculatorResinTable = styled(Table)`
  .ant-table-cell.hidden {
    transition: 500ms ease-in-out;
    display: none;
  }
  .ant-table-thead > tr > th {
    background: transparent;
  }

  tr {
    th,
    td {
      &:first-child {
        padding-left: 24px;
      }
    }
    &:first-child {
      th {
        background-color: #ffffff;
        border-bottom: 1px solid #c6cddc;
      }
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    min-width: 80px;
  }

  &.ant-table-tbody {
    tr:last-child {
      &:only-child {
        border-top: 0px solid transparent;
      }
      &:not(only-child) td {
        border-top: 1px solid #c6cddc;
      }
      td {
        border-bottom: 0px solid transparent;
      }
    }
  }
`;
